import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Select } from 'components/mui';
import { GenderOperand, OperandValues } from 'types';
import { OperandProps } from './constants/OperandProps';

const operators: GenderOperand['operator'][] = ['='];
const values: GenderOperand['value'][] = ['M', 'F', 'U'];

export default function Gender({ operand, updateOperand }: OperandProps<GenderOperand>) {
  // check for "value" so that ts doesn't complain about accessing "value" on an operand without value
  const isAge = operand?.operand === OperandValues.gender;
  if (!isAge) return <></>;

  const handleValChange = (e: SelectChangeEvent<any>) => {
    updateOperand({ ...operand, value: e.target.value as (typeof values)[0] });
  };

  return (
    <>
      <Select disabled value={operand.operator}>
        {operators.map((o, i) => (
          <MenuItem value={o} key={i}>
            {o}
          </MenuItem>
        ))}
      </Select>
      <Select label="Gender" onChange={handleValChange} value={operand.value}>
        {values.map((o, i) => (
          <MenuItem value={o} key={i}>
            {o}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
