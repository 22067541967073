import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export const Table = (theme: Theme) => {
  return {
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          //borderBottom: "none",
          height: 50,
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
        },
        head: {
          color: theme.palette.text.primary,
          //backgroundColor: theme.palette.background.paper,
          //backgroundColor: theme.palette.grey[100],
          fontWeight: theme.typography.fontWeightMedium,
          //color: theme.palette.grey[900]
        },
        // stickyHeader: {
        //   backgroundColor: theme.palette.grey[100],
        // },
        body: {
          '&:first-of-type': {
            //paddingLeft: theme.spacing(3),
          },
          '&:last-of-type': {
            //paddingRight: theme.spacing(3),
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        MuiTableRow: {
          //borderBottom: "1px solid black"
        },
        MuiTableCell: {
          borderBottom: 1,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          //borderTop: `solid 1px ${theme.palette.divider}`,
          borderTop: 'none !important',
        },
        toolbar: {
          height: 64,
        },
        select: {
          '&:focus': {
            borderRadius: theme.shape.borderRadius,
          },
        },
        selectIcon: {
          width: 20,
          height: 20,
          marginTop: -4,
        },
      },
    },
  };
};
