import { MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { Select } from 'components/mui';
import { ChangeEvent } from 'react';
import { NoEncounterWithinOperand, OperandValues } from 'types';
import { OperandProps } from './constants/OperandProps';

const periodTypes: (keyof NoEncounterWithinOperand['duration'])[] = [
  'days',
  'weeks',
  'months',
  'years',
];

export default function NoEncounterWithin({
  operand,
  updateOperand,
}: OperandProps<NoEncounterWithinOperand>) {
  // check for "value" so that ts doesn't complain about accessing "value" on an operand without value
  const isPrev = operand?.operand === OperandValues.noEncounterWithin;
  if (!isPrev) return <></>;

  const handlePeriodTypeChange = (e: SelectChangeEvent<any>) => {
    const val = e.target.value as (typeof periodTypes)[0];
    const duration = {
      [val]: 1,
    };
    updateOperand({ ...operand, duration });
  };

  const handlePeriodValueChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const key = Object.keys(operand.duration)?.[0];
    const duration = {
      [key]: e.target.value,
    };
    updateOperand({ ...operand, duration });
  };

  return (
    <>
      <Select
        value={Object.keys(operand.duration)?.[0]}
        label="Period"
        onChange={handlePeriodTypeChange}
      >
        {periodTypes.map((p) => (
          <MenuItem key={p} value={p}>
            {p}
          </MenuItem>
        ))}
      </Select>
      <TextField
        value={Object.values(operand.duration)?.filter((o) => !!o) || 0}
        label="Duration"
        onChange={handlePeriodValueChange}
        type="number"
      />
    </>
  );
}
