import { Typography } from '@mui/material';
import { ensomataApi } from 'api';
import { Box, Page } from 'components';
import * as React from 'react';
import { useState } from 'react';
import { SelfServiceFilter } from 'types';
import { SelfServicePageFilters } from './SelfServicePageFilters';
import { SelfServicePageRecommendationLogs } from './SelfServicePageRecommendationLogs';
import { SelfServicePageRecommendationRequests } from './SelfServicePageRecommendationRequests';
import { PageRoute } from 'models';
import { buildPath, buildTemplatePath } from 'services';

const routeName = 'selfService';
export const selfServicePageRoute: PageRoute = {
  handle: 'Self Service',
  templatePath: buildTemplatePath(routeName),
  getPath: () => buildPath(routeName),
};

export const SelfServicePage = () => {
  const [filter, setFilter] = useState<SelfServiceFilter>({ days: 3 });
  const [appliedFilter, setAppliedFilter] = useState<SelfServiceFilter>(filter);
  const { data: recommendationLogsResponse, isFetching: logsFetching } =
    ensomataApi.useAdminGetRecommendationLogsQuery(appliedFilter, {
      skip: !appliedFilter.patientId,
    });
  const { data: recommendationRequestsResponse, isFetching: requestsFetching } =
    ensomataApi.useAdminGetRecommendationRequestPayloadsQuery(appliedFilter, {
      skip: !appliedFilter.patientId,
    });
  const recommendationLogs: string[] | undefined =
    recommendationLogsResponse?.recommendationLogs;
  const recommendationRequests: any[] | undefined =
    recommendationRequestsResponse?.requestPayloads;

  const [selectedPayload, setSelectedPayload] = useState<string>('');
  const { data: recommendations } = ensomataApi.useAdminGetRecommendationsQuery(
    { payload: selectedPayload },
    { skip: !selectedPayload }
  );
  const selectedPayloadResponse: any[] | undefined = recommendations?.recommendations;

  return (
    <Page
      header={
        <Box>
          <Box>{selfServicePageRoute.handle}</Box>
          <Typography fontStyle="italic" fontSize="12px">
            All times in UTC
          </Typography>
        </Box>
      }
    >
      <SelfServicePageFilters
        filter={filter}
        setFilter={setFilter}
        setAppliedFilter={setAppliedFilter}
      />
      <SelfServicePageRecommendationLogs
        logsFetching={logsFetching}
        recommendationLogs={recommendationLogs}
      />

      {(recommendationLogs != null ||
        recommendationRequests != null ||
        logsFetching ||
        requestsFetching) && <hr />}

      <SelfServicePageRecommendationRequests
        requestsFetching={requestsFetching}
        recommendationRequests={recommendationRequests}
        appliedFilter={appliedFilter}
        setSelectedPayload={setSelectedPayload}
        selectedPayload={selectedPayload}
        selectedPayloadResponse={selectedPayloadResponse}
      />
    </Page>
  );
};
