import { Box, Form, IconButton, Stack } from 'components';
import { useTheme } from 'hooks';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';

export type PageMode = 'Add' | 'Edit' | 'View';

type PageProps = {
  header?: ReactNode;
  children?: ReactNode;
  backButton?: boolean;
  isForm?: boolean;
};

export function Page({ header, backButton, isForm, children }: PageProps) {
  const navigate = useNavigate();
  const theme = useTheme();

  const rootStyle: any = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.default,
  };

  const content = (
    <>
      <Stack direction="row" alignItems="center" px={5} pt={3} spacing="sm">
        {backButton && (
          <IconButton onClick={() => navigate('../')}>
            <BackIcon />
          </IconButton>
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          fontSize="1.5em"
          flexGrow={1}
        >
          {header}
        </Stack>
      </Stack>

      {children && (
        <Box overflow="auto" px={5} py={3} flexGrow={1}>
          {children}
        </Box>
      )}
    </>
  );

  return !isForm ? (
    <Box sx={rootStyle} className="page">
      {content}
    </Box>
  ) : (
    <Form style={rootStyle} className="page">
      {content}
    </Form>
  );
}
