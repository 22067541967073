"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmokingStatusOption = void 0;
var SmokingStatusOption;
(function (SmokingStatusOption) {
    SmokingStatusOption["current"] = "Current smoker";
    SmokingStatusOption["former"] = "Former smoker";
    SmokingStatusOption["nonSmoker"] = "Non-smoker";
    SmokingStatusOption["other"] = "Other tobacco user";
})(SmokingStatusOption = exports.SmokingStatusOption || (exports.SmokingStatusOption = {}));
