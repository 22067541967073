import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from 'components';
import { useNavigate } from 'react-router-dom';
import { formatDate } from 'services';
import { CDSSRuleListDto } from 'types';
import { editRulePageRoute } from '../RulePage/EditRule';

type RuleListPageTableProps = {
  rules: CDSSRuleListDto[] | undefined;
};

export const RuleListPageTable = ({ rules }: RuleListPageTableProps) => {
  const navigate = useNavigate();

  return (
    <TableContainer sx={{ height: '100%' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Rule Name</TableCell>
            <TableCell>Display</TableCell>
            <TableCell>Version</TableCell>
            <TableCell>Date Posted</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rules?.map((rule) => {
            return (
              <TableRow
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    color: 'orange',
                  },
                }}
                key={rule.id}
                onClick={() => navigate({ pathname: editRulePageRoute.getPath(rule.id) })}
              >
                <TableCell>{rule.name}</TableCell>
                <TableCell>{rule.displayName}</TableCell>
                <TableCell>{rule.version}</TableCell>
                <TableCell>{formatDate(rule.datePosted)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
