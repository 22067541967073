import CheckIcon from '@mui/icons-material/Check';
import {
  ButtonPopover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from 'components';
import { PAGE_SIZE } from 'constants/commonConstants';
import { formatDate } from 'services';
import { OrderMetric } from 'types';

type OrderActivityListPageTableProps = {
  tableContainerRef: React.RefObject<HTMLDivElement>;
  page: number;
  orderMetrics: OrderMetric[] | undefined;
};

export const OrderActivityListPageTable = ({
  tableContainerRef,
  page,
  orderMetrics,
}: OrderActivityListPageTableProps) => {
  return (
    <TableContainer ref={tableContainerRef}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>User Id</TableCell>
            <TableCell>User Name</TableCell>
            <TableCell>Order Id</TableCell>
            <TableCell>Order Name</TableCell>
            <TableCell>Order Date</TableCell>
            <TableCell align="center">Accepted?</TableCell>
            <TableCell>Reason</TableCell>
            <TableCell>Order Location Id</TableCell>
            <TableCell>Service Location</TableCell>
            <TableCell>Provider Id</TableCell>
            <TableCell>Provider Name</TableCell>
            <TableCell>Encounter Id</TableCell>
            <TableCell>Patient Id</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderMetrics
            ?.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)
            .map((x, index) => {
              return (
                <TableRow key={x.row}>
                  <TableCell>{x.row}.</TableCell>
                  <TableCell>{x.staffId}</TableCell>
                  <TableCell>{x.staffName}</TableCell>
                  <TableCell>{x.orderId}</TableCell>
                  <TableCell>{x.orderName}</TableCell>
                  <TableCell>{formatDate(x.orderDate)}</TableCell>
                  <TableCell align="center">
                    {x.orderAccepted === true && <CheckIcon />}
                  </TableCell>
                  <TableCell>
                    {x.orderReason && <ButtonPopover>{x.orderReason}</ButtonPopover>}
                  </TableCell>
                  <TableCell>{x.orderLocationId}</TableCell>
                  <TableCell>{x.serviceLocationName}</TableCell>
                  <TableCell>{x.providerId}</TableCell>
                  <TableCell>{x.providerName}</TableCell>
                  <TableCell>{x.encounterId}</TableCell>
                  <TableCell>{x.patientId}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
