import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Select } from 'components/mui';
import { FluSeasonOperand, FluSeasonOption, OperandValues } from 'types';
import { OperandProps } from './constants/OperandProps';

const values: FluSeasonOption[] = Object.values(FluSeasonOption);

export default function FluSeason({
  operand,
  updateOperand,
}: OperandProps<FluSeasonOperand>) {
  // check for "value" so that ts doesn't complain about accessing "value" on an operand without value
  const isFluSeason = operand?.operand === OperandValues.fluSeason;
  if (!isFluSeason) return <></>;

  const handleValChange = (e: SelectChangeEvent<any>) => {
    updateOperand({ ...operand, value: e.target.value as FluSeasonOption });
  };

  return (
    <>
      <Select onChange={handleValChange} value={operand.value}>
        {values.map((o, i) => (
          <MenuItem value={o} key={i}>
            {o}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
