import {
  Box,
  Card as MuiCard,
  CardProps as MuiCardProps,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';

type CardProps = MuiCardProps & {
  header?: ReactNode;
};

export function Card({ sx, header, variant, children, ...rest }: CardProps) {
  return (
    <MuiCard
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: variant === 'outlined' ? 'none' : undefined,
        ...sx,
      }}
      variant={variant}
      {...rest}
    >
      {header && (
        <Box px={4} py={2} borderBottom={(theme) => `1px solid ${theme.palette.divider}`}>
          <Typography variant="h6">{header}</Typography>
        </Box>
      )}

      <Box width="100%" p={5}>
        {children}
      </Box>
    </MuiCard>
  );
}
