import {
  Avatar,
  Box,
  BoxProps,
  Breadcrumbs,
  IconButton,
  Link,
  Logo,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from 'components';
import { useAuth, useNavigationBar } from 'hooks';
import { useState } from 'react';
import { matchRoutes, RouteMatch, useLocation, useNavigate } from 'react-router-dom';
import { appRoutes } from 'routes';
import { AppTopBarNavTrigger } from './AppTopBarNavTrigger';

type AppTopBarProps = Omit<BoxProps, 'children'> & {};

export const AppTopBar = ({ className, sx, ...rest }: AppTopBarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleLogout } = useAuth();
  const { isNavBarDrawer } = useNavigationBar();
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null);

  const renderBreadcrumbs = () => {
    let routeMatches: RouteMatch[] | null = matchRoutes(appRoutes, location.pathname);
    routeMatches = routeMatches!.filter((x) => x.route.path && x.route.path !== '/');
    const fontSize: string = '0.8rem';

    if (routeMatches.length > 1) {
      return (
        <Breadcrumbs separator="›">
          {routeMatches.map((x, index) => {
            const last = index === routeMatches!.length - 1;

            return (
              <Box key={index}>
                {last ? (
                  <Typography fontSize={fontSize}>{x.route.handle}</Typography>
                ) : (
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(x.pathname);
                    }}
                    href=""
                    underline="hover"
                    color="inherit"
                    fontSize={fontSize}
                  >
                    {x.route.handle}
                  </Link>
                )}
              </Box>
            );
          })}
        </Breadcrumbs>
      );
    }

    return null;
  };

  return (
    <Box position="relative">
      <Stack direction="row" px={2} height="50px" display="flex" alignItems="center">
        <Stack direction="row" spacing="sm">
          {isNavBarDrawer && <Logo width="60px" />}

          <AppTopBarNavTrigger />

          {renderBreadcrumbs()}
        </Stack>

        <Box ml="auto">
          <IconButton onClick={(e) => setUserMenuAnchorEl(e.currentTarget)}>
            <Avatar sx={{ width: 30, height: 30 }}></Avatar>
          </IconButton>

          <Menu
            anchorEl={userMenuAnchorEl}
            open={Boolean(userMenuAnchorEl)}
            onClose={() => setUserMenuAnchorEl(null)}
          >
            {/* <MenuItem onClick={() => {}}>Placeholder</MenuItem> */}
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Box>
      </Stack>
    </Box>
  );
};
