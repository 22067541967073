import { MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { Select } from 'components/mui';
import {
  CONDITIONAL_OPERATORS,
  OperandValues,
  TestResultValueOperand,
  TestResultValuePropertyName,
} from 'types';
import { OperandProps } from './constants/OperandProps';

const testValues: string[] = Object.values(TestResultValuePropertyName);

const operators: TestResultValueOperand['operator'][] = [
  ...CONDITIONAL_OPERATORS,
  'Is Null',
];

export default function TestResultValue({
  operand,
  updateOperand,
}: OperandProps<TestResultValueOperand>) {
  // check for "value" so that ts doesn't complain about accessing "value" on an operand without value
  const isTestResult = operand?.operand === OperandValues.testResultValue;
  if (!isTestResult) return <></>;

  const handleTestSelection = (e: SelectChangeEvent<any>) => {
    updateOperand({ ...operand, test: e.target.value as TestResultValuePropertyName });
  };

  const handleOperatorChange = (e: SelectChangeEvent<any>) => {
    console.log(e.target.value);
    console.log(operand);
    if (e.target.value === 'Is Null') {
      updateOperand({
        ...operand,
        operator: e.target.value as (typeof operators)[0],
        value: null,
      });
    } else if (operand.value === null && e.target.value !== 'Is Null') {
      updateOperand({
        ...operand,
        operator: e.target.value as (typeof operators)[0],
        value: '1.0',
      });
    } else {
      updateOperand({ ...operand, operator: e.target.value as (typeof operators)[0] });
    }
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateOperand({ ...operand, value: e.target.value });
  };
  return (
    <>
      <Select label="Test" onChange={handleTestSelection} value={operand.test}>
        {testValues.map((o, i) => (
          <MenuItem value={o} key={i}>
            {o}
          </MenuItem>
        ))}
      </Select>

      <div style={{ display: 'flex', gap: 4 }}>
        <div style={{ width: '50%' }}>
          <Select onChange={handleOperatorChange} value={operand.operator}>
            {operators.map((o, i) => (
              <MenuItem value={o} key={i}>
                {o}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div style={{ width: '50%' }}>
          {operand.operator !== 'Is Null' && (
            <TextField
              label="Test Result Value"
              onChange={handleValueChange}
              value={operand.value}
            />
          )}
        </div>
      </div>
    </>
  );
}
