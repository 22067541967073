type Variant = 'word-white';

type LogoProps = {
  width?: string;
  variant?: Variant | undefined;
};

export const Logo = ({ width, variant }: LogoProps) => {
  let src = '/ensomata-word-logo.svg';
  if (variant === 'word-white') src = '/ensomata-word-logo-white.svg';

  return <img width={width ? width : '140px'} src={src} alt="logo" />;
};
