import { StandardTextFieldProps, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface Props extends StandardTextFieldProps {
  value: number;
  changeHandler: (value: number) => void;
}

// generic wrapper of textfield for handling number fields
// this solves the problem of clearing a field
// the changeHandler may only take a number, but we need to allow the input value to == ""
// when the user is clearing the field
// this holds the state locally and only updates the parent when the value is a number
const NumberField = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const [state, setState] = useState<number | string>('');
  const [lastPropsVal, setLastPropsVal] = useState<any>(null);

  // set the value initially/onload
  // and  account for the data first being undefined
  //   or changing
  useEffect(() => {
    if (lastPropsVal === props.value) return;
    setLastPropsVal(props.value);
    setState(props.value ?? '');
  }, [props.value, state, lastPropsVal]);

  // Set form state to blank to start if no prior value stored
  useEffect(() => {
    if ([0, '', null, undefined].includes(props.value)) {
      setState('');
    }
  }, [props.value]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === '') {
      setState('');
      return;
    }
    const num = Number(value);
    if (isNaN(num)) return;
    setState(num);
    props.changeHandler(num);
  };

  const { changeHandler, ...textFieldProps } = props;
  return (
    <TextField
      {...textFieldProps}
      ref={ref}
      value={state}
      onChange={onChange}
      type="number"
    />
  );
});

NumberField.displayName = 'NumberField';

export default NumberField;
