"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BloodPressurePropertyNames = void 0;
var BloodPressurePropertyNames;
(function (BloodPressurePropertyNames) {
    BloodPressurePropertyNames["bP2DiastolicValue"] = "bP2DiastolicValue";
    BloodPressurePropertyNames["bP2SystolicValue"] = "bP2SystolicValue";
    BloodPressurePropertyNames["bpDiastolicValue"] = "bpDiastolicValue";
    BloodPressurePropertyNames["bpSystolicValue"] = "bpSystolicValue";
})(BloodPressurePropertyNames = exports.BloodPressurePropertyNames || (exports.BloodPressurePropertyNames = {}));
