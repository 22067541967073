import { Box, Button, PageMode, Stack } from 'components';
import { SavableContext, SavableContextValue } from 'contexts';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { setAppToast } from 'redux/appSlice';
import { newToast } from 'services';

type DeveloperPageHeaderProps = {
  pageMode: PageMode;
  setPageMode?: React.Dispatch<React.SetStateAction<PageMode>>;
};

export const DeveloperPageHeader = ({
  pageMode,
  setPageMode,
}: DeveloperPageHeaderProps) => {
  const context = useContext<SavableContextValue>(SavableContext);
  const dispatch = useDispatch();

  const save = () => {
    context.onSave?.();
    dispatch(setAppToast(newToast('success')));
  };

  return (
    <>
      <Box>{pageMode} Developer</Box>
      <Stack direction="row" spacing={'sm'}>
        {pageMode === 'View' && (
          <Button onClick={() => setPageMode?.('Edit')} variant="outlined">
            Edit
          </Button>
        )}
        {(pageMode === 'Add' || pageMode === 'Edit') && (
          <Button onClick={() => save()} variant="contained">
            Save
          </Button>
        )}
      </Stack>
    </>
  );
};
