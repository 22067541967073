import { Page } from 'components';
import { HomePageDashboard } from './HomePageDashboard/HomePageDashboard';
import { PageRoute } from 'models';
import { buildPath, buildTemplatePath } from 'services';

const routeName = 'home';
export const homePageRoute: PageRoute = {
  handle: 'Home',
  templatePath: buildTemplatePath(routeName),
  getPath: () => buildPath(routeName),
};

export const HomePage = () => {
  return (
    <Page header="Home">
      <HomePageDashboard />
    </Page>
  );
};
