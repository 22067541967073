import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Select } from 'components/mui';
import {
  AgeOperand,
  CONDITIONAL_OPERATORS,
  ConditionalOperatorType,
  OperandValues,
} from 'types';
import { OperandProps } from './constants/OperandProps';
import NumberField from '../Components/NumberInput';

export default function Age({ operand, updateOperand }: OperandProps<AgeOperand>) {
  // check for "value" so that ts doesn't complain about accessing "value" on an operand without value
  const isAge = operand?.operand === OperandValues.age;
  if (!isAge) return <></>;

  const handleOperandChange = (e: SelectChangeEvent<any>) => {
    updateOperand({ ...operand, operator: e.target.value as ConditionalOperatorType });
  };

  const handleValueChange = (value: number) => {
    updateOperand({ ...operand, value });
  };

  return (
    <>
      <Select onChange={handleOperandChange} value={operand.operator}>
        {CONDITIONAL_OPERATORS.map((o, i) => (
          <MenuItem value={o} key={i}>
            {o}
          </MenuItem>
        ))}
      </Select>
      <NumberField label="Age" changeHandler={handleValueChange} value={operand.value} />
    </>
  );
}
