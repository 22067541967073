"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TestResultDatePropertyName = exports.TestResultValuePropertyName = void 0;
var TestResultValuePropertyName;
(function (TestResultValuePropertyName) {
    TestResultValuePropertyName["aagfrResultValue"] = "aagfrResultValue";
    TestResultValuePropertyName["hbA1CLastResultValue"] = "hbA1CLastResultValue";
    TestResultValuePropertyName["ldlLastResultValue"] = "ldlLastResultValue";
    TestResultValuePropertyName["naagfrResultValue"] = "naagfrResultValue";
    TestResultValuePropertyName["tResultValue"] = "tResultValue";
})(TestResultValuePropertyName = exports.TestResultValuePropertyName || (exports.TestResultValuePropertyName = {}));
var TestResultDatePropertyName;
(function (TestResultDatePropertyName) {
    TestResultDatePropertyName["aagfrResultDate"] = "aagfrResultDate";
    TestResultDatePropertyName["hbA1CLastResultDate"] = "hbA1CLastResultDate";
    TestResultDatePropertyName["ldlLastResultDate"] = "ldlLastResultDate";
    TestResultDatePropertyName["naagfrResultDate"] = "naagfrResultDate";
    TestResultDatePropertyName["tResultDate"] = "tResultDate";
})(TestResultDatePropertyName = exports.TestResultDatePropertyName || (exports.TestResultDatePropertyName = {}));
