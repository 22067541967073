import { Line } from 'react-chartjs-2';
import { formatDate } from 'services';
import { OrderMetric } from 'types';
import { TimePeriodFilter } from './HomePageDashboard';

type SourceItem = { value: string; name: string };
const sources: SourceItem[] = [
  { value: 'accepted', name: 'Accepted' },
  { value: 'notAccepted', name: 'Not Accepted' },
];

type DataSourceItem = {
  timestamp: string;
  accepted: number;
  notAccepted: number;
};

type HomePageDashboardLineChartProps = {
  orderMetrics: OrderMetric[];
  timePeriod: TimePeriodFilter;
};

export const HomePageDashboardLineChart = ({
  orderMetrics,
  timePeriod,
}: HomePageDashboardLineChartProps) => {
  const getDataSourceByCurrentHour = (orderMetrics: OrderMetric[]) => {
    const incrementMinutes = 5;

    const endDate = new Date();
    const startDate = new Date();
    startDate.setMinutes(0, 0, 0);
    endDate.setMinutes(55, 0, 0);

    const result: DataSourceItem[] = [];
    for (
      let current = startDate;
      current <= endDate;
      current.setMinutes(current.getMinutes() + incrementMinutes)
    ) {
      console.log(current.toISOString());

      const date = new Date(current);
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12;
      let minutesString = minutes < 10 ? '0' + minutes : minutes;
      const strTime = hours + ':' + minutesString + ' ' + ampm;

      const matchingOrderMetrics = orderMetrics.filter(
        (om: OrderMetric) =>
          new Date(om.orderDate).getFullYear() === date.getFullYear() &&
          new Date(om.orderDate).getMonth() === date.getMonth() &&
          new Date(om.orderDate).getDate() === date.getDate() &&
          new Date(om.orderDate).getHours() === date.getHours() &&
          // round to the nearest multiple of incrementMinutes (ex. 54 outputs 55)
          Math.round(new Date(om.orderDate).getMinutes() / incrementMinutes) *
            incrementMinutes ===
            date.getMinutes()
      );

      result.push({
        timestamp: strTime,
        accepted: matchingOrderMetrics.filter((om: OrderMetric) => om.orderAccepted)
          .length,
        notAccepted: matchingOrderMetrics.filter((om: OrderMetric) => !om.orderAccepted)
          .length,
      });
    }

    return result;
  };

  const getDataSourceByToday = (orderMetrics: OrderMetric[]) => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(24, 0, 0, 0);

    const result: DataSourceItem[] = [];
    for (
      let current = startDate;
      current <= endDate;
      current.setHours(current.getHours() + 1)
    ) {
      const date = new Date(current);
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12;
      let minutesString = minutes < 10 ? '0' + minutes : minutes;
      const strTime = hours + ':' + minutesString + ' ' + ampm;

      const matchingOrderMetrics = orderMetrics.filter(
        (om: OrderMetric) =>
          new Date(om.orderDate).getFullYear() === date.getFullYear() &&
          new Date(om.orderDate).getMonth() === date.getMonth() &&
          new Date(om.orderDate).getDate() === date.getDate() &&
          new Date(om.orderDate).getHours() === date.getHours()
      );

      result.push({
        timestamp: strTime,
        accepted: matchingOrderMetrics.filter((om: OrderMetric) => om.orderAccepted)
          .length,
        notAccepted: matchingOrderMetrics.filter((om: OrderMetric) => !om.orderAccepted)
          .length,
      });
    }

    return result;
  };

  const getDataSourceByWeekly = (orderMetrics: OrderMetric[]) => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 7);

    const result: DataSourceItem[] = [];
    for (
      let current = startDate;
      current <= endDate;
      current.setDate(current.getDate() + 1)
    ) {
      const matchingOrderMetrics = orderMetrics.filter(
        (om: OrderMetric) =>
          new Date(om.orderDate).getFullYear() === current.getFullYear() &&
          new Date(om.orderDate).getMonth() === current.getMonth() &&
          new Date(om.orderDate).getDate() === current.getDate()
      );
      result.push({
        timestamp: formatDate(current, 'ddd, MM/DD/YYYY')!,
        accepted: matchingOrderMetrics.filter((om: OrderMetric) => om.orderAccepted)
          .length,
        notAccepted: matchingOrderMetrics.filter((om: OrderMetric) => !om.orderAccepted)
          .length,
      });
    }

    return result;
  };

  const getDataSourceByMonthly = (orderMetrics: OrderMetric[]) => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 30);

    const result: DataSourceItem[] = [];
    for (
      let current = startDate;
      current <= endDate;
      current.setDate(current.getDate() + 1)
    ) {
      const matchingOrderMetrics = orderMetrics.filter(
        (om: OrderMetric) =>
          new Date(om.orderDate).getFullYear() === current.getFullYear() &&
          new Date(om.orderDate).getMonth() === current.getMonth() &&
          new Date(om.orderDate).getDate() === current.getDate()
      );
      result.push({
        timestamp: formatDate(current, 'MM/DD/YYYY')!,
        accepted: matchingOrderMetrics.filter((om: OrderMetric) => om.orderAccepted)
          .length,
        notAccepted: matchingOrderMetrics.filter((om: OrderMetric) => !om.orderAccepted)
          .length,
      });
    }

    return result;
  };

  let dataSource: DataSourceItem[] = [];

  if (timePeriod === 'Current Hour')
    dataSource = getDataSourceByCurrentHour(orderMetrics);
  if (timePeriod === 'Today') dataSource = getDataSourceByToday(orderMetrics);
  if (timePeriod === 'Week') dataSource = getDataSourceByWeekly(orderMetrics);
  if (timePeriod === 'Month') dataSource = getDataSourceByMonthly(orderMetrics);

  return (
    <Line
      data={{
        labels: dataSource.map((item) => item.timestamp),
        datasets: sources.map((source) => ({
          label: source.name,
          data: dataSource.map((item) => item[source.value]),
          borderColor:
            source.value === 'accepted'
              ? 'rgba(75, 192, 192, 1)'
              : 'rgba(255, 99, 132, 1)',
          backgroundColor:
            source.value === 'accepted'
              ? 'rgba(75, 192, 192, 0.2)'
              : 'rgba(255, 99, 132, 0.2)',
          fill: true,
        })),
      }}
      options={{
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom' as const,
          },
          title: {
            display: true,
            text: 'Accepted Orders',
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            min: 0, // Ensure the y-axis starts at 0
          },
        },
      }}
    />
  );
};
