import { Box, BoxProps, Paper } from "@mui/material";

import { ReactNode } from "react";

type ListContainerProps = Omit<BoxProps, "children"> & {
  header?: ReactNode;
  tableContainer?: ReactNode;
  pagination?: ReactNode;
};

export const ListContainer = ({ sx, header, tableContainer, pagination, ...rest }: ListContainerProps) => {
  return (
    <Paper
      sx={{
        ...sx,
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        height: "100%",
      }}
    >
      {header && (
        <Box px={2} py={3}>
          {header}
        </Box>
      )}
      {tableContainer && (
        <Box display="flex" flexGrow={1} overflow="auto">
          {tableContainer}
        </Box>
      )}
      {pagination && <Box>{pagination}</Box>}
    </Paper>
  );
};
