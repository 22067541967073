import { MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { Select } from 'components/mui';
import {
  CONDITIONAL_OPERATORS,
  ConditionalOperatorType,
  OperandValues,
  SmokingCessationYearsOperand,
} from 'types';
import { OperandProps } from './constants/OperandProps';

export default function SmokingCessationYears({
  operand,
  updateOperand,
}: OperandProps<SmokingCessationYearsOperand>) {
  // check for "value" so that ts doesn't complain about accessing "value" on an operand without value
  const isSmokingCessationYears =
    operand?.operand === OperandValues.smokingCessationYears;
  if (!isSmokingCessationYears) return <></>;

  const handleOperandChange = (e: SelectChangeEvent<any>) => {
    updateOperand({ ...operand, operator: e.target.value as ConditionalOperatorType });
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateOperand({ ...operand, value: Number(e.target.value) });
  };

  return (
    <>
      <Select onChange={handleOperandChange} value={operand.operator}>
        {CONDITIONAL_OPERATORS.map((o, i) => (
          <MenuItem value={o} key={i}>
            {o}
          </MenuItem>
        ))}
      </Select>
      <TextField
        label="Cessation Years"
        onChange={handleValueChange}
        type="number"
        value={operand.value}
      />
    </>
  );
}
