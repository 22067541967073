import { Breakpoint, Grid as MuiGrid, GridProps as MuiGridProps } from '@mui/material';
import React from 'react';
import { getSpacing } from '../../theme';

type GridProps = Omit<MuiGridProps, 'spacing'> & {
  spacing?: Breakpoint;
};

export const Grid = React.forwardRef<HTMLDivElement, GridProps>(
  ({ children, spacing, ...rest }, ref) => {
    return (
      <MuiGrid ref={ref} spacing={getSpacing(spacing)} {...rest}>
        {children}
      </MuiGrid>
    );
  }
);
