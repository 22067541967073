import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { MetricsReport } from 'bi-report/BiReportApi';
import BiReportApiService from 'bi-report/BiReportApiService';
import { Page, Stack, Typography } from 'components';
import { useParams } from 'hooks';
import { PageRoute } from 'models';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { buildPath, buildTemplatePath } from 'services';
import { DataPackage } from './DataPackage';
import { DateFilter } from './DateFilter';
import { useNavigate } from 'react-router-dom';

const routeName = 'practiceMetricsDetail';

class Params {
  reportId!: number;
  facilityId!: number;
  badgeNumber!: string;
  reportDate!: string;
}

export const practiceMetricsDetailPageRoute: PageRoute<Params> = {
  handle: 'Practice Metrics Detail',
  templatePath: buildTemplatePath(routeName, Params),
  getPath: (params) => buildPath(routeName, params),
};

export const PracticeMetricsDetailPage = () => {
  const { params } = useParams(routeName, Params);
  const navigate = useNavigate();
  const reportId = params.reportId;
  const facilityId = params.facilityId;
  const badgeNumber = params.badgeNumber;
  const reportDate = params.reportDate;

  const [reportDetail, setReportDetail] = useState<MetricsReport>({} as MetricsReport);

  const fetch = useCallback(() => {
    BiReportApiService.getApi()
      .adminApp.adminAppControllerGetReportSegment(
        reportId,
        facilityId,
        badgeNumber,
        reportDate
      )
      .then((response) => {
        setReportDetail(response.data);
        //setReportDetail(everythingPackage);
      });
  }, [reportId, facilityId, badgeNumber, reportDate]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const trendIndicator = () => {
    const arrowColor =
      reportDetail?.trendIsGood === undefined
        ? undefined
        : reportDetail.trendIsGood > 0
        ? 'success'
        : reportDetail.trendIsGood < 0
        ? 'error'
        : undefined;

    return (
      <Stack direction="row" alignItems="center" spacing="xs">
        {reportDetail.trendDirection && reportDetail.trendDirection > 0 && (
          <ArrowCircleUpIcon color={arrowColor} fontSize="small" />
        )}

        {reportDetail.trendDirection && reportDetail.trendDirection < 0 && (
          <ArrowCircleDownIcon color={arrowColor} fontSize="small" />
        )}

        <Typography
          color={(theme) =>
            reportDetail?.trendIsGood === undefined
              ? undefined
              : reportDetail.trendIsGood > 0
              ? theme.palette.success.main
              : theme.palette.error.main
          }
          variant="subtitle1"
          fontWeight={(theme) => theme.typography.fontWeightMedium}
        >
          {reportDetail.trendValue}
        </Typography>
        <Typography variant="caption">{reportDetail.trendOutput}</Typography>
      </Stack>
    );
  };

  const handleDateChange = (date: Date) => {
    navigate({
      pathname: `../${practiceMetricsDetailPageRoute.getPath({
        reportId: reportId,
        facilityId: facilityId,
        badgeNumber: badgeNumber,
        reportDate: moment(date).format('YYYY-MM-DD'),
      })}`,
    });
  };

  const pageHeader = (
    <Stack
      width="100%"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" alignItems="center" spacing="sm">
        {reportDetail.icon && (
          <img src={reportDetail.icon} style={{ width: 50 }} alt={reportDetail.title} />
        )}
        <Typography variant="inherit">{reportDetail.title}</Typography>

        {trendIndicator()}
      </Stack>

      <DateFilter date={moment(reportDate).toDate()} setDate={handleDateChange} />
    </Stack>
  );

  return (
    <Page backButton header={pageHeader}>
      <Stack spacing="lg">
        {reportDetail.dataPackages?.map((dataPackage) => (
          <DataPackage target={reportDetail?.target} dataPackage={dataPackage} reportDate={reportDate} />
        ))}
      </Stack>
    </Page>
  );
};
