import { Box, Card } from 'components/mui';
import { CDSSRuleListDto } from 'types';
import FieldsComponents from './FieldsComponents';
import { Field } from '../Constants/Field';

const fields: Field<CDSSRuleListDto>[] = [
  { label: 'Name', accessor: 'name', type: 'text' },
  { label: 'Display Name', accessor: 'displayName', type: 'text' },
  { label: 'Preventative/Routine', accessor: 'preventative', type: 'boolean' },
  { label: 'Active', accessor: 'active', type: 'boolean' },
];

interface Props {
  rule: CDSSRuleListDto;
  updateRule: (rule: CDSSRuleListDto) => void;
}

export default function RuleFieldsContainer({ rule, updateRule }: Props) {
  return (
    <Card>
      <Box style={{ marginBottom: '24px' }}>Fields</Box>
      <FieldsComponents
        fields={fields}
        rule={rule}
        handleUpdate={(field, value) => {
          updateRule({ ...rule, [field.accessor]: value });
        }}
      />
    </Card>
  );
}
