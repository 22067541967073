import { Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from "@mui/material";
import { FormControlLabel, SxProps, Theme, useTheme } from "@mui/material";

type SwitchProps = MuiSwitchProps & {
  label?: string;
};

export function Switch({ label, ...rest }: SwitchProps) {
  const theme = useTheme();
  const rootStyle: SxProps<Theme> = {
    fontWeight: theme.typography.fontWeightBold,
  };

  const muiSwitch = <MuiSwitch sx={{ rootStyle }} {...rest} />;
  return label ? <FormControlLabel control={muiSwitch} label={label} /> : muiSwitch;
}
