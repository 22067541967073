import { Button } from '@mui/material';
import { AllOperand, AnyOperand, Operand, OperandValues } from 'types';
import OperandRowContainer, { IndentationIndicators } from './OperandRowContainer';
import { OperandProps } from './constants/OperandProps';

export default function AnyOrAll({
  indentation = 0,
  operand,
  updateOperand,
}: OperandProps<AnyOperand | AllOperand>) {
  const isAnyOrAll =
    OperandValues.all === operand?.operand || OperandValues.any === operand?.operand;
  const childOperands = isAnyOrAll && ((operand?.value ?? []) as Operand[]);
  const buttonPadding = `10px`;

  const addOperand = () => {
    // push an empty object into the value array
    // this will be updated when the user selects the operand type (gender,age, etc.)
    updateOperand({
      ...operand,
      value: [...operand!.value, {} as Operand],
    } as AnyOperand);
  };

  // we need to have an update with the current context of where we are in the rules
  // object, so we can append child operands to the correct any/or parent in the rules object
  const updateOperandWithContext = (
    childOperand: Operand,
    index: number,
    remove?: boolean
  ) => {
    const empty = [] as Operand[];
    // use ... to remove the reference
    const value = [...(operand?.value ?? empty)];

    if (remove) {
      value.splice(index, 1);
    } else {
      value?.splice(index, 1, childOperand);
    }

    updateOperand({
      ...operand,
      value,
    } as AnyOperand);
  };

  if (!isAnyOrAll) return <></>;

  return (
    <div>
      <div>
        {childOperands &&
          childOperands?.map((o, i) => (
            <OperandRowContainer
              key={i}
              updateOperand={(o, b) => updateOperandWithContext(o, i, b)}
              operand={o}
              indentation={indentation + 1}
            />
          ))}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 24 }}>
        <IndentationIndicators indentation={indentation + 1} />
        {isAnyOrAll && (
          <div style={{ marginLeft: buttonPadding }}>
            <Button onClick={addOperand} variant="outlined">
              Add Condition
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
