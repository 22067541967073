import TextField from '@mui/material/TextField';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

type DatePickerProps = {
  label?: string;
  value?: any;
  onChange: (date: any | undefined, keyboardInputValue?: string) => void;
  errors?: any[];
  required?: boolean;
  size?: 'small' | 'medium' | undefined;
  disabled?: boolean;
};

// export class UTCUtils extends MomentUtils {
//   format = (value, formatString) => {
//     return moment(value).utc().format(formatString);
//   };
// }

export const DatePicker = ({
  label,
  value,
  onChange,
  size = 'medium',
  errors,
  required,
  disabled,
  ...rest
}: DatePickerProps) => {
  const errorMsg = errors && errors?.length > 0 ? errors[0] : null;
  const hasError = errorMsg ? true : false;

  const handleChange = (dateValue) => {
    if (dateValue) {
      onChange(dateValue);
      //onChange(dateValue.format("YYYY-MM-DD"));
    } else {
      onChange(null);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDatePicker
        //sx={{ ...props.sx }}
        //clearable={true}
        label={label}
        value={value || null} // || null to prevent auto prefill today's date when adding a new listItem: why?
        onChange={handleChange}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            required={required}
            variant={'outlined'}
            error={params.error ? params.error : hasError}
            helperText={errorMsg}
            size={size}
            autoComplete="off"
          />
        )}
      />
    </LocalizationProvider>
  );
};
