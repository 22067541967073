import { ElementType, lazy, Suspense } from 'react';

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  );
};

export const lazyLoadingComponents = () => {
  const HomePage = Loadable(
    lazy(() =>
      import('pages/home/HomePage/HomePage').then((module) => ({
        default: module.HomePage,
      }))
    )
  );

  const LoginPage = Loadable(
    lazy(() =>
      import('pages/login/LoginPage/LoginPage').then((module) => ({
        default: module.LoginPage,
      }))
    )
  );

  const DeveloperListPage = Loadable(
    lazy(() =>
      import('pages/developers/DeveloperListPage/DeveloperListPage').then((module) => ({
        default: module.DeveloperListPage,
      }))
    )
  );

  const DeveloperPage = Loadable(
    lazy(() =>
      import('pages/developers/DeveloperPage/DeveloperPage').then((module) => ({
        default: module.DeveloperPage,
      }))
    )
  );

  const NewDeveloperPage = Loadable(
    lazy(() =>
      import('pages/developers/NewDeveloperPage/NewDeveloperPage').then((module) => ({
        default: module.NewDeveloperPage,
      }))
    )
  );

  const RuleListPage = Loadable(
    lazy(() =>
      import('pages/rules/RuleListPage/RuleListPage').then((module) => ({
        default: module.RuleListPage,
      }))
    )
  );

  const NewRulePage = Loadable(
    lazy(() =>
      import('pages/rules/RulePage/NewRule').then((module) => ({
        default: module.NewRulePage,
      }))
    )
  );

  const EditRulePage = Loadable(
    lazy(() =>
      import('pages/rules/RulePage/EditRule').then((module) => ({
        default: module.EditRulePage,
      }))
    )
  );

  const LookupSyncPage = Loadable(
    lazy(() =>
      import('pages/lookupSync/LookupSyncPage').then((module) => ({
        default: module.LookupSyncPage,
      }))
    )
  );

  const OrderActivityPage = Loadable(
    lazy(() =>
      import('pages/orderActivity/OrderActivityListPage/OrderActivityListPage').then(
        (module) => ({
          default: module.OrderActivityListPage,
        })
      )
    )
  );

  const SelfServicePage = Loadable(
    lazy(() =>
      import('pages/selfService/SelfServicePage/SelfServicePage').then((module) => ({
        default: module.SelfServicePage,
      }))
    )
  );

  const PracticeMetricsPage = Loadable(
    lazy(() =>
      import('pages/practice-metrics/PracticeMetricsPage/PracticeMetricsPage').then(
        (module) => ({
          default: module.PracticeMetricsPage,
        })
      )
    )
  );

  const PracticeMetricsDetailPage = Loadable(
    lazy(() =>
      import(
        'pages/practice-metrics/PracticeMetricsDetailPage/PracticeMetricsDetailPage'
      ).then((module) => ({
        default: module.PracticeMetricsDetailPage,
      }))
    )
  );

  return {
    HomePage,
    LoginPage,
    DeveloperListPage,
    DeveloperPage,
    NewDeveloperPage,
    RuleListPage,
    NewRulePage,
    EditRulePage,
    LookupSyncPage,
    OrderActivityPage,
    SelfServicePage,
    PracticeMetricsPage,
    PracticeMetricsDetailPage,
  };
};
