import { Box, ActionNotification, LinearProgress } from 'components';
import { useTheme } from 'hooks';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { RootState } from 'redux/store';

export function BaseLayout() {
  const theme = useTheme();
  const isLoading = useSelector(
    (state: RootState) =>
      Object.values(state.ensomataApi.queries).some(
        (query) => query?.status === 'pending'
      ) || state.app.loading > 0
  );

  function renderLoadingBar() {
    return (
      <LinearProgress
        sx={{
          position: 'absolute',
          width: '100%',
          top: 0,
          zIndex: theme.zIndex.snackbar,
        }}
      />
    );
  }

  return (
    <Box bgcolor={theme.palette.background.default}>
      {isLoading === true && renderLoadingBar()}

      <Outlet />

      <ActionNotification />
    </Box>
  );
}
