import { Box } from 'components/mui';
import { Field } from '../Constants/Field';
import { SxProps, TextareaAutosize, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { CDSSRuleListDto, Recommendation } from 'types';
import { CSSProperties } from 'react';

interface Props {
  field: Field<any>;
  rule: CDSSRuleListDto | Recommendation;
  handleTextFieldChange: (s: string, field: Field<any>) => void;
  autoFocus?: boolean;
}

const containerStyle: SxProps<Theme> = {
  color: grey[600],
  fontSize: 13,
  background: 'white',
  position: 'absolute',
  top: -8,
  left: 10,
  paddingX: 1,
};

const textareaStyle: CSSProperties = {
  resize: 'vertical',
  width: '100%',
  padding: 12,
  border: `1px solid ${grey[400]}`,
  borderRadius: 4,
};

export default function TextArea({ field, rule, handleTextFieldChange,autoFocus }: Props) {
  return (
    <Box style={{ position: 'relative' }}>
      <Box sx={containerStyle}>{field.label}</Box>
      <TextareaAutosize
        minRows={2}
        maxRows={6}
        autoFocus={autoFocus}
        style={textareaStyle}
        value={rule[field.accessor] ?? ''}
        onChange={(e) => handleTextFieldChange(e.target.value, field)}
      />
    </Box>
  );
}
