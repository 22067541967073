import { MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { Select } from 'components/mui';
import {
  AgeOfFirstVaccinePropertyName,
  AgeOfFirstVaccineOperand,
  OperandValues,
  ConditionalOperatorType,
  CONDITIONAL_OPERATORS,
} from 'types';
import { OperandProps } from './constants/OperandProps';

const vaccines: AgeOfFirstVaccinePropertyName[] = Object.values(
  AgeOfFirstVaccinePropertyName
);

export default function AgeOfFirstVaccine({
  operand,
  updateOperand,
}: OperandProps<AgeOfFirstVaccineOperand>) {
  // check for "value" so that ts doesn't complain about accessing "value" on an operand without value
  const isAgeOfFirstVaccine = operand?.operand === OperandValues.ageOfFirstVaccine;
  if (!isAgeOfFirstVaccine) return <></>;

  const handleVaccineChange = (e: SelectChangeEvent<any>) => {
    updateOperand({
      ...operand,
      vaccine: e.target.value as AgeOfFirstVaccinePropertyName,
    });
  };

  const handleOperatorChange = (e: SelectChangeEvent<any>) => {
    updateOperand({ ...operand, operator: e.target.value as ConditionalOperatorType });
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateOperand({ ...operand, value: Number(e.target.value) });
  };

  return (
    <>
      <Select label="Vaccine" onChange={handleVaccineChange} value={operand.vaccine}>
        {vaccines.map((o, i) => (
          <MenuItem value={o} key={i}>
            {Object.keys(AgeOfFirstVaccinePropertyName)[i]}
          </MenuItem>
        ))}
      </Select>

      <div style={{ display: 'flex', gap: 4 }}>
        <div style={{ width: '50%' }}>
          <Select
            label="Operator"
            onChange={handleOperatorChange}
            value={operand.operator}
          >
            {CONDITIONAL_OPERATORS.map((o, i) => (
              <MenuItem value={o} key={i}>
                {o}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div style={{ width: '50%' }}>
          <TextField
            label="Age"
            onChange={handleValueChange}
            type="number"
            value={operand.value}
          />
        </div>
      </div>
    </>
  );
}
