import { Add } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { Box, Card, IconButton, Stack } from 'components/mui';
import { CDSSRuleListDto, Recommendation, RecommendationType } from 'types';
import { Field } from '../Constants/Field';
import RecommendationCard from './RecommendationCard';

type F = Field<Recommendation>;

interface Props {
  rule: CDSSRuleListDto;
  updateRule: (rule: CDSSRuleListDto) => void;
}

export interface HandleFieldChangeParams {
  recommendation: Recommendation;
  recommendationIndex: number;
  field: F;
  value: string | Date | number | boolean | string[];
}

export interface HandleOrderableItemOptionChangeParams {
  recommendationIndex: number;
  optionIndex: number;
  field: 'orderableItemId' | 'orderableItemName';
  value: number | string;
}

export const newRecommendation: Recommendation = {
  criteria: '',
  defaultAssessmentItemId: undefined,
  defaultIcdCode: undefined,
  defaultDiagnosisName: undefined,
  description: '',
  displayOrder: 0,
  futureOrder: false,
  isOrderable: false,
  orderableItemTypeId: 0,
  orderableItemId: 0,
  orderableItemName: '',
  radTech: false,
  recommendationType: RecommendationType.IMAGING,
  required: false,
};

export default function Recommendations({ rule, updateRule }: Props) {
  const removeRec = (rec: Recommendation) => {
    const r = { ...rule };
    r.recommendations = r.recommendations?.filter(
      (r) => JSON.stringify(rec) !== JSON.stringify(r)
    );
    updateRule(r);
  };

  const addRec = () => {
    const r = { ...rule };
    r.recommendations = [newRecommendation, ...(r.recommendations ?? [])];
    updateRule(r);
  };

  const addOrderableItemOption = (rec: Recommendation) => {
    const r = { ...rule };
    const found = r.recommendations.findIndex(
      (i) => JSON.stringify(i) === JSON.stringify(rec)
    );

    rec = { ...r.recommendations[found] };

    if (!rec.orderableItemOptions) {
      rec.orderableItemOptions = [];
    }
    rec.orderableItemOptions.push({
      orderableItemId: 0,
      orderableItemName: '',
    });

    r.recommendations = r.recommendations.map((original, i) => {
      if (i === found) return rec;
      return original;
    });

    updateRule(r);
  };

  const removeOrderableItemOption = (
    recIndex: number,
    orderableItemOptionIndex: number
  ) => {
    const r = { ...rule };

    r.recommendations = r.recommendations.map((rec, i) => {
      if (i === recIndex) {
        const recommendation = { ...r.recommendations[recIndex] };
        recommendation.orderableItemOptions = recommendation.orderableItemOptions?.filter(
          (option, idx) => idx !== orderableItemOptionIndex
        );
        return recommendation;
      }
      return rec;
    });

    console.log('removed', recIndex, orderableItemOptionIndex);

    updateRule(r);
  };

  const handleOrderableItemOptionUpdate = (
    update: HandleOrderableItemOptionChangeParams
  ) => {
    const updatedRule = { ...rule };
    updatedRule.recommendations = rule.recommendations.map((r, i) => {
      if (i === update.recommendationIndex) {
        const options = r.orderableItemOptions?.map((option, j) => {
          if (j === update.optionIndex) {
            return { ...option, [update.field]: update.value };
          }
          return option;
        });
        return { ...r, orderableItemOptions: options };
      }
      return r;
    });

    updateRule(updatedRule);
  };

  const handleFieldChange = (vals: HandleFieldChangeParams) => {
    const updatedRule = { ...rule };
    updatedRule.recommendations = rule.recommendations?.map((r, i) => {
      if (i === vals.recommendationIndex) {
        return {
          ...r,
          [vals.field.accessor]: vals.value,
        };
      }
      return r;
    });
    updateRule(updatedRule);
  };

  return (
    <Card>
      <Box
        style={{
          marginBottom: '12px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box> Recommendations </Box>
        <IconButton sx={{ border: `1px solid ${grey[300]}` }} onClick={addRec}>
          <Add />
        </IconButton>
      </Box>
      <Box sx={{ padding: '12px' }} />
      {/* 
          use the recomendations as key so we can force a rerender 
          of the list when adding/removing an entry 
      */}
      <Stack key={rule.recommendations?.length} spacing="sm">
        {rule.recommendations?.map((rec, i) => (
          <RecommendationCard
            {...{
              rec,
              i,
              removeRec,
              addOrderableItemOption,
              removeOrderableItemOption,
              handleFieldChange,
              handleOrderableItemOptionUpdate,
            }}
            key={i}
          />
        ))}
      </Stack>
    </Card>
  );
}
