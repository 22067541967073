"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderableItemTypeId = exports.OrderableItemType = void 0;
var OrderableItemType;
(function (OrderableItemType) {
    OrderableItemType["Lab"] = "Lab";
    OrderableItemType["Imaging"] = "Diagnostic Imaging";
    OrderableItemType["Procedure"] = "Procedure";
})(OrderableItemType = exports.OrderableItemType || (exports.OrderableItemType = {}));
var OrderableItemTypeId;
(function (OrderableItemTypeId) {
    OrderableItemTypeId[OrderableItemTypeId["Lab"] = 0] = "Lab";
    OrderableItemTypeId[OrderableItemTypeId["Imaging"] = 1] = "Imaging";
    OrderableItemTypeId[OrderableItemTypeId["Procedure"] = 3] = "Procedure";
})(OrderableItemTypeId = exports.OrderableItemTypeId || (exports.OrderableItemTypeId = {}));
