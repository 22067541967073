import { Page } from 'components';
import RuleBuilder from 'components/RuleBuilder';
import { defaultRule } from 'constants/DefaultRule';
import { useState } from 'react';
import { CDSSRuleListDto } from 'types';

export const newRulePageRoute = {
  name: 'New Rule',
  template: 'new',
  getPath: () => {
    return `${'new'}`;
  },
};

export const NewRulePage = () => {
  const [rule, setRule] = useState<CDSSRuleListDto>(defaultRule);

  return (
    <Page header="New Rule">
      <RuleBuilder updateRule={setRule} rule={rule} />
    </Page>
  );
};
