import { useMediaQuery } from '@mui/material';
import { useTheme } from 'hooks';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export const useNavigationBar = (): {
  isNavBarDrawer: boolean;
  showPin: boolean;
  navPinned: boolean;
} => {
  const theme = useTheme();
  const navPinned = useSelector((state: RootState) => state.app.navPinned);
  const isNavBarDrawer = !useMediaQuery(theme.breakpoints.up('lg')) || !navPinned;
  const showPin = useMediaQuery(theme.breakpoints.up('lg'));

  return {
    isNavBarDrawer: isNavBarDrawer,
    showPin: showPin,
    navPinned: navPinned,
  };
};
