import { configureStore } from '@reduxjs/toolkit';
import { ensomataApi } from 'api';
import appReducer from 'redux/appSlice';

export const store = configureStore({
  reducer: {
    app: appReducer,

    [ensomataApi.reducerPath]: ensomataApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(ensomataApi.middleware), // by Andy: if commented out, the ensomataApi sometimes doesn't work
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
