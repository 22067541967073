import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ReactNode, useState } from 'react';
import { Box, BoxProps, IconButton, Menu, MenuItem } from '../../components';

export type MenuItemType = {
  label: ReactNode;
  action: () => void;
};

export type DropDownMenuProps = Omit<BoxProps, 'children'> & {
  menuItems?: MenuItemType[];
};

export const DropDownMenu = ({ menuItems, ...rest }: DropDownMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const renderMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {menuItems?.map((menuItem, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                menuItem.action();
                setAnchorEl(null);
              }}
            >
              {menuItem.label}
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  return (
    <Box display="inline-flex" {...rest}>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <ArrowDropDownIcon />
      </IconButton>

      {renderMenu()}
    </Box>
  );
};
