import { Alert, AlertColor, Snackbar as MuiSnackBar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { theme } from 'theme';

export interface IActionNotification {
  key: number;
  message: string;
  alertColor: AlertColor;
}

export interface State {
  open: boolean;
  snackPack: readonly IActionNotification[];
  messageInfo?: IActionNotification;
}

export function ActionNotification() {
  const toast = useSelector((state: RootState) => state.app.toast);
  const [snackPack, setSnackPack] = useState<readonly IActionNotification[]>([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState<IActionNotification | undefined>(
    undefined
  );

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  useEffect(() => {
    if (toast) {
      setSnackPack((prev) => [
        ...prev,
        { key: toast.timeSent, message: toast.message, alertColor: toast.alertColor },
      ]);
    }
  }, [toast]);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = (): void => {
    setMessageInfo(undefined);
  };

  return (
    <MuiSnackBar
      key={messageInfo ? messageInfo.key : undefined}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      message={messageInfo ? messageInfo.message : undefined}
    >
      <Alert
        severity={messageInfo?.alertColor}
        variant="filled"
        sx={{ fontWeight: theme.typography.fontWeightBold }}
      >
        {messageInfo?.message}
      </Alert>
    </MuiSnackBar>
  );
}
