import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export const Button = (theme: Theme) => {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: '80px !important',
          fontWeight: theme.typography.fontWeightBold,
        },
      },
    },
  };
};
