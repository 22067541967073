import { Amplify } from 'aws-amplify';
import { getRedirectLink, IS_PROD } from '../constants';

Amplify.configure({
  Auth: {
    // // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: "XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab",

    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',

    // // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: "XX-XXXX-X",

    // // OPTIONAL - Amazon Cognito User Pool ID
    // userPoolId: "us-east-2_Zu9INpx6v",
    // userPoolId: "us-east-2_zv7X3Y6WQ",

    userPoolId: IS_PROD
      ? 'us-east-1_EzspuTsdd' // PROD
      : 'us-east-1_efNM1nhDV', // DEV

    // // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    // userPoolWebClientId: "73n8jle34qv8ke1cu0kv2nc532",
    // userPoolWebClientId: "6r1sleeu6pd5vphj7e3udi9qgs",
    userPoolWebClientId: IS_PROD
      ? '3sbamsee2acvtk18mb7h663hap' // PROD
      : '1af7bs6lja2hb7f2skd2tmuqdi', // DEV

    // // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    // mandatorySignIn: false,

    // // OPTIONAL - Configuration for cookie storage
    // // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //   domain: ".yourdomain.com",
    //   // OPTIONAL - Cookie path
    //   path: "/",
    //   // OPTIONAL - Cookie expiration in days
    //   expires: 365,
    //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //   sameSite: "strict" | "lax",
    //   // OPTIONAL - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //   secure: true,
    // },

    // // OPTIONAL - customized storage object
    // storage: MyStorage,

    // // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: "USER_PASSWORD_AUTH",

    // // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: "myCustomValue" },

    // // OPTIONAL - Hosted UI configuration
    // oauth: {
    //   domain: "your_cognito_domain",
    //   scope: [
    //     "phone",
    //     "email",
    //     "profile",
    //     "openid",
    //     "aws.cognito.signin.user.admin",
    //   ],
    //   redirectSignIn: "http://localhost:3000/",
    //   redirectSignOut: "http://localhost:3000/",
    //   responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    // },

    oauth: {
      domain: IS_PROD
        ? 'ensomata-prod.auth.us-east-1.amazoncognito.com' // PROD
        : 'ensomata-dev.auth.us-east-1.amazoncognito.com', // DEV
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: getRedirectLink(),
      redirectSignOut: getRedirectLink(),
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});
