import { LookupSyncDto } from '@ensomata/common/dto';
import { createApi } from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';
import { ClientError, gql, request } from 'graphql-request';
import { CDSSRuleListDto, CDSSRuleVersion, CreateCDSSRule, Operand } from 'types';
import { getApiUrl } from '../constants';

const BASE_URL = getApiUrl();

const graphqlBaseQuery =
  ({ baseUrl }: { baseUrl: string }) =>
  async ({ body, variables }: { body: string; variables?: Record<string, any> }) => {
    // if (USE_LOCAL_MOCK) {
    //   // DEBUGGING ONLY
    //   return SAMPLE_API_RESPONSE_DATA;
    // }

    const authData = await Auth.currentAuthenticatedUser();
    const token = authData?.signInUserSession?.idToken?.jwtToken;
    // console.log(`JWT token: ${token}`);

    try {
      const result = await request(baseUrl, body, variables, {
        authorization: token,
      });
      return { data: result };
    } catch (error) {
      if (error instanceof ClientError) {
        return {
          error: { message: error?.message },
        };
      }
      return { error: { status: 500, data: error } };
    }
  };

export const ensomataApi = createApi({
  reducerPath: 'ensomataApi',
  tagTypes: ['Rule'],
  baseQuery: graphqlBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: (builder) => ({
    getRule: builder.query({
      providesTags: ['Rule'],
      query: (id: string) => ({
        body: gql`
          query GetCDSSRule {
            getCDSSRule(id: "${id}") {
              rule {
                id
                version
                name
                displayName
                active
                order
                enDesc
                esDesc
                preventative
                rules
                recommendations {
                  criteria
                  defaultAssessmentItemId
                  defaultDiagnosisName
                  defaultIcdCode
                  description
                  displayOrder
                  futureOrder
                  isOrderable
                  orderableItemId
                  orderableItemName
                  orderableItemTypeId
                  radTech
                  recommendationType
                  required
                  resultAttribute
                  resultAttributeId
                  orderableItemOptions {
                    orderableItemId
                    orderableItemName
                  }
                }
                datePosted
                types
              }
              versions {
                version
                createdBy
                timestamp
                diffs 
              }
            }
        }
          `,
      }),
      transformResponse: (response) => {
        const res = response.getCDSSRule as {
          rule: CDSSRuleListDto | undefined;
          versions: CDSSRuleVersion[];
        };
        if (res.rule?.rules) {
          if (typeof res.rule.rules === 'string') {
            const rulesObj = JSON.parse(res.rule.rules) as Operand;
            res.rule.rules = rulesObj;
          }
        }
        return res;
      },
    }),
    updateRule: builder.mutation({
      invalidatesTags: ['Rule'],
      query: (rule: Partial<CDSSRuleListDto>) => ({
        variables: { rule },
        body: gql`
          mutation UpdateCDSSRule($rule: UpdateCDSSRule) {
            updateCDSSRule(rule: $rule) {
              id
            }
          }
        `,
      }),
      transformResponse: (response) => {
        return response.getCDSSRule as {
          rule: CDSSRuleListDto | undefined;
          versions: CDSSRuleVersion[];
        };
      },
    }),
    createRule: builder.mutation({
      invalidatesTags: ['Rule'],
      query: (rule: CreateCDSSRule) => {
        return {
          variables: { rule },
          body: gql`
            mutation AddCDSSRule($rule: CreateCDSSRule) {
              addCDSSRule(rule: $rule) {
                id
              }
            }
          `,
        };
      },
      transformResponse: (response) => {
        return response.addCDSSRule?.id as string | undefined;
      },
    }),
    getRules: builder.query({
      providesTags: ['Rule'],
      query: () => ({
        body: gql`
          query GetRules {
            listCDSSRules {
              rules {
                id
                name
                displayName
                order
                version
                datePosted
              }
            }
          }
        `,
      }),
      transformResponse: (response) => {
        const res = response?.listCDSSRules;
        return {
          rules: res.rules,
        };
      },
    }),
    getOrderMetrics: builder.query({
      query: (variables: {
        starts?: string;
        ends?: string;
        staff?: number | undefined;
        locations?: number | undefined;
      }) => ({
        body: gql`
          query GetOrderMetrics
          (
            $starts: AWSDateTime, 
            $ends: AWSDateTime, 
            ${variables.staff ? '$staff: [Int],' : ''}
            ${variables.locations ? '$locations: [Int],' : ''}
          ) {
            getOrderMetrics
              (
                starts: $starts, 
                ends: $ends, 
                ${variables.staff ? 'staff: $staff,' : ''}
                ${variables.locations ? 'locations: $locations,' : ''}
              ) {
              csvReportUrl
              orderMetrics {
                staffId
                staffName
                assessmentItemId
                orderAccepted
                orderDate
                orderName
                orderReason
                orderLocationName
                serviceLocationName
                serviceLocationId
                orderId
                orderLocationId
                providerId
                providerName
                encounterId
                patientId
              }
            }
          }
        `,
        variables,
      }),
      transformResponse: (response) => {
        const res = response?.getOrderMetrics;
        return {
          orderMetrics: res.orderMetrics,
          csvReportUrl: res.csvReportUrl,
        };
      },
    }),
    getLocations: builder.query({
      query: (variables: { limit?: number }) => ({
        body: gql`
          query GetLocations($limit: Int = 10000) {
            getLocations(limit: $limit) {
              locations {
                locationID
                locationName
              }
            }
          }
        `,
        variables,
      }),
      transformResponse: (response) => {
        const res = response?.getLocations;
        return {
          locations: res.locations,
        };
      },
    }),
    getRaceCodes: builder.query({
      query: () => ({
        body: gql`
          query GetRaceCodes {
            getRaceCodes {
              label
              value
            }
          }
        `,
      }),
      transformResponse: (response) => {
        const res = response?.getRaceCodes as { label: string; value: string }[];
        return res;
      },
    }),
    syncLookups: builder.mutation({
      query: (input: LookupSyncDto) => {
        return {
          variables: { input },
          body: gql`
            mutation SyncLookups($input: SyncLookupsInput!) {
              syncLookups(input: $input) {
                entries {
                  eventId
                  errorCode
                  errorMessage
                }
                failedEntryCount
              }
            }
          `,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),
    getStaff: builder.query({
      query: (variables: { limit?: number }) => ({
        body: gql`
          query GetStaff($limit: Int = 10000) {
            getStaff(limit: $limit) {
              staff {
                staffId
                staffName
              }
            }
          }
        `,
        variables,
      }),
      transformResponse: (response) => {
        const res = response?.getStaff;
        return {
          staff: res.staff,
        };
      },
    }),
    adminGetRecommendationLogs: builder.query({
      query: (variables: { patientId?: number; days?: number }) => ({
        body: gql`
          query AdminGetRecommendationLogs {
            adminGetRecommendationLogs(
              patient: ${variables.patientId}, 
              ${variables.days ? `days: ${variables.days}` : ''}
            )
          }
        `,
        variables,
      }),
      transformResponse: (response) => {
        const res = response?.adminGetRecommendationLogs;
        return {
          recommendationLogs: res,
        };
      },
    }),
    adminGetRecommendationRequestPayloads: builder.query({
      query: (variables: { patientId?: number; days?: number }) => ({
        body: gql`
          query AdminGetRecommendationRequestPayloads {
            adminGetRecommendationRequestPayloads(
              patient: ${variables.patientId}, 
              ${variables.days ? `days: ${variables.days}` : ''}
            ){
              date
              id
              params
            }
          }
        `,
        variables,
      }),
      transformResponse: (response) => {
        const res = response?.adminGetRecommendationRequestPayloads;
        return {
          requestPayloads: res,
        };
      },
    }),
    adminGetRecommendations: builder.query({
      query: (variables: { payload: string }) => ({
        body: gql`
          query AdminGetRecommendations($payload: String) {
            adminGetRecommendations(payload: $payload) {
              orderableItemId
              orderableItemName
            }
          }
        `,
        variables,
      }),
      transformResponse: (response) => {
        const res = response?.adminGetRecommendations;
        return {
          recommendations: res,
        };
      },
    }),
  }),
});
