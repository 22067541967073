import { ThemeProvider } from '@mui/material/styles';
import { Drawer, Logo, Paper, Stack, Switch } from 'components';
import { LOCAL_STORAGE_NAV_PINNED } from 'constants/localStorageConstants';
import { useNavigationBar, useTheme } from 'hooks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAppNavDrawerOpen, setAppNavPinned } from 'redux/appSlice';
import { RootState } from 'redux/store';

import { darkTheme } from 'theme';
import { NavigationBarMenu } from './NavigationBarMenu';

export const NavigationBar = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const appNavDrawerOpen = useSelector((state: RootState) => state.app.navDrawerOpen);
  const { isNavBarDrawer, showPin, navPinned } = useNavigationBar();

  useEffect(() => {
    const navPinned = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_NAV_PINNED) || 'false'
    );
    dispatch(setAppNavPinned(navPinned));
  }, [dispatch]);

  const togglePin = () => {
    const _navPinned = !navPinned;
    dispatch(setAppNavPinned(_navPinned));
    localStorage.setItem(LOCAL_STORAGE_NAV_PINNED, _navPinned.toString());
  };

  const navigationBar = (
    <Paper
      sx={{
        position: 'relative',
        height: '100vh',
        width: '285px',
        borderRadius: '0px',
        overflow: 'auto',
        flexShrink: 0,
        zIndex: theme.zIndex.appBar,
      }}
    >
      <Stack spacing="lg">
        <Stack direction="row" justifyContent={'space-between'} pt={3} px={3}>
          <Logo variant="word-white" />

          {showPin && <Switch onClick={() => togglePin()} checked={navPinned}></Switch>}
        </Stack>

        <NavigationBarMenu />
      </Stack>
    </Paper>
  );

  return (
    <ThemeProvider theme={darkTheme}>
      {isNavBarDrawer ? (
        <Drawer
          anchor={'left'}
          open={appNavDrawerOpen}
          onClose={() => dispatch(setAppNavDrawerOpen(false))}
        >
          {navigationBar}
        </Drawer>
      ) : (
        navigationBar
      )}

      {/* {navigationBar} */}
    </ThemeProvider>
  );
};
