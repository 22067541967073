// @mui
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "components";
import { useNavigationBar } from "hooks";
import { useDispatch } from "react-redux";
import { setAppNavDrawerOpen } from "redux/appSlice";

export const AppTopBarNavTrigger = () => {
  const { isNavBarDrawer } = useNavigationBar();
  const dispatch = useDispatch();
  
  if (!isNavBarDrawer) return null;

  return (
    <IconButton size="large" edge="start" aria-label="menu" onClick={() => dispatch(setAppNavDrawerOpen(true))}>
      <MenuIcon />
    </IconButton>
  );
};
