import { Box, Button, Stack } from "components";
import { getSpacing } from "theme";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

type PaginationProps = {
  total: number | undefined;
  page: number;
  size: number;
  onPageChange: (page: number) => void;
  tableContainerRef?: React.RefObject<HTMLDivElement>;
};

export const Pagination = ({ total, page, size, onPageChange, tableContainerRef }: PaginationProps) => {
  let totalPages: number | undefined = total !== undefined ? Math.ceil(total / size) : undefined;
  if (totalPages === 0) {
    totalPages = 1;
  }

  const handlePageChange = (page: number) => {
    onPageChange(page);
    if (tableContainerRef) tableContainerRef?.current?.scrollTo(0, 0); // scroll table to the top
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" py={getSpacing("sm")} px={5}>
      <Box>{total} records</Box>
      
      <Stack direction="row" spacing="md" alignItems="center">
        <Button
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
          sx={{ minWidth: "0px !important" }}
          variant="contained"
        >
          <ChevronLeftIcon />
        </Button>
        <Box>
          Page {page} of {totalPages}
        </Box>
        <Button
          disabled={page === totalPages}
          onClick={() => handlePageChange(page + 1)}
          sx={{ minWidth: "0px !important" }}
          variant="contained"
        >
          <ChevronRightIcon />
        </Button>
      </Stack>
    </Stack>
  );
};
