import { CDSSRuleListDto } from 'types';
import { Stack, Box, Button } from 'components/mui';
import useUpsertRule from '../hooks/useUpsertRule';

const textStyling = { whiteSpace: 'nowrap' };

export default function Header({ rule }: { rule: CDSSRuleListDto }) {
  const title = rule.id ? 'Edit Rule' : 'Create Rule';
  const { isLoading, upsert } = useUpsertRule();

  return (
    <Stack direction="row" justifyContent="space-between">
      <Box sx={{ display: 'flex', gap: 4 }}>
        <Box component={'p'} sx={textStyling}>
          {title}
        </Box>
        {rule.id && (
          <Box display={'flex'}>
            <Box component="p" paddingRight={2}>
              Version {rule.version + 1}
            </Box>
            <Box component="p" sx={textStyling}>
              Last updated: {new Date(rule.datePosted).toLocaleDateString()} at{' '}
              {new Date(rule.datePosted).toLocaleTimeString()}
            </Box>
          </Box>
        )}
      </Box>
      <Button disabled={isLoading} onClick={() => upsert(rule)} variant="outlined">
        Save
      </Button>
    </Stack>
  );
}
