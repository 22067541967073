import { Remove } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { Button, TextField } from 'components/mui';
import { OrderableItemOption } from 'types';
import { HandleOrderableItemOptionChangeParams } from '../Recommendation';

interface Props {
  option: OrderableItemOption;
  i: number;
  j: number;
  removeOrderableItemOption: (recIndex: number, orderableItemOptionIndex: number) => void;
  handleOrderableItemOptionUpdate: (
    update: HandleOrderableItemOptionChangeParams
  ) => void;
}

const OrderableItemOptionRow = ({
  option,
  i,
  j,
  removeOrderableItemOption,
  handleOrderableItemOptionUpdate,
}: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        paddingBottom: '10px',
      }}
    >
      <TextField
        onChange={(e) =>
          handleOrderableItemOptionUpdate({
            recommendationIndex: i,
            optionIndex: j,
            field: 'orderableItemId',
            value: Number(e.target.value),
          })
        }
        type={'number'}
        value={option.orderableItemId}
        label={'Orderable Item Id'}
      />

      <TextField
        onChange={(e) =>
          handleOrderableItemOptionUpdate({
            recommendationIndex: i,
            optionIndex: j,
            field: 'orderableItemName',
            value: e.target.value,
          })
        }
        type={'text'}
        value={option.orderableItemName}
        label={'Orderable Item Name'}
      />

      <Button
        onClick={() => removeOrderableItemOption(i, j)}
        sx={{ border: `1px solid ${grey[300]}` }}
      >
        <Remove />
      </Button>
    </div>
  );
};

export default OrderableItemOptionRow;
