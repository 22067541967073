import { Box, Card, Stack, Switch } from 'components';
import { useState } from 'react';

type DeveloperBasicInfoProps = {
  id?: string;
};

export const DeveloperBasicInfo = ({ id }: DeveloperBasicInfoProps) => {
  const [toggle, setToggle] = useState<boolean>(false);

  return (
    <Card>
      <Stack
        direction="row"
        spacing="md"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack spacing="md">
          <Box>Developer Page</Box>
          <Box>ID: {id}</Box>
        </Stack>

        <Box>
          <Switch
            label="Some Toggle"
            checked={toggle}
            onChange={(e) => setToggle(e.target.checked)}
          />
        </Box>
      </Stack>
    </Card>
  );
};
