import { MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { NoPreviousTestOperand, OperandValues, RecommendationType } from 'types';
import { OperandProps } from './constants/OperandProps';
import { Select } from 'components/mui';

const testTypes: RecommendationType[] = Object.values(RecommendationType);

export default function NoPreviousTest({
  operand,
  updateOperand,
}: OperandProps<NoPreviousTestOperand>) {
  // check for "value" so that ts doesn't complain about accessing "value" on an operand without value
  const isPrev = operand?.operand === OperandValues.noPreviousTest;
  if (!isPrev) return <></>;

  const handleLabItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateOperand({ ...operand, orderableLabItemId: e.target.value });
  };

  const handleTestTypeChange = (e: SelectChangeEvent<any>) => {
    updateOperand({ ...operand, testType: e.target.value as RecommendationType });
  };

  return (
    <>
      <TextField
        label="Orderable Lab Item ID"
        onChange={handleLabItemChange}
        value={operand.orderableLabItemId}
      />

      <Select label="Test Type" onChange={handleTestTypeChange} value={operand.testType}>
        {testTypes.map((o, i) => (
          <MenuItem value={o} key={i}>
            {o}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
