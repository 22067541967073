import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Select } from 'components/mui';
import { OperandValues, SmokingStatusOperand, SmokingStatusOption } from 'types';
import { OperandProps } from './constants/OperandProps';

const operators: SmokingStatusOperand['operator'][] = ['=', '!'];
const values: SmokingStatusOption[] = Object.values(SmokingStatusOption);

export default function SmokingStatus({
  operand,
  updateOperand,
}: OperandProps<SmokingStatusOperand>) {
  // check for "value" so that ts doesn't complain about accessing "value" on an operand without value
  const isSmokingStatus = operand?.operand === OperandValues.smokingStatus;
  if (!isSmokingStatus) return <></>;

  const handleOperatorChange = (e: SelectChangeEvent<any>) => {
    updateOperand({ ...operand, operator: e.target.value as (typeof operators)[0] });
  };

  const handleValueChange = (e: SelectChangeEvent<any>) => {
    updateOperand({ ...operand, value: e.target.value as (typeof values)[0] });
  };

  return (
    <>
      <Select onChange={handleOperatorChange} value={operand.operator}>
        {operators.map((o, i) => (
          <MenuItem value={o} key={i}>
            {o}
          </MenuItem>
        ))}
      </Select>
      <Select onChange={handleValueChange} value={operand.value}>
        {values.map((o, i) => (
          <MenuItem value={o} key={i}>
            {o}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
