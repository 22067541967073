import {
  MetricsReportDataPackage,
  MetricsReportDataPackageVisualization,
} from 'bi-report/BiReportApi';
import { Chart, ChartData, ChartOptions } from 'chart.js';

import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from 'components';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { getChartColor } from 'services';

import annotationPlugin, { AnnotationOptions } from 'chartjs-plugin-annotation';

// Register the annotation plugin
Chart.register(annotationPlugin);

type MetricRow = {
  label: string;
  value: number;
  trend: number;
  trend_is_good: number;
};

export const DataPackage = (props: {
  dataPackage: MetricsReportDataPackage;
  reportDate: string;
  target?: string | undefined;
}) => {
  const dataSet = props?.dataPackage?.dataSet ?? [];
  const reportDate = props.reportDate;
  const target = props.target;
  const targetValue = target ? parseFloat(target) : undefined;

  const propertyNames = dataSet && dataSet.length > 0 ? Object.keys(dataSet[0]) : [];

  const dataGrid = (visIndex: number) => {
    const weekRange = getWeekRange(reportDate);
    return (
      <Box sx={{ overflow: 'auto' }}>
        <Table sx={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              {propertyNames?.map((propertyName, index) => (
                <TableCell
                  key={`dataSet-${visIndex}-header-row-${index}`}
                  align={index === 0 ? 'left' : 'right'}
                >
                  {index === 0
                    ? propertyName
                    : attemptColumnNameReplacement(propertyName, weekRange, index)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSet?.map((row, index) => (
              <TableRow key={`dataSet-${visIndex}-row-${index}`}>
                {Object.values(row).map((value: any, cellIndex) => (
                  <TableCell key={cellIndex} align={cellIndex === 0 ? 'left' : 'right'}>
                    {value}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    );
  };

  const barChart = (chartType: 'bar' | 'stackedBar', visIndex: number) => {
    const data: ChartData<'bar', any[], any> = {
      labels: (dataSet as MetricRow[]).map((row: MetricRow) => row.label),
      datasets: [
        {
          // label: 'Dataset',
          data: (dataSet as MetricRow[]).map((row: MetricRow) => row.value),
          backgroundColor: (dataSet as MetricRow[]).map((row: MetricRow, index: number) =>
            row.trend_is_good === 1 ? getChartColor(1) : getChartColor(0)
          ),
          minBarLength: 2,
          maxBarThickness: 45,
          borderRadius: {
            topLeft: 10,
            topRight: 10,
            bottomLeft: 0,
            bottomRight: 0,
          },
        },
      ],
    };

    const beginAtZero = (dataSet as MetricRow[]).every((row: MetricRow) => row.value >= 0);

    // draws a line on the chart at the target value, if it's returned in the data package
    const annotation =
      targetValue && targetValue > 0
        ? {
            annotations: {
              targetLine: {
                type: 'line',
                yMin: targetValue, // Position of the line
                yMax: targetValue,
                borderColor: getChartColor(2),
                borderWidth: 2,
                label: {
                  content: 'Goal',
                  position: 'start',
                  color: getChartColor(2),
                  backgroundColor: 'rgba(0,0,0,0)',
                  padding: 4, // Padding around the label text
                },
              } as AnnotationOptions<'line'>,
            },
          }
        : undefined;

    const options: ChartOptions<'bar'> = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
          display: false, // hide because it isn't in the mocks
        },
        title: {
          display: true,
          //text: 'Chart Title',
        },
        tooltip: {
          enabled: true,
        },
        annotation: annotation,
      },
      scales: {
        x: {
          stacked: chartType === 'stackedBar',
        },
        y: {
          stacked: chartType === 'stackedBar',
          beginAtZero,
        },
      },
    };

    return (
      <Box
        key={`chart-box-${visIndex}`}
        style={{ height: '60vh', minHeight: '300px', maxHeight: '477px' }}
      >
        <Bar key={`chart-${visIndex}`} data={data} options={options} />
        <Typography variant="caption" align="center">
          Dates shown are the starting Monday of the week.
          {target ? ` Goal: ${target}` : ''}
        </Typography>
      </Box>
    );
  };

  return (
    <Card header={props.dataPackage.title ? props.dataPackage.title : undefined}>
      <Grid container spacing="xl">
        {/* {JSON.stringify(props.dataPackage, null, 4)} */}
        {props.dataPackage.visualizations.map((vis, index) => {
          let visComponent: JSX.Element | null = null;
          switch (vis) {
            case MetricsReportDataPackageVisualization.BarChart:
              visComponent = barChart('bar', index);
              break;
            case MetricsReportDataPackageVisualization.StackedBarChart:
              visComponent = barChart('stackedBar', index);
              break;
            case MetricsReportDataPackageVisualization.Table:
              visComponent = dataGrid(index);
              break;
            default:
              visComponent = <div>Unknown visualization type "{vis}"</div>;
          }

          return (
            <Grid key={`visualization-${index}`} item xs={12}>
              {visComponent}
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
};

/**
 *
 * @param str
 * @returns a corrected column name depending on the date of the report
 */
function attemptColumnNameReplacement(str: string, weekRange: string[], index: number) {
  switch (str.toLowerCase()) {
    case 'currentweek':
    case 'lastweek':
    case 'twoweeksago':
    case 'threeweeksago':
    case 'fourweeksago':
    case 'fiveweeksago':
    case 'sixweeksago':
      return weekRange[index - 1];
    default:
      return str;
  }
}

/**
 * the reportDate is a string in the format 'yyyy-mm-dd'
 * @param reportDate - as a string
 *
 * @returns an array of dates for the week of the report date
 */
function getWeekRange(reportDate: string | undefined): string[] {
  const reportDateAsMoment = moment(reportDate, 'YYYY-MM-DD');
  const currentWeeksMonday = new Date();
  currentWeeksMonday.setDate(
    currentWeeksMonday.getDate() - ((currentWeeksMonday.getDay() + 6) % 7)
  );
  const results: string[] = [];

  for (let i = 1; i < 7; i++) {
    // format the date in 'mm/dd/yy' format
    const d = moment(reportDateAsMoment).subtract(i, 'weeks').format('MM/DD/YY');
    results.push(d);
  }

  return results.reverse();
}
