import { MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { Select } from 'components/mui';
import {
  OperandValues,
  TestResultDatePropertyName,
  NoTestResultDateWithinOperand,
} from 'types';
import { OperandProps } from './constants/OperandProps';

const testValues: string[] = Object.values(TestResultDatePropertyName);
const periodTypes: (keyof NoTestResultDateWithinOperand['duration'])[] = [
  'days',
  'weeks',
  'months',
  'years',
];

export default function NoTestResultDateWithin({
  operand,
  updateOperand,
}: OperandProps<NoTestResultDateWithinOperand>) {
  // check for "value" so that ts doesn't complain about accessing "value" on an operand without value
  const isTestResult = operand?.operand === OperandValues.noTestResultDateWithin;
  if (!isTestResult) return <></>;

  const handleTestSelection = (e: SelectChangeEvent<any>) => {
    updateOperand({ ...operand, test: e.target.value as TestResultDatePropertyName });
  };

  const handlePeriodTypeChange = (e: SelectChangeEvent<any>) => {
    const val = e.target.value as (typeof periodTypes)[0];
    const duration = {
      [val]: 1,
    };
    updateOperand({ ...operand, duration });
  };

  const handlePeriodValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = Object.keys(operand.duration)?.[0];
    const duration = {
      [key]: e.target.value,
    };
    updateOperand({ ...operand, duration });
  };

  return (
    <>
      <Select label="Test" onChange={handleTestSelection} value={operand.test}>
        {testValues.map((o, i) => (
          <MenuItem value={o} key={i}>
            {o}
          </MenuItem>
        ))}
      </Select>

      <div style={{ display: 'flex', gap: 4 }}>
        <div style={{ width: '50%' }}>
          <Select
            value={Object.keys(operand.duration)?.[0]}
            label="Period"
            onChange={handlePeriodTypeChange}
          >
            {periodTypes.map((p) => (
              <MenuItem key={p} value={p}>
                {p}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div style={{ width: '50%' }}>
          <TextField
            value={Object.values(operand.duration)?.filter((o) => !!o) || 0}
            label="Duration"
            onChange={handlePeriodValueChange}
            type="number"
          />
        </div>
      </div>
    </>
  );
}
