import { createTheme } from '@mui/material/styles';
import { overrideComponents } from './overrideComponents/components';

// A custom theme for this app
export let darkTheme = createTheme({
  //spacing: 6, // 8 is default
  palette: {
    mode: 'dark',
    text: {
      primary: 'rgba(255, 255, 255)',
    },
    primary: {
      light: 'green',
      main: 'rgba(255, 255, 255)',
      dark: 'rgb(255 238 226)',
    },
    background: {
      default: '#ED4924',
      paper: '#ED4924',
      //default: "#aa1f00",
      //paper: "#aa1f00",
      //neutral: "#fdfdfd",
      //hover: 'rgba(13, 98, 141, 0.04)'
    },
  },
  zIndex: {},
});

darkTheme.components = overrideComponents(darkTheme);
