import { ReactNode, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, BoxProps, Collapse, Divider, IconButton, Menu, MenuItem } from "../../components";

type ListIdsSelectorProps = Omit<BoxProps, "children"> & {
  selectedIds: number[];
  setSelectedIds: (value: React.SetStateAction<number[]>) => void;
  totalIds: number[];
  actionMenuItems?: ListActionMenuItem[];
};

export type ListActionMenuItem = {
  label: ReactNode;
  action: (selectedIds: number[]) => void;
};

export const ListIdsSelector = ({
  selectedIds,
  setSelectedIds,
  totalIds,
  actionMenuItems,
  ...rest
}: ListIdsSelectorProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <Box {...rest}>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Box display="flex" alignItems="center">
          {selectedIds.length > 0 && <Box fontSize="0.8rem">{selectedIds.length}</Box>}
          <ArrowDropDownIcon />
        </Box>
      </IconButton>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            setSelectedIds(totalIds);

            // fetchFunction().then((response) => {
            //   const selectingIds: number[] | undefined = response as unknown as number[] | undefined;
            //   let _selectedIds: number[] = [...selectedIds] ?? [];

            //   if (selectingIds) {
            //     for (let i: number = 0; i < selectingIds.length; i++) {
            //       if (!_selectedIds.includes(selectingIds[i])) {
            //         _selectedIds.push(selectingIds[i]);
            //       }
            //     }
            //   }

            //   setSelectedIds(totalIds);
            // });
            //setAnchorEl(null);
          }}
        >
          + Select All ({totalIds.length})
        </MenuItem>
        
        <MenuItem
          onClick={() => {
            setSelectedIds([]);
            setAnchorEl(null);
          }}
          disabled={selectedIds.length === 0}
        >
          - Clear
        </MenuItem>

        <Collapse
          in={selectedIds && selectedIds.length > 0 && actionMenuItems && actionMenuItems.length > 0}
          orientation="vertical"
        >
          <>
            <Divider />

            {actionMenuItems?.map((actionMenu, index) => {
              return (
                <MenuItem
                  color="primary"
                  key={index}
                  onClick={() => {
                    actionMenu.action(selectedIds);
                    setAnchorEl(null);
                  }}
                  disabled={selectedIds.length === 0}
                >
                  {actionMenu.label}
                </MenuItem>
              );
            })}
          </>
        </Collapse>
        {/* {actionMenuItems && actionMenuItems.length > 0 && <Divider />}

        {actionMenuItems?.map((actionMenu, index) => {
          return (
            <MenuItem
              color="primary"
              key={index}
              onClick={() => {
                actionMenu.action();
                setAnchorEl(null);
              }}
              disabled={selectedIds.length === 0}
            >
              {actionMenu.label}
            </MenuItem>
          );
        })} */}
      </Menu>
    </Box>
  );
};
