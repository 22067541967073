import { CircularProgress } from '@mui/material';
import { Box, Stack } from 'components';
import { getSpacing } from 'theme';

type SelfServicePageRecommendationLogsProps = {
  logsFetching: boolean;
  recommendationLogs: string[] | undefined;
};

export const SelfServicePageRecommendationLogs = ({
  logsFetching,
  recommendationLogs,
}: SelfServicePageRecommendationLogsProps) => {
  return (
    <Box py={getSpacing('md')} key="recommendationLogs">
      {logsFetching && <CircularProgress />}
      {!logsFetching && (
        <Stack direction="column" spacing="sm" key="log-stack">
          {recommendationLogs?.map((x, y) => {
            return <Box key={y + '-log'}>{x}</Box>;
          })}
        </Stack>
      )}
    </Box>
  );
};
