import { ensomataApi } from 'api';
import { Page } from 'components';
import RuleBuilder from 'components/RuleBuilder';
import VersionTable from 'components/RuleBuilder/Versions';
import { defaultRule } from 'constants/DefaultRule';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CDSSRuleListDto } from 'types';

export const editRulePageRoute = {
  name: 'Edit Rule',
  template: ':ruleId',
  getPath: (ruleId: string) => {
    return `${ruleId}`;
  },
};

export const EditRulePage = () => {
  const params = useParams();
  const ruleId = params.ruleId;
  const [rule, setRule] = useState<CDSSRuleListDto | undefined>(undefined);
  const { data: rulesResponse } = ensomataApi.useGetRuleQuery(ruleId!);

  if (!rule && !!rulesResponse?.rule) {
    setRule(rulesResponse.rule);
  }

  return (
    <Page header="Rule">
      <RuleBuilder updateRule={setRule} rule={rule ?? defaultRule} />
      <VersionTable versions={rulesResponse?.versions ?? []} />
    </Page>
  );
};
