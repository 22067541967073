import { Page, PageMode, Stack } from 'components';
import { useState } from 'react';
import { Developer } from 'types';
import { PageRoute } from 'models';
import { buildPath, buildTemplatePath } from 'services';
import { DeveloperPageHeader } from '../DeveloperPage/DeveloperPageHeader';
import { DeveloperBasicInfo } from '../DeveloperPage/DeveloperBasicInfo';
import { DeveloperDemographics } from '../DeveloperPage/DeveloperDemographics';
import { DeveloperTasks } from '../DeveloperPage/DeveloperTasks';

const routeName = 'newDeveloper';
export const newDeveloperPageRoute: PageRoute = {
  handle: 'Developer',
  templatePath: buildTemplatePath(routeName),
  getPath: (params) => buildPath(routeName),
};

export const NewDeveloperPage = () => {
  const [developer, setDeveloper] = useState<Developer>({} as Developer);
  const pageMode: PageMode = 'Edit';

  return (
    <Page header={<DeveloperPageHeader pageMode={'Edit'} />} backButton>
      <Stack spacing="lg">
        <DeveloperBasicInfo />
        <DeveloperDemographics
          pageMode={pageMode}
          developer={developer}
          setDeveloper={setDeveloper}
        />
        <DeveloperTasks
          pageMode={pageMode}
          developer={developer}
          setDeveloper={setDeveloper}
        />
      </Stack>
    </Page>
  );
};
