import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { USE_LOCAL_MOCK } from '../constants';

type UseAuthReturnValue = {
  handleLogout(): void;
  handleLogin(): void;
  isAuthenticated?: boolean;
  user: User;
  userId: User['userId'];
  authStatus: string;
  isLoading: boolean;
};

interface User {
  email?: string;
  username?: string;
  isAuthenticated?: boolean;
}

// // https://ui.docs.amplify.aws/components/authenticator#access-auth-state
// type CognitoAuthState =
//   | "idle"
//   | "setup"
//   | "signIn"
//   | "signUp"
//   | "confirmSignIn"
//   | "confirmSignUp"
//   | "setupTOTP"
//   | "forceNewPassword"
//   | "resetPassword"
//   | "confirmResetPassword"
//   | "verifyUser"
//   | "confirmVerifyUser"
//   | "signOut"
//   | "authenticated";

interface User {
  userId?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
}

// interface CognitoUser {
//   signInUserSession
// }

function transformCognitoUserToUser(user: any): User {
  const payload = user?.getSignInUserSession()?.getIdToken()?.payload;

  return {
    userId: user?.getUsername(),
    firstName: payload?.given_name,
    lastName: payload?.family_name,
  };
}

export function useAuth(): UseAuthReturnValue {
  // const navigate = useNavigate();
  const {
    route, // CognitoAuthState
    authStatus,
    user: cognitoUser,
    isPending,
    // toFederatedSignIn,
    signOut,
  } = useAuthenticator((context) => [context.route]);
  const isAuthenticated = authStatus === 'authenticated' || USE_LOCAL_MOCK;
  // While signing out isPending works.
  const isLoading = isPending || (route === 'idle' && authStatus === 'configuring');

  const handleLogout = signOut;
  const handleLogin = () => Auth.federatedSignIn();
  const user = transformCognitoUserToUser(cognitoUser);

  return {
    isLoading,
    handleLogin,
    handleLogout,
    isAuthenticated,
    authStatus,
    user,
    userId: user.userId,
  };
}
