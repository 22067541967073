import { CDSSRuleListDto } from 'types';

export const defaultRule: CDSSRuleListDto = {
  id: '',
  name: '',
  enDesc: '',
  esDesc: '',
  order: 0,
  rules: undefined,
  version: 0,
  displayName: '',
  active: true,
  preventative: true,
  datePosted: new Date(),
  types: ['ALERT'],
  recommendations: [],
};
