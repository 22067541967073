import { createContext } from 'react';

export type SavableContextValue = {
  onSave?: () => void;
  remountOutlet?: () => void;
};

export const SavableContext = createContext<SavableContextValue>(
  {} as SavableContextValue
);
