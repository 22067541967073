import { Checkbox, TextField } from 'components/mui';
import { CDSSRuleListDto, Recommendation } from 'types';
import { Field } from '../Constants/Field';
import TextArea from './TextArea';
import SelectField from './Select';
import NumberField from './NumberInput';

type F = Field<any>;

interface Props {
  fields: F[];
  handleUpdate: (fields: F, value: string | Date | number | boolean | string[]) => void;
  rule: Recommendation | CDSSRuleListDto;
  autoFocus?: boolean;
}

const transformers = {
  object: (s: string[]) => s,
  boolean: (s: string) => s === 'true',
  number: Number,
  date: (s: string) => new Date(s),
};

export default function FieldsComponents({
  fields,
  handleUpdate,
  rule,
  autoFocus,
}: Props) {
  const handleNumberFieldChange = (value: string | string[], field: F) => {
    // convert the input to the appropriate type per field
    const transform = transformers['number'] || Number;
    handleUpdate(field, transform(value));
  };

  const handleTextFieldChange = (value: string | string[], field: F) => {
    // convert the input to the appropriate type per field
    const transform = transformers[typeof value] || String;
    handleUpdate(field, transform(value));
  };

  const handleCheckboxFieldChange = (value: boolean, field: F) => {
    // convert the input to the appropriate type per field
    handleUpdate(field, !value);
  };

  return (
    <div style={{ display: 'grid', gap: 20, gridTemplateColumns: 'repeat(3, 1fr)' }}>
      {/* eslint-disable-next-line */}
      {fields.map((f, i) => {
        if (f.type === 'number') {
          return (
            <NumberField
              autoFocus={autoFocus && !i}
              value={rule[f.accessor]}
              label={f.label}
              changeHandler={(val) => handleNumberFieldChange(`${val}`, f)}
              key={i}
            />
          );
        }
        if (['date', 'text', 'number'].includes(f.type)) {
          return (
            <TextField
              autoFocus={autoFocus && !i}
              onChange={(e) => handleTextFieldChange(e.target.value, f)}
              type={f.type}
              key={i}
              value={rule[f.accessor]}
              label={f.label}
            />
          );
        }

        if (f.type === 'text-area') {
          return (
            <TextArea
              key={i}
              {...{ handleTextFieldChange, field: f, rule, autoFocus: !!autoFocus && !i }}
            />
          );
        }

        if (f.type === 'boolean') {
          return (
            <Checkbox
              label={f.label}
              autoFocus={autoFocus && !i}
              key={i}
              onClick={(e) => handleCheckboxFieldChange(rule[f.accessor], f)}
              checked={rule[f.accessor]}
            />
          );
        }

        if (f.type === 'select') {
          return (
            <SelectField
              key={i}
              {...{ handleTextFieldChange, field: f, rule, autoFocus: !!autoFocus && !i }}
            />
          );
        }
      })}
    </div>
  );
}
