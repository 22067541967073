import { ReactNode } from 'react';

type FormProps = {
  children: ReactNode;
  className?: string;
  style?: object;
};

export const Form = (props: FormProps) => {
  return (
    <form style={props.style} className={props.className}>
      {props.children}
    </form>
  );
};
