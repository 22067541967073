import { ensomataApi } from 'api';
import { Autocomplete, Button, DatePicker, Form, Stack, TextField } from 'components';
import produce from 'immer';
import { Location, OrderActivityFilter, Staff } from 'types';

type OrderActivityListPageFiltersProps = {
  filter: OrderActivityFilter;
  setFilter: React.Dispatch<React.SetStateAction<OrderActivityFilter>>;
  appliedFilter: OrderActivityFilter;
  setAppliedFilter: React.Dispatch<React.SetStateAction<OrderActivityFilter>>;
};

export const OrderActivityListPageFilters = ({
  filter,
  setFilter,
  appliedFilter,
  setAppliedFilter,
}: OrderActivityListPageFiltersProps) => {
  const { data: locationsResponse } = ensomataApi.useGetLocationsQuery({});
  const locations: Location[] = locationsResponse?.locations ?? [];

  const { data: staffResponse } = ensomataApi.useGetStaffQuery({});
  const staff: Staff[] = staffResponse?.staff ?? [];

  return (
    <Form style={{ width: '100%' }}>
      <Stack
        direction="row"
        //justifyContent="space-between"
        alignItems="center"
        spacing="sm"
      >
        <Autocomplete
          fullWidth
          value={staff?.find((x) => x.staffId === filter.staffId)}
          options={staff!}
          getOptionLabel={(option) => option.staffName}
          onChange={(e, value) =>
            setFilter(
              produce((draft) => {
                draft.staffId = value ? value.staffId : undefined;
              })
            )
          }
          renderInput={(params) => <TextField {...params} label="Staff" size="small" />}
        />

        <Autocomplete
          fullWidth
          value={locations?.find((x) => x.locationID === filter.primaryServiceLocationId)}
          options={locations}
          getOptionLabel={(option) => option.locationName}
          onChange={(e, value) =>
            setFilter(
              produce((draft) => {
                draft.primaryServiceLocationId = value ? value.locationID : undefined;
              })
            )
          }
          renderInput={(params) => (
            <TextField {...params} label="Primary Service Location" size="small" />
          )}
        />

        <DatePicker
          value={filter.dateFrom}
          onChange={(value) =>
            setFilter(
              produce((draft) => {
                draft.dateFrom = value;
              })
            )
          }
          label="Date: From"
          size="small"
        />

        <DatePicker
          value={filter.dateTo}
          onChange={(value) =>
            setFilter(
              produce((draft) => {
                draft.dateTo = value;
              })
            )
          }
          label="Date: To"
          size="small"
        />

        <Button
          variant="contained"
          onClick={(e) => {
            e.preventDefault();
            setAppliedFilter(filter);
          }}
          type="submit"
          disabled={JSON.stringify(filter) === JSON.stringify(appliedFilter)}
        >
          Apply
        </Button>

        {/* <Button variant="contained" onClick={downloadExcel} sx={{ flexShrink: 0 }}>
        <Stack direction="row" alignItems="center" spacing="sm">
          <Box>.xls</Box>
          <DownloadIcon sx={{ fontSize: "1rem" }} />
        </Stack>
      </Button> */}
      </Stack>
    </Form>
  );
};
