import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { LookupType } from '@ensomata/common/enums';

const lookupTables = Object.values(LookupType);

export default function MultiselectMenu({
  lookupTablesSelected,
  setLookupTablesSelected,
}) {
  const handleChange = (event: SelectChangeEvent<typeof lookupTablesSelected>) => {
    const {
      target: { value },
    } = event;

    if (value.includes('Select All')) {
      setLookupTablesSelected(lookupTables);
    } else if (value.includes('Deselect All')) {
      setLookupTablesSelected([]);
    } else {
      setLookupTablesSelected(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value
      );
    }
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: '70%' }}>
        <InputLabel id="demo-multiple-chip-label">Lookup Tables</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={lookupTablesSelected}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {[
            <MenuItem
              key={'select-all'}
              value={
                lookupTablesSelected.length === lookupTables.length
                  ? 'Deselect All'
                  : 'Select All'
              }
            >
              {lookupTablesSelected.length === lookupTables.length
                ? 'Deselect All'
                : 'Select All'}
            </MenuItem>,
            ...lookupTables.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            )),
          ]}
        </Select>
      </FormControl>
    </div>
  );
}
