import { Box, Button, Page } from 'components';
import { LocalStorageRedirectTo } from 'constants/localStorageConstants';
import { LOCAL_STORAGE_REDIRECT_TO } from 'constants/localStorageConstants';
import { useAuth, useParams } from 'hooks';
import { PageRoute } from 'models';
import { buildOptionalParams, buildPath, buildTemplatePath } from 'services';

const routeName = 'login';

class OptionalParams {
  redirectTo?: string = '';
}

export const loginPageRoute: PageRoute<undefined, OptionalParams> = {
  handle: 'Login',
  templatePath: buildTemplatePath(routeName),
  getPath: () => buildPath(routeName),
  getOptionalParams: (params: OptionalParams) => buildOptionalParams(params),
};

export const LoginPage = () => {
  const { optionalParams } = useParams(routeName, undefined, OptionalParams);
  const redirectTo = optionalParams?.redirectTo;

  const { handleLogin } = useAuth();

  const handleLoginClick = () => {
    // Save the redirectTo URL in local storage so we can redirect to it after login
    // This is a hack: refactor later to use INCOGNITO for redirect if possible
    if (redirectTo) {
      const expirationTime = new Date().getTime() + 1 * 60000; // 1 minute in the future
      const item: LocalStorageRedirectTo = {
        value: redirectTo,
        expiration: expirationTime,
      };
      localStorage.setItem(LOCAL_STORAGE_REDIRECT_TO, JSON.stringify(item));
    }

    handleLogin();
  };

  return (
    <Page>
      <Box display="flex" height="100vh" alignItems="center" justifyContent="center">
        <Box>
          <Button variant="contained" onClick={handleLoginClick}>
            Login with SSO
          </Button>
        </Box>
      </Box>
    </Page>
  );
};
