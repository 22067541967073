import { AlertColor } from '@mui/material';
import { Toast } from 'types';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import { CHART_COLORS } from 'constants/commonConstants';
const cloneDeep = require('lodash');

//export function propertyName<TObject>(obj: TObject, key: keyof TObject): string;
export function pn<TObject>(key: keyof TObject): string {
  return key.toString();
}

export function stringLiteral<TObject>(value: TObject): string {
  return value as unknown as string;
}

export const formatBoolean = (bool: boolean | undefined): string | undefined => {
  if (bool !== undefined) {
    if (bool === true) return 'Yes';
    if (bool === false) return 'No';
  }

  return undefined;
};

type DateFormatString = 'MM/DD/YYYY' | 'MM/DD/YYYY hh:mm A' | 'ddd, MM/DD/YYYY';
export const formatDate = (
  value: Date | undefined,
  format: DateFormatString = 'MM/DD/YYYY hh:mm A',
  hasTimezone: boolean = false
): string | undefined => {
  if (value) {
    let momentDate: Moment = moment(value);
    const timezone = moment.tz.guess();
    momentDate = momentDate.tz(timezone);

    let dateString = momentDate.format(format);
    if (hasTimezone) {
      dateString = dateString + ' ' + momentDate.zoneAbbr();
    }
    return dateString;
  }

  return undefined;
};

export function deepCopy<T>(obj: T): T {
  return cloneDeep(obj) as T;
}

export const newToast = (alertColor: AlertColor, message?: string): Toast => {
  let toast: Toast = {} as Toast;

  toast.alertColor = alertColor;

  if (message) toast.message = message;
  else {
    if (toast.alertColor === 'success') toast.message = 'Action Successful';
    if (toast.alertColor === 'error') toast.message = 'An error has occured';
  }

  toast.timeSent = new Date().getTime();

  return toast;
};

export function getChartColor(index: number, opacity: number = 1): string {
  // Ensure the index is within the bounds of the array
  const color = CHART_COLORS[index % CHART_COLORS.length];

  // Convert the opacity to a valid format (0.0 to 1.0)
  const clampedOpacity = Math.min(Math.max(opacity, 0), 1);

  // Convert the hex color to rgba format
  function hexToRgba(hex: string, opacity: number): string {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  const rgbaColor = hexToRgba(color, clampedOpacity);

  return rgbaColor;
}
