import { theme } from 'theme';

var IS_LOCAL_MOCK_ON = false; // Toggle this to show local mock in app (no auth + fixture api responses)
// IS_LOCAL_MOCK_ON = true;
export const IS_LOCAL = process.env.NODE_ENV === 'development';
export const USE_LOCAL_MOCK = IS_LOCAL_MOCK_ON && IS_LOCAL;

export const isLocalhostWeb = Boolean(
  window?.location?.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window?.location?.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window?.location?.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

type APP_MODE = 'local_web' | 'local_pwa' | 'prod' | 'dev';

export function getAppMode(): APP_MODE {
  if (window?.location?.hostname?.includes('192.168')) {
    return 'local_pwa';
  }

  return process.env.REACT_APP_MODE as APP_MODE;
}

export function getAppVersion() {
  return process.env.REACT_APP_VERSION;
}

export function getRedirectLink() {
  if (getAppMode() === 'local_web') {
    return 'http://localhost:3000';
  }

  if (getAppMode() === 'local_pwa') {
    return process.env.REACT_APP_LOCAL_IP;
  }

  if (getAppMode() === 'dev') {
    return 'https://admin.dev.ensomata.com';
  }

  // prod
  return 'https://admin.ensomata.com';
}

export function getApiUrl() {
  if (getAppMode() === 'local_web') {
    return 'http://localhost:4001';
  }

  if (getAppMode() === 'dev') {
    return 'https://api.dev.ensomata.io/graphql';
  }

  // prod
  return 'https://api.prod.ensomata.io/graphql';
}

export const IS_PROD = getAppMode() === 'prod';

export const PAGE_SIZE: number = 20;

export const CHART_COLORS = [
  theme.palette.primary.main,
  theme.palette.secondary.main,
  '#7e90cf', // Blue
  '#9467BD', // Purple
  '#8C564B', // Brown
  '#E377C2', // Pink
  '#7F7F7F', // Gray
  '#BCBD22', // Yellow-Green
  '#17BECF', // Cyan
  '#AEC7E8', // Light Blue
  '#FFBB78', // Light Orange
  '#98DF8A', // Light Green
  '#FF9896', // Light Red
  '#C5B0D5', // Light Purple
  '#C49C94', // Light Brown
  '#F7B6D2', // Light Pink
  '#C7C7C7', // Light Gray
  '#DBDB8D', // Light Yellow-Green
  '#9EDAE5', // Light Cyan
  '#393B79', // Dark Blue
  '#8C6D31', // Dark Orange
  '#637939', // Dark Green
  '#843C39', // Dark Red
  '#7B4173', // Dark Purple
  '#5254A3', // Medium Blue
  '#E7969C', // Medium Red
  '#DE9ED6', // Medium Pink
  '#D6616B', // Medium Red-Pink
  '#9C9EDE', // Light Blue-Purple
  '#17A554', // Medium Green
  '#9C7561', // Medium Brown
  '#FF8C00', // Darker Orange
  '#B8860B', // Darker Goldenrod
  '#556B2F', // Dark Olive Green
  '#8B0000', // Dark Red (Crimson)
  '#E9967A', // Darker Salmon
  '#8FBC8F', // Dark Sea Green
  '#483D8B', // Dark Slate Blue
  '#2F4F4F', // Dark Slate Gray
  '#00CED1', // Darker Turquoise
  '#4682B4', // Steel Blue
  '#D2B48C', // Tan
  '#A0522D', // Sienna
  '#D2691E', // Darker Chocolate
  '#CD5C5C', // Indian Red
  '#808000', // Olive
  '#6B8E23', // Olive Drab
  '#DAA520', // Goldenrod
  '#B22222', // Firebrick
];
