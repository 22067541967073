import { Autocomplete, TextField } from 'components';
import moment from 'moment';
import { useState } from 'react';

interface OptionType {
  value: Date;
  label: string;
}

export const DateFilter = (props: { date: Date; setDate: (value: Date) => void }) => {
  const [options] = useState<OptionType[]>(generateDateOptions(52));

  function generateDateOptions(weeks: number) {
    return Array.from({ length: weeks }, (_, index) => {
      const momentDate = moment().startOf('isoWeek').subtract(index, 'weeks');

      return {
        value: momentDate.toDate(),
        label: momentDate.format('MM/DD/YYYY'),
      } as OptionType;
    });
  }

  const selectedOption = options.find(
    (option) => option.value.getTime() === props.date.getTime()
  );

  return (
    <Autocomplete
      sx={{ width: 300 }}
      disableClearable
      value={selectedOption}
      options={options}
      onChange={(e, value) => props.setDate(value.value)}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField {...params} label="Select a Week" size="small" />
      )}
    />
  );
};
