import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export const Paper = (theme: Theme) => {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          boxShadow:
            'rgb(159 162 191 / 20%) 0px 5px 16px, rgb(159 162 191 / 50%) 0px 0px 2px',
          //boxShadow: "rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem"
        },
      },
    },
  };
};
