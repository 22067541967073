import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from 'components';
import { useNavigate } from 'react-router-dom';
import { formatDate } from 'services';
import { Developer } from 'types';
import { developerPageRoute } from '../DeveloperPage/DeveloperPage';

type DeveloperListPageTableProps = {
  developers: Developer[] | undefined;
};

export const DeveloperTable = ({ developers }: DeveloperListPageTableProps) => {
  const navigate = useNavigate();

  return (
    <TableContainer sx={{ height: '100%' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Last Updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {developers?.map((developer) => {
            return (
              <TableRow
                onClick={() =>
                  navigate(
                    developerPageRoute.getPath({ id: developer.uuid, pageMode: 'View' })
                  )
                }
              >
                <TableCell>{developer.uuid}</TableCell>
                <TableCell>{developer.firstName}</TableCell>
                <TableCell>{developer.genderName}</TableCell>
                <TableCell>
                  {formatDate(developer.lastUpdatedDate)} by {developer.lastUpdatedBy}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
