import { Add, Remove } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { Box, Card, IconButton, Stack } from 'components/mui';
import { useMemo } from 'react';
import { Recommendation, RecommendationType } from 'types';
import {
  HandleFieldChangeParams,
  HandleOrderableItemOptionChangeParams,
  newRecommendation,
} from '.';
import FieldsComponents from '../Components/FieldsComponents';
import { Field } from '../Constants/Field';
import OrderableItemOptionRow from '../OrderableItemOption/OrderableItemOptionRow';

const fields: Field<Recommendation>[] = [
  { label: 'Orderable Item Name', accessor: 'orderableItemName', type: 'text' },
  { label: 'Display Order', accessor: 'displayOrder', type: 'number' },
  {
    label: 'Recommendation Type',
    accessor: 'recommendationType',
    type: 'select',
    options: Object.values(RecommendationType),
  },
  { label: 'Orderable Item ID', accessor: 'orderableItemId', type: 'number' },
  { label: 'Orderable Item Type ID', accessor: 'orderableItemTypeId', type: 'number' },
  { label: 'Default Icd Code', accessor: 'defaultIcdCode', type: 'text' },
  {
    label: 'Default Assessment Item Id',
    accessor: 'defaultAssessmentItemId',
    type: 'number',
  },
  { label: 'Future Order', accessor: 'futureOrder', type: 'boolean' },
  { label: 'Required', accessor: 'required', type: 'boolean' },
  { label: 'Visible to RadTech', accessor: 'radTech', type: 'boolean' },
  { label: 'Is Orderable', accessor: 'isOrderable', type: 'boolean' },
  { label: 'Result Attribute', accessor: 'resultAttribute', type: 'text' },
  { label: 'Result Attribute ID', accessor: 'resultAttributeId', type: 'number' },
  {
    label: 'Default Diagnosis Name',
    accessor: 'defaultDiagnosisName',
    type: 'text-area',
  },
  { label: 'Criteria', accessor: 'criteria', type: 'text-area' },
  { label: 'Description', accessor: 'description', type: 'text-area' },
];

interface Props {
  rec: Recommendation;
  i: number;
  removeRec: (rec: Recommendation) => void;
  addOrderableItemOption: (rec: Recommendation) => void;
  removeOrderableItemOption: (recIndex: number, orderableItemOptionIndex: number) => void;
  handleFieldChange: (_: HandleFieldChangeParams) => void;
  handleOrderableItemOptionUpdate: (
    update: HandleOrderableItemOptionChangeParams
  ) => void;
}

const RecommendationCard = ({
  rec,
  i,
  removeRec,
  addOrderableItemOption,
  removeOrderableItemOption,
  handleFieldChange,
  handleOrderableItemOptionUpdate,
}: Props) => {
  // a little hack to check if the recommendation is new
  // triggered by the presence of the recommendation so that it only runs on the first render
  const isNew = useMemo(() => {
    return JSON.stringify(rec) === JSON.stringify(newRecommendation);
    // eslint-disable-next-line
  }, [!!rec]);

  const label = isNew ? 'New Recommendation' : `Recommendation`;

  return (
    <Card key={i}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '24px',
        }}
      >
        <Box>{label}</Box>
        <IconButton
          onClick={() => removeRec(rec)}
          sx={{ border: `1px solid ${grey[300]}` }}
        >
          <Remove />
        </IconButton>
      </Box>
      <FieldsComponents
        fields={fields}
        autoFocus={isNew}
        rule={rec}
        handleUpdate={(field, value) => {
          handleFieldChange({
            recommendation: rec,
            recommendationIndex: i,
            field,
            value,
          });
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '24px',
          marginBottom: '24px',
        }}
      >
        Orderable Item Options ({rec.orderableItemOptions?.length ?? 0})
        <IconButton
          onClick={() => addOrderableItemOption(rec)}
          sx={{ border: `1px solid ${grey[300]}` }}
        >
          <Add />
        </IconButton>
      </Box>
      <Box>
        <Stack direction={'column'}>
          {rec.orderableItemOptions?.map((option, j) => (
            <OrderableItemOptionRow
              {...{
                option,
                i,
                j,
                removeOrderableItemOption,
                handleOrderableItemOptionUpdate,
              }}
              key={j}
            />
          ))}
        </Stack>
      </Box>
    </Card>
  );
};

export default RecommendationCard;
