import { MenuItem, Select } from 'components/mui';
import { ArrowRight, Clear } from '@mui/icons-material';
import { Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import { operandTypes } from './constants/ListOperandTypes';
import { OperandProps } from './constants/OperandProps';

import { Operand } from 'types';
import AnyOrAll from './AnyOrAll';
import Age from './Age';
import Gender from './Gender';
import Race from './Race';
import Diagnosis from './Diagnosis';
import NoPreviousTest from './NoPreviousTest';
import NoPreviousDoS from './NoPreviousDoS';
import NoTestWithin from './NoTestWithin';
import NoDoSWithin from './NoDoSWithin';
import PackYears from './PackYears';
import SmokingStatus from './SmokingStatus';
import SmokingCessationYears from './SmokingCessationYears';
import TestResultValue from './TestResultValue';
import NoTestResultDateWithin from './NoTestResultDateWithin';
import NoEncounterWithin from './NoEncounterWithin';
import BloodPressure from './BloodPressure';
import FluSeason from './FluSeason';
import VaccineAdministered from './VaccineAdministered';
import AgeOfFirstVaccine from './AgeOfFirstVaccine';
import PrimaryInsuranceName from './PrimaryInsuranceName';

export function IndentationIndicators({ indentation = 0 }: { indentation: number }) {
  return (
    <div style={{ display: 'flex' }}>
      {Array.from(Array(indentation)).map((_, i) => (
        <ArrowRight style={{}} sx={{ color: grey[300] }} key={i} />
      ))}
    </div>
  );
}

export default function OperandRowContainer(props: OperandProps<any>) {
  const { operand, updateOperand, indentation = 0 } = props;
  // check for "value" so that ts doesn't complain about accessing "value" on an operand without value
  const isGrid = !['all', 'any'].includes(operand?.operand);

  const removeOperand = () => {
    if (!indentation) {
      // we're at the root operand, so just set it to an empty object {}
      updateOperand({} as Operand);
      return;
    }
    // update operand has the 'context' of where we're at in the object
    // so we handle removing the operand from the parent inside that callback
    updateOperand(operand, true);
  };

  return (
    <div>
      <div
        style={{ display: 'flex', gap: 10, alignItems: 'center', marginBottom: `12px` }}
      >
        <IndentationIndicators indentation={indentation} />
        <div
          style={{
            width: '100%',
            position: 'relative',
            gap: 10,
            gridTemplateColumns: 'repeat(3, 1fr)',
            display: isGrid ? 'grid' : 'block',
          }}
        >
          <Select
            value={operand?.operand}
            onChange={(e) => {
              const newOperand = operandTypes.find(
                (t) => t.value.operand === e.target.value
              )?.value;
              if (!newOperand) return;
              updateOperand(newOperand);
            }}
          >
            {operandTypes.map((t) => (
              <MenuItem key={t.label} value={t.value.operand}>
                {t.label}
              </MenuItem>
            ))}
          </Select>
          <Gender {...props} />
          <Race {...props} />
          <Age {...props} />
          <Diagnosis {...props} />
          <NoPreviousTest {...props} />
          <NoPreviousDoS {...props} />
          <NoTestWithin {...props} />
          <NoDoSWithin {...props} />
          <NoEncounterWithin {...props} />
          <NoTestResultDateWithin {...props} />
          <PackYears {...props} />
          <SmokingStatus {...props} />
          <SmokingCessationYears {...props} />
          <TestResultValue {...props} />
          <BloodPressure {...props} />
          <FluSeason {...props} />
          <VaccineAdministered {...props} />
          <AgeOfFirstVaccine {...props} />
          <PrimaryInsuranceName {...props} />
        </div>
        <Button
          onClick={removeOperand}
          variant="outlined"
          sx={{
            width: '56px !important',
            minWidth: '56px !important',
            height: '56px !important',
            borderColor: grey[400],
            color: grey[400],
          }}
        >
          <Clear />
        </Button>
      </div>
      <AnyOrAll {...{ ...props }} />
    </div>
  );
}
