import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from "@mui/material";

type TextFieldProps = MuiTextFieldProps & {
  errors?: any[];
};

export function TextField({ label, value, errors, multiline, minRows, ...rest }: TextFieldProps) {
  //   const errorMsg = errors && errors?.length > 0 ? errors[0] : null;
  //   const hasError = errorMsg ? true : false;

  return (
    <MuiTextField
      fullWidth
      label={label}
      value={value !== null && value !== undefined ? value : ""}
      //error={hasError}
      //helperText={errorMsg}
      variant={"outlined"}
      //TextFieldLabelProps={{ shrink: false }}
      InputProps={{ readOnly: false }} // could use this someday
      multiline={multiline}
      minRows={minRows ? minRows : 2} // only applies if multiline === true
      maxRows={Infinity} // only applies if multiline === true
      {...rest}
    />
  );
}
