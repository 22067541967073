import { LookupSyncDto } from '@ensomata/common/dto';
import { LookupType } from '@ensomata/common/enums';
import { ensomataApi } from 'api';
import { toast } from 'react-hot-toast';

export default function useLookupSync() {
  const [sync, syncStatus] = ensomataApi.useSyncLookupsMutation();

  const triggerSync = async (lookupTable: LookupType) => {
    const req: LookupSyncDto = {
      lookupTypes: [lookupTable],
    };

    try {
      const res = await sync(req);
      if ('error' in res) throw Error(res.error.message);
      toast.success(`${lookupTable} Sync Successful`);
    } catch (e) {
      toast.error(`Sync error ${lookupTable}`);
      console.error(e);
    }
  };

  const upsert = (lookupTables: LookupType[]) => {
    lookupTables.forEach((lookupTable) => {
      triggerSync(lookupTable);
    });
  };

  return {
    upsert,
    isLoading: syncStatus.isLoading,
  };
}
