import { ensomataApi } from 'api';
import { Card, Grid, MenuItem, Select, Stack } from 'components';

import { useState } from 'react';
import { OrderMetric } from 'types';
import { HomePageDashboardLineChart } from './HomePageDashboardLineChart';

const timePeriodOptions = ['Current Hour', 'Today', 'Week', 'Month'] as const;
export type TimePeriodFilter = typeof timePeriodOptions[number];

export const HomePageDashboard = () => {
  const [timePeriod, setTimePeriod] = useState<TimePeriodFilter>('Current Hour');

  const getFilterStartDate = (timePeriod: TimePeriodFilter): Date => {
    if (timePeriod === 'Current Hour') {
      let date = new Date();
      date.setHours(date.getHours(), 0, 0, 0);
      return date;
    } else if (timePeriod === 'Today') {
      return new Date(new Date().setHours(0, 0, 0, 0));
    } else if (timePeriod === 'Week') {
      let date = new Date();
      date.setDate(date.getDate() - 7);
      date.setHours(0, 0, 0, 0);
      return date;
    } else if (timePeriod === 'Month') {
      let date = new Date();
      date.setDate(date.getDate() - 30);
      date.setHours(0, 0, 0, 0);
      return date;
    }

    return new Date('01/01/2000');
  };

  const filterStartDate = getFilterStartDate(timePeriod);
  const { data: orderMetricsResponse } = ensomataApi.useGetOrderMetricsQuery({
    starts: filterStartDate.toISOString(),
  });

  const orderMetrics: OrderMetric[] = orderMetricsResponse?.orderMetrics ?? [];

  return (
    <Card>
      {/* {orderMetrics?.map((x) => {
        return <div>{x.orderName}: {x.orderDate.toString()}</div>;
      })} */}
      <Stack direction="column" spacing="lg">
        <Grid container>
          <Grid item xs={3}>
            <Stack direction={'column'} spacing="sm">
              <Select
                label="Time Period"
                value={timePeriod}
                onChange={(e) => {
                  setTimePeriod(e.target.value as unknown as TimePeriodFilter);
                }}
                size="small"
              >
                {timePeriodOptions.map((x) => {
                  return (
                    <MenuItem key={x} value={x}>
                      {x}
                    </MenuItem>
                  );
                })}
              </Select>

              {/* {timePeriod !== 'Today' && (
                <Typography variant="caption">
                  Starting: {filterStartDate?.toDateString()}
                </Typography>
              )} */}
            </Stack>
          </Grid>
        </Grid>

        <HomePageDashboardLineChart orderMetrics={orderMetrics} timePeriod={timePeriod} />
      </Stack>
    </Card>
  );
};
