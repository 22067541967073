import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Select } from 'components/mui';
import {
  OperandValues,
  BloodPressureOperand,
  CONDITIONAL_OPERATORS,
  ConditionalOperatorType,
} from 'types';
import { OperandProps } from './constants/OperandProps';
import NumberField from '../Components/NumberInput';

export default function BloodPressure({
  operand,
  updateOperand,
}: OperandProps<BloodPressureOperand>) {
  // check for "value" so that ts doesn't complain about accessing "value" on an operand without value
  const isTestResult = operand?.operand === OperandValues.bloodPressure;
  if (!isTestResult) return <></>;

  const handleOperatorChange = (e: SelectChangeEvent<any>, index: number) => {
    const newOperand = { ...operand };
    newOperand.values[index].operator = e.target.value as ConditionalOperatorType;
    updateOperand(newOperand);
  };

  const handleValueChange = (value: number, index: number) => {
    const newOperand = { ...operand };
    newOperand.values[index].value = value;
    updateOperand(newOperand);
  };

  return (
    <>
      <div></div>
      <div></div>
      {operand.values.map((bpValue, index) => {
        return (
          <div
            key={index}
            style={{ display: 'flex', gridColumn: 'span 3', gap: 4, marginLeft: '10%' }}
          >
            <div style={{ width: '33%' }}>
              <Select label="Blood Pressure Type" value={bpValue.propertyName}>
                <MenuItem value={bpValue.propertyName}>{bpValue.label}</MenuItem>
              </Select>
            </div>

            <div style={{ width: '33%' }}>
              <Select
                onChange={(e) => handleOperatorChange(e, index)}
                value={bpValue.operator}
              >
                {CONDITIONAL_OPERATORS.map((o, i) => (
                  <MenuItem value={o} key={i}>
                    {o}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div style={{ width: '33%' }}>
              <NumberField
                label="Test Result Value"
                changeHandler={(value: number) => handleValueChange(value, index)}
                value={bpValue.value}
              />
            </div>
          </div>
        );
      })}
    </>
  );
}
