"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaritalStatusType = void 0;
var MaritalStatusType;
(function (MaritalStatusType) {
    MaritalStatusType["SINGLE"] = "SINGLE";
    MaritalStatusType["DIVORCED"] = "DIVORCED";
    MaritalStatusType["PARTNER"] = "PARTNER";
    MaritalStatusType["MARRIED"] = "MARRIED";
    MaritalStatusType["SEPARATED"] = "SEPARATED";
    MaritalStatusType["UNKNOWN"] = "UNKNOWN";
    MaritalStatusType["UNMARRIED"] = "UNMARRIED";
    MaritalStatusType["WIDOWED"] = "WIDOWED";
    MaritalStatusType["LEGALLY SEPARATED"] = "LEGALLY SEPARATED";
})(MaritalStatusType = exports.MaritalStatusType || (exports.MaritalStatusType = {}));
