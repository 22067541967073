import AssignmentIcon from '@mui/icons-material/Assignment';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import SyncIcon from '@mui/icons-material/Sync';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import HomeIcon from '@mui/icons-material/Home';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import SickIcon from '@mui/icons-material/Sick';
import { Box, List, ListItemButton, Stack, Typography } from 'components';
import { getAppMode } from 'constants/commonConstants';
import { developerListPageRoute } from 'pages/developers/DeveloperListPage/DeveloperListPage';
import { homePageRoute } from 'pages/home/HomePage/HomePage';
import { lookupSyncPageRoute } from 'pages/lookupSync/LookupSyncPage';
import { orderActivityListPageRoute } from 'pages/orderActivity/OrderActivityListPage/OrderActivityListPage';
import { ruleListPageRoute } from 'pages/rules/RuleListPage/RuleListPage';
import { selfServicePageRoute } from 'pages/selfService/SelfServicePage/SelfServicePage';

import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setAppNavDrawerOpen } from 'redux/appSlice';

type MenuItemType = {
  title: string;
  path: string;
  icon?: ReactNode;
  disabled?: boolean;
};

export const NavigationBarMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  let menuItems: MenuItemType[] = [
    { title: 'Home', path: homePageRoute.getPath(), icon: <HomeIcon /> },
    {
      title: 'Order Activity',
      path: orderActivityListPageRoute.getPath(),
      icon: <AssignmentIcon />,
    },
    {
      title: 'Self Service',
      path: selfServicePageRoute.getPath(),
      icon: <HeadsetMicIcon />,
    },
    {
      title: 'CDSS Rules',
      path: ruleListPageRoute.getPath(),
      icon: <DensityMediumIcon />,
    },
    {
      title: 'Lookup Sync',
      path: lookupSyncPageRoute.getPath(),
      icon: <SyncIcon />,
    },
    {
      title: 'System Roles',
      path: '/placeholder',
      icon: <PeopleAltIcon />,
      disabled: true,
    },
    {
      title: 'System Settings',
      path: '/placeholder',
      icon: <SettingsIcon />,
      disabled: true,
    },
    {
      title: 'Locations',
      path: '/placeholder',
      icon: <LocationOnIcon />,
      disabled: true,
    },
  ];

  if (getAppMode() === 'local_web')
    menuItems.push({
      title: 'Developers',
      path: developerListPageRoute.getPath(),
      icon: <SickIcon />,
    });

  return (
    <List>
      {menuItems.map((menuItem) => {
        return (
          <ListItemButton
            key={menuItem.title}
            sx={{ display: 'block' }}
            onClick={() => {
              navigate(menuItem.path);
              dispatch(setAppNavDrawerOpen(false));
            }}
            selected={pathname.includes(menuItem.path)}
            disabled={menuItem.disabled}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              px={1}
              minHeight="40px"
            >
              <Stack direction="row" spacing="sm" alignItems="center">
                <Box position="relative" top="1px">
                  {menuItem.icon}
                </Box>
                <Typography fontWeight="bold" fontSize="0.9rem">
                  {menuItem.title}
                </Typography>
              </Stack>
            </Stack>
          </ListItemButton>
        );
      })}
    </List>
  );
};
