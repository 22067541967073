import {
  AllOperand,
  AnyOperand,
  AgeOperand,
  GenderOperand,
  RaceOperand,
  DiagnosisOperand,
  NoPreviousTestOperand,
  NoPreviousDoSOperand,
  NoTestWithinOperand,
  NoDoSWithinOperand,
  NoEncounterWithinOperand,
  NoTestResultDateWithinOperand,
  PackYearsOperand,
  SmokingStatusOperand,
  SmokingCessationYearsOperand,
  TestResultValueOperand,
  BloodPressureOperand,
  FluSeasonOperand,
  VaccineAdministeredOperand,
  AgeOfFirstVaccineOperand,
  VaccineAdministeredPropertyName,
  AgeOfFirstVaccinePropertyName,
  SmokingStatusOption,
  DoSPropertyName,
  FluSeasonOption,
  OperandValues,
  TestResultValuePropertyName,
  TestResultDatePropertyName,
  BloodPressurePropertyNames,
  RecommendationType,
  PrimaryInsuranceNameOperand,
} from 'types';

// the "initial" or "default" values for creating a new instance of an operand

export const initialDiagnosis: DiagnosisOperand = {
  operand: OperandValues.diagnosis,
  operator: '=',
  diagnosisGroupSourceId: '',
};

export const initialAll: AllOperand = {
  operand: OperandValues.all,
  value: [],
  operator: 'all',
};

export const initialAny: AnyOperand = {
  operand: OperandValues.any,
  value: [],
  operator: 'any',
};

export const initialAge: AgeOperand = {
  operand: OperandValues.age,
  operator: '=',
  value: 0,
};

export const initialGender: GenderOperand = {
  operand: OperandValues.gender,
  operator: '=',
  value: 'M',
};

export const initialNoPreviousTest: NoPreviousTestOperand = {
  orderableLabItemId: '',
  testType: RecommendationType.IMAGING,
  operand: OperandValues.noPreviousTest,
};

export const initialNoTestWithin: NoTestWithinOperand = {
  operand: OperandValues.noTestWithin,
  orderableLabItemId: '',
  testType: RecommendationType.IMAGING,
  duration: {
    years: 1,
  },
};

export const initialNoPreviousDoS: NoPreviousDoSOperand = {
  operand: OperandValues.noPreviousDoS,
  DoSPropertyName: DoSPropertyName.aaaScreenDoS,
};

export const initialNoDoSWithin: NoDoSWithinOperand = {
  operand: OperandValues.noDoSWithin,
  DoSPropertyName: DoSPropertyName.aaaScreenDoS,
  duration: {
    years: 1,
  },
};

export const initialNoEncounterWithin: NoEncounterWithinOperand = {
  duration: { years: 1 },
  operand: OperandValues.noEncounterWithin,
};

export const initialNoTestResultDateWithin: NoTestResultDateWithinOperand = {
  test: Object.values(TestResultDatePropertyName)[0],
  duration: { years: 1 },
  operand: OperandValues.noTestResultDateWithin,
};

export const initialRace: RaceOperand = {
  operand: OperandValues.race,
  operator: '=',
  value: '',
};

export const initialPackYears: PackYearsOperand = {
  operator: '>=',
  value: 0,
  operand: OperandValues.packYears,
};

export const initialSmokingStatus: SmokingStatusOperand = {
  operator: '=',
  value: SmokingStatusOption.current,
  operand: OperandValues.smokingStatus,
};

export const initialSmokingCessationYears: SmokingCessationYearsOperand = {
  operator: '>=',
  value: 0,
  operand: OperandValues.smokingCessationYears,
};

export const initialTestResultValue: TestResultValueOperand = {
  test: Object.values(TestResultValuePropertyName)[0],
  operator: '>=',
  value: '1.0',
  operand: OperandValues.testResultValue,
};

export const initialBloodPressure: BloodPressureOperand = {
  values: [
    {
      value: 1.0,
      operator: '>=',
      propertyName: BloodPressurePropertyNames.bpSystolicValue,
      label: 'Systolic',
    },
    {
      value: 1.0,
      operator: '>=',
      propertyName: BloodPressurePropertyNames.bpDiastolicValue,
      label: 'Diastolic',
    },
    {
      value: 1.0,
      operator: '>=',
      propertyName: BloodPressurePropertyNames.bP2SystolicValue,
      label: 'Systolic 2',
    },
    {
      value: 1.0,
      operator: '>=',
      propertyName: BloodPressurePropertyNames.bP2DiastolicValue,
      label: 'Diastolic 2',
    },
  ],
  operand: OperandValues.bloodPressure,
};

export const initialFluSeason: FluSeasonOperand = {
  value: Object.values(FluSeasonOption)[0],
  operand: OperandValues.fluSeason,
};

export const initialVaccineAdministered: VaccineAdministeredOperand = {
  operator: '>=',
  vaccine: Object.values(VaccineAdministeredPropertyName)[0],
  value: 0,
  operand: OperandValues.vaccineAdministered,
};

export const initialAgeOfFirstVaccine: AgeOfFirstVaccineOperand = {
  operator: '>=',
  vaccine: Object.values(AgeOfFirstVaccinePropertyName)[0],
  value: 0,
  operand: OperandValues.ageOfFirstVaccine,
};

export const initialPrimaryInsuranceName: PrimaryInsuranceNameOperand = {
  caseSensitive: false,
  operator: 'contains',
  value: '',
  operand: OperandValues.primaryInsuranceName,
};
