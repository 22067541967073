import { useState } from 'react';

export const useRemountPageOutlet = (): {
  outletKey: string | undefined;
  remountOutlet: () => void;
} => {
  const [outletKey, setOutleyKey] = useState<string>();

  const remountOutlet = () => {
    setOutleyKey(new Date().toISOString());
  };

  return {
    outletKey: outletKey,
    remountOutlet: remountOutlet,
  };
};
