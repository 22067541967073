import { BaseLayout, NavLayout, PublicLayout } from 'layouts';
import { developerListPageRoute } from 'pages/developers/DeveloperListPage/DeveloperListPage';
import { developerPageRoute } from 'pages/developers/DeveloperPage/DeveloperPage';
import { newDeveloperPageRoute } from 'pages/developers/NewDeveloperPage/NewDeveloperPage';
import { homePageRoute } from 'pages/home/HomePage/HomePage';
import { loginPageRoute } from 'pages/login/LoginPage/LoginPage';
import { lookupSyncPageRoute } from 'pages/lookupSync/LookupSyncPage';
import { orderActivityListPageRoute } from 'pages/orderActivity/OrderActivityListPage/OrderActivityListPage';
import { practiceMetricsPageRoute } from 'pages/practice-metrics/PracticeMetricsPage/PracticeMetricsPage';
import { ruleListPageRoute } from 'pages/rules/RuleListPage/RuleListPage';
import { editRulePageRoute } from 'pages/rules/RulePage/EditRule';
import { newRulePageRoute } from 'pages/rules/RulePage/NewRule';
import { selfServicePageRoute } from 'pages/selfService/SelfServicePage/SelfServicePage';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { AuthCheck } from './AuthCheck';
import { lazyLoadingComponents } from './components';
import { practiceMetricsDetailPageRoute } from 'pages/practice-metrics/PracticeMetricsDetailPage/PracticeMetricsDetailPage';

// Component Pages.
// Avoiding importing all components / import component as we need
// By declaring each component as lazy promise so that it gets imported on the spot
// only when that component is being routed to
const {
  HomePage,
  LoginPage,
  DeveloperListPage,
  DeveloperPage,
  NewDeveloperPage,
  RuleListPage,
  NewRulePage,
  EditRulePage,
  LookupSyncPage,
  OrderActivityPage,
  SelfServicePage,
  PracticeMetricsPage,
  PracticeMetricsDetailPage,
} = lazyLoadingComponents();

// const HomePage = Loadable(lazy(() => import("pages/home/HomePage")));
// const LoginPage = Loadable(lazy(() => import("pages/login/LoginPage")));

// copies RouteObject from react-router-dom, with additional field
// export type RouteObject = {
//   children?: RouteObject[];
//   element?: React.ReactNode;
//   index?: boolean;
//   path?: string;
//   name?: string;
// };

// export type EnsomataRouteObject = RouteObject & {
//   name?: string;
//   children?: EnsomataRouteObject[];
// };

const publicRoutes: RouteObject[] = [
  {
    path: loginPageRoute.templatePath,
    handle: loginPageRoute.handle,
    element: <LoginPage />,
  },
];

const navRoutes: RouteObject[] = [
  { element: <Navigate to={homePageRoute.templatePath} replace />, index: true }, // default
  {
    path: homePageRoute.templatePath,
    handle: homePageRoute.handle,
    element: <HomePage />,
  },
  {
    path: developerListPageRoute.templatePath,
    handle: developerListPageRoute.handle,
    element: <DeveloperListPage />,
    children: [
      {
        path: newDeveloperPageRoute.templatePath,
        handle: newDeveloperPageRoute.handle,
        element: <NewDeveloperPage />,
      },
      {
        path: developerPageRoute.templatePath,
        handle: developerPageRoute.handle,
        element: <DeveloperPage />,
      },
    ],
  },
  {
    path: ruleListPageRoute.templatePath,
    handle: ruleListPageRoute.handle,
    element: <RuleListPage />,
    // refactor routes for rules to use existing pattern later
    children: [
      {
        path: newRulePageRoute.template,
        handle: newRulePageRoute.name,
        element: <NewRulePage />,
      },
      {
        path: editRulePageRoute.template,
        handle: editRulePageRoute.name,
        element: <EditRulePage />,
      },
    ],
  },
  {
    path: orderActivityListPageRoute.templatePath,
    handle: orderActivityListPageRoute.handle,
    element: <OrderActivityPage />,
  },
  {
    path: selfServicePageRoute.templatePath,
    handle: selfServicePageRoute.handle,
    element: <SelfServicePage />,
  },
  {
    path: lookupSyncPageRoute.templatePath,
    handle: lookupSyncPageRoute.handle,
    element: <LookupSyncPage />,
  },
  {
    path: practiceMetricsPageRoute.templatePath,
    handle: practiceMetricsPageRoute.handle,
    element: <PracticeMetricsPage />,
    children: [
      {
        path: practiceMetricsDetailPageRoute.templatePath,
        handle: practiceMetricsDetailPageRoute.handle,
        element: <PracticeMetricsDetailPage />,
      },
    ],
  },
];

export const appRoutes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: (
          <AuthCheck>
            <NavLayout />
          </AuthCheck>
        ),
        children: navRoutes,
      },
      // PublicLayout
      {
        path: '/',
        element: <PublicLayout />,
        children: publicRoutes,
      },
    ],
  },

  { path: '*', element: <Navigate to="/404" replace /> },
];

export function AppRoutes() {
  //const navigate = useNavigate();
  //const urlParams = new URLSearchParams(window.location.search);
  //let redirectTo = urlParams.get("redirectTo");

  return useRoutes(appRoutes);
}
