import { MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { Select } from 'components/mui';
import { DiagnosisOperand, OperandValues } from 'types';
import { OperandProps } from './constants/OperandProps';

const operators: DiagnosisOperand['operator'][] = ['=', '!'];

export default function Diagnosis({
  operand,
  updateOperand,
}: OperandProps<DiagnosisOperand>) {
  // check for "value" so that ts doesn't complain about accessing "value" on an operand without value
  const isDiagnosis = operand?.operand === OperandValues.diagnosis;
  if (!isDiagnosis) return <></>;

  const handleOperatorChange = (e: SelectChangeEvent<any>) => {
    updateOperand({ ...operand, operator: e.target.value as (typeof operators)[0] });
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateOperand({ ...operand, diagnosisGroupSourceId: e.target.value });
  };

  return (
    <>
      <Select onChange={handleOperatorChange} value={operand.operator ?? '='}>
        {operators.map((o, i) => (
          <MenuItem value={o} key={i}>
            {o}
          </MenuItem>
        ))}
      </Select>
      <TextField
        label="Diagnosis Group Source ID"
        onChange={handleValueChange}
        value={operand.diagnosisGroupSourceId}
      />
    </>
  );
}
