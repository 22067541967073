import { Breakpoint } from '@mui/material';

export const SPACING_XS: number = 0.5;
export const SPACING_SM: number = 1.5;
export const SPACING_MD: number = 2.5;
export const SPACING_LG: number = 4;
export const SPACING_XL: number = 6;

export const getSpacing = (spacing: Breakpoint | undefined): number | undefined => {
  switch (spacing) {
    case 'xs':
      return SPACING_XS;
    case 'sm':
      return SPACING_SM;
    case 'md':
      return SPACING_MD;
    case 'lg':
      return SPACING_LG;
    case 'xl':
      return SPACING_XL;
    default:
      return undefined;
  }
};
