import { Select } from 'components/mui';
import { Field } from '../Constants/Field';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { CDSSRuleListDto, Recommendation } from 'types';

interface Props {
  field: Field<any>;
  rule: CDSSRuleListDto | Recommendation;
  handleTextFieldChange: (s: string | string[], field: Field<any>) => void;
  autoFocus?: boolean;
}

export default function SelectField({
  field,
  rule,
  handleTextFieldChange,
  autoFocus,
}: Props) {
  const valStr = rule[field.accessor];
  let valArray: string[] = [];

  if (field.multi) {
    // can't be sure if the value is coming in as a string or an array
    if (typeof valStr === 'string') {
      valArray = valStr.split(',');
    }
    if (typeof valStr === 'object') {
      valArray = valStr;
    }
  }

  const val = field.multi ? valArray : valStr;

  function handleMultiSelectChange(e: SelectChangeEvent<string[]>) {
    const value = e.target.value as string[];
    const cleaned = value.filter((v) => v !== '');
    handleTextFieldChange(cleaned, field);
  }

  function handleSelectChange(e: SelectChangeEvent<any>) {
    if (field.multi) {
      handleMultiSelectChange(e as SelectChangeEvent<string[]>);
    } else {
      handleTextFieldChange(e.target.value, field);
    }
  }

  return (
    <Select
      multiple={field.multi}
      label={field.label}
      autoFocus={autoFocus}
      onChange={handleSelectChange}
      value={val}
    >
      {field.options?.map((o, j) => (
        <MenuItem value={typeof o === 'string' ? o : o.value} key={j}>
          {typeof o === 'string' ? o : o.label}
        </MenuItem>
      ))}
    </Select>
  );
}
