import { Box, Card } from 'components/mui';
import { CDSSRuleListDto, Operand } from 'types';
import OperandRowContainer from './OperandRowContainer';

interface Props {
  rule: CDSSRuleListDto;
  updateOperand: (operand: Operand) => void;
}

export default function OperandBuilder({ rule, updateOperand }: Props) {
  return (
    <Card>
      <Box style={{ marginBottom: '12px' }}>Conditions</Box>
      <OperandRowContainer updateOperand={updateOperand} operand={rule.rules} />
    </Card>
  );
}
