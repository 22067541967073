"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DevelopmentStatisticGender = exports.DevelopmentStatisticToVitalName = exports.DevelopmentStatistic = void 0;
var DevelopmentStatistic;
(function (DevelopmentStatistic) {
    DevelopmentStatistic["BMI"] = "bmi";
    DevelopmentStatistic["HEAD_CIRC"] = "head_circ";
    DevelopmentStatistic["LENGTH"] = "length";
    DevelopmentStatistic["STATURE"] = "stature";
    DevelopmentStatistic["WEIGHT_BABY"] = "weight_baby";
    DevelopmentStatistic["WEIGHT_ADOLESCENT"] = "weight_adolescent";
    DevelopmentStatistic["WEIGHT_FOR_LENGTH"] = "weight_for_length";
})(DevelopmentStatistic = exports.DevelopmentStatistic || (exports.DevelopmentStatistic = {}));
var DevelopmentStatisticToVitalName;
(function (DevelopmentStatisticToVitalName) {
    DevelopmentStatisticToVitalName["bmi"] = "BMI";
    DevelopmentStatisticToVitalName["head_circ"] = "HC";
    DevelopmentStatisticToVitalName["length"] = "Ht";
    DevelopmentStatisticToVitalName["stature"] = "Ht";
    DevelopmentStatisticToVitalName["weight_baby"] = "Wt";
    DevelopmentStatisticToVitalName["weight_adolescent"] = "Wt";
    DevelopmentStatisticToVitalName["weight_for_length"] = "weight_for_length";
})(DevelopmentStatisticToVitalName = exports.DevelopmentStatisticToVitalName || (exports.DevelopmentStatisticToVitalName = {}));
var DevelopmentStatisticGender;
(function (DevelopmentStatisticGender) {
    DevelopmentStatisticGender["MALE"] = "male";
    DevelopmentStatisticGender["FEMALE"] = "female";
})(DevelopmentStatisticGender = exports.DevelopmentStatisticGender || (exports.DevelopmentStatisticGender = {}));
