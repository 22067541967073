import CancelIcon from '@mui/icons-material/Cancel';
import { Card, Grid, IconButton, PageMode, Stack, TextField } from 'components';
import produce, { original } from 'immer';
import { Developer } from 'types';

type DeveloperTasksProps = {
  pageMode: PageMode;
  developer: Developer;
  setDeveloper: (value: React.SetStateAction<Developer>) => void;
};

export const DeveloperTasks = ({
  pageMode,
  developer,
  setDeveloper,
}: DeveloperTasksProps) => {
  return (
    <Card>
      <Stack spacing="md">
        {developer.tasks?.map((task) => {
          return (
            <Card variant="outlined" sx={{ p: 2 }}>
              <Stack direction="row" spacing="md" alignItems={'center'}>
                <Grid container spacing="sm">
                  <Grid item xs={6}>
                    {pageMode === 'View' && task.name}
                    {(pageMode === 'Add' || pageMode === 'Edit') && (
                      <TextField
                        value={task.name}
                        onChange={(e) =>
                          setDeveloper(
                            produce((draft) => {
                              let draftTask = draft.tasks?.find(
                                (dt) => original(dt) === task
                              );
                              draftTask!.name = e.target.value;
                            })
                          )
                        }
                      />
                    )}
                  </Grid>
                </Grid>

                <IconButton
                  onClick={() =>
                    setDeveloper(
                      produce((draft) => {
                        const index: number = draft.tasks?.findIndex(
                          (dt) => original(dt) === task
                        )!;
                        draft.tasks?.splice(index, 1);
                      })
                    )
                  }
                >
                  <CancelIcon />
                </IconButton>
              </Stack>
            </Card>
          );
        })}
      </Stack>
    </Card>
  );
};
