import InfoIcon from '@mui/icons-material/Info';
import { Box, BoxProps, IconButton, Popover } from 'components';
import { useState } from 'react';

type ButtonPopoverProps = BoxProps & {};

export const ButtonPopover = ({ children, ...rest }: ButtonPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const renderPopoverContent = () => {
    return (
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Box p={3}>{children}</Box>
      </Popover>
    );
  };

  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} color="primary">
        <InfoIcon />
      </IconButton>
      {renderPopoverContent()}
    </>
  );
};
