import { Box, Button, ListContainer, Page, Pagination, Stack } from 'components';

import DownloadIcon from '@mui/icons-material/Download';
import { ensomataApi } from 'api';
import { useEffect, useRef, useState } from 'react';
import { buildPath, buildTemplatePath } from 'services';
import { OrderActivityFilter, OrderMetric } from 'types';
import { OrderActivityListPageTable } from './OrderActivityListPageTable';

import { PAGE_SIZE } from 'constants/commonConstants';
import { PageRoute } from 'models';
import { OrderActivityListPageFilters } from './OrderActivityListPageFilters';

const routeName = 'orderActivity';
export const orderActivityListPageRoute: PageRoute = {
  handle: 'Order Activity',
  templatePath: buildTemplatePath(routeName),
  getPath: () => buildPath(routeName),
};

export const OrderActivityListPage = () => {
  const tableContainerRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState<number>(1);

  let defaultDateFrom = new Date(new Date().setHours(0, 0, 0, 0));
  const [filter, setFilter] = useState<OrderActivityFilter>({
    dateFrom: defaultDateFrom,
  });
  const [appliedFilter, setAppliedFilter] = useState<OrderActivityFilter>(filter);

  const { data: orderMetricsResponse } = ensomataApi.useGetOrderMetricsQuery({
    starts: appliedFilter.dateFrom?.toISOString(),
    ends: appliedFilter.dateTo?.toISOString(),
    staff: appliedFilter.staffId,
    locations: appliedFilter.primaryServiceLocationId,
  });

  // only invoke when there's a fresh orderMetricsResponse
  useEffect(() => {
    let csvReportUrl = orderMetricsResponse?.csvReportUrl || '';
    if (csvReportUrl) {
      downloadFile(csvReportUrl, 'OrderActivity.csv');
    }
  }, [orderMetricsResponse]);

  // this makes orderMetrics from readOnly to writable
  let orderMetrics: OrderMetric[] | undefined = orderMetricsResponse?.orderMetrics
    ? [...orderMetricsResponse?.orderMetrics]
    : undefined;

  if (orderMetrics) {
    for (let i = 0; i < orderMetrics?.length; i++) {
      orderMetrics[i] = Object.assign({ row: i + 1 }, orderMetrics[i]);
    }
  }

  useEffect(() => {
    setPage(1);
  }, [appliedFilter]);

  function downloadFile(url, filename) {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename || 'download';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const downloadExcel = () => {
    let element: HTMLElement = document.getElementsByClassName(
      'dx-datagrid-export-button'
    )[0] as HTMLElement;
    element.click();
  };

  return (
    <Page
      header={
        <>
          <Box>{orderActivityListPageRoute.handle}</Box>
          <Button variant="contained" onClick={downloadExcel} sx={{ flexShrink: 0 }}>
            <Stack direction="row" alignItems="center" spacing="sm">
              <Box>.xls</Box>
              <DownloadIcon sx={{ fontSize: '1rem' }} />
            </Stack>
          </Button>
        </>
      }
    >
      <ListContainer
        header={
          <OrderActivityListPageFilters
            filter={filter}
            setFilter={setFilter}
            appliedFilter={appliedFilter}
            setAppliedFilter={setAppliedFilter}
          />
        }
        tableContainer={
          <OrderActivityListPageTable
            tableContainerRef={tableContainerRef}
            page={page}
            orderMetrics={orderMetrics}
          />
        }
        pagination={
          <Pagination
            total={orderMetrics?.length}
            page={page}
            size={PAGE_SIZE}
            onPageChange={(page) => setPage(page)}
            tableContainerRef={tableContainerRef}
          />
        }
      />
    </Page>
  );
};
