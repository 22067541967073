import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  Accordion,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Card } from 'components/mui';
import { Fragment } from 'react';
import { CDSSRuleVersion, Diff } from 'types';

interface Props {
  version?: CDSSRuleVersion;
}

type Key = keyof Partial<Diff>;

type KeyList = {
  accessor: Key;
  label: string;
};

const keys: KeyList[] = [
  { accessor: 'key', label: 'Field' },
  { accessor: 'type', label: 'Change Type' },
  { accessor: 'oldValue', label: 'Old Value' },
  { accessor: 'value', label: 'New Value' },
];

const DiffRow = ({ diff }: { diff: Diff }) => {
  const { changes } = diff;

  if (changes?.length) {
    return (
      <>
        {changes.map((change, i) => {
          return (
            <DiffRow
              key={i}
              diff={{
                ...change,
                oldValue: change.oldValue ?? diff.oldValue,
                // value: change.value ?? diff.oldValue,
                key: [diff.key, change.key].join('.'),
              }}
            />
          );
        })}
      </>
    );
  }

  return (
    <TableRow>
      {keys.map(({ accessor }) => {
        let val = diff[accessor] ?? '';

        if (typeof val === 'object') {
          val = JSON.stringify(val);
        }

        return <TableCell key={accessor}>{`${val}`}</TableCell>;
      })}
    </TableRow>
  );
};

function VersionTable({ version }: Props) {
  if (!version) return <></>;
  const { diffs } = version;

  let versionLabel = '';

  if (version.version) {
    const { createdBy, version: v } = version;
    if (v === 1) {
      versionLabel = `Version ${v} by ${createdBy}`;
    } else {
      versionLabel = `Version ${v - 1} to Version ${v} by ${createdBy}`;
    }
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography>{versionLabel}</Typography>
      </AccordionSummary>
      <Table>
        <TableHead>
          <TableRow>
            {keys.map(({ label }) => (
              <TableCell key={label}>{label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {diffs?.map((d, i) => {
            let diff: Diff;
            if (typeof d === 'object') {
              diff = d;
            } else {
              // in appsync this json may be stringified
              diff = JSON.parse(d as any) as Diff;
            }
            // when adding a value to an empty field there will be a "Remove" update with
            // two null values. Skip these.
            const isEmptyFieldRemoval = !diff.oldValue && !diff.value && !diff.changes;
            if (isEmptyFieldRemoval) return <Fragment key={i} />;
            return <DiffRow key={i} diff={diff} />;
          })}
        </TableBody>
      </Table>
    </Accordion>
  );
}

export default function VersionsContainer({ versions }: { versions: CDSSRuleVersion[] }) {
  return (
    <Card sx={{ marginTop: 4 }}>
      <Box sx={{ marginBottom: 4 }}>Changes</Box>
      {versions?.map((v, i) => (
        <VersionTable version={v} key={i} />
      ))}
    </Card>
  );
}
