import {
  LOCAL_STORAGE_REDIRECT_TO,
  LocalStorageRedirectTo,
} from 'constants/localStorageConstants';
import { useAuth } from 'hooks';
import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

export function AuthCheck({ children }: any) {
  const { isAuthenticated, isLoading } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      const redirectToItemString = localStorage.getItem(LOCAL_STORAGE_REDIRECT_TO);

      if (redirectToItemString) {
        const redirectToItem: LocalStorageRedirectTo = JSON.parse(redirectToItemString);
        if (new Date().getTime() < redirectToItem.expiration) {
          navigate(redirectToItem.value);
        }

        localStorage.removeItem(LOCAL_STORAGE_REDIRECT_TO);
      }
    }
  }, [navigate, isAuthenticated]);

  if (isLoading) return null;

  if (isAuthenticated) return <>{children}</>;

  if (!pathname || pathname === '/') {
    return <Navigate to={`/login`} />;
  } else {
    return <Navigate to={`/login?redirectTo=${encodeURIComponent(pathname)}`} />;
  }
}
