import { MenuItem, Select } from '@mui/material';
import { DoSPropertyName, NoPreviousDoSOperand, OperandValues } from 'types';
import { OperandProps } from './constants/OperandProps';

const DoSValues: string[] = Object.keys(DoSPropertyName);

export default function NoPreviousDoS({
  operand,
  updateOperand,
}: OperandProps<NoPreviousDoSOperand>) {
  // check for "value" so that ts doesn't complain about accessing "value" on an operand without value
  const isPrev = operand?.operand === OperandValues.noPreviousDoS;
  if (!isPrev) return <></>;

  const handleValueChange = (e: any) => {
    updateOperand({ ...operand, DoSPropertyName: e.target.value as DoSPropertyName });
  };

  return (
    <>
      <Select
        label="DOS Name"
        onChange={handleValueChange}
        value={operand.DoSPropertyName}
      >
        {DoSValues.map((o, i) => (
          <MenuItem value={o} key={i}>
            {o}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
