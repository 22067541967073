import { Box, BoxProps, InputLabel } from '@mui/material';

import { ReactNode } from 'react';

type DataDisplayProps = BoxProps & {
  label?: ReactNode;
  type?: 'normal' | 'textarea';
};

export function DataDisplay({
  label,
  type = 'normal',
  children,
  ...rest
}: DataDisplayProps) {
  let content: ReactNode = children;

  if (type === 'textarea') {
    content = (
      <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>{children}</pre>
    );
  }

  return (
    <Box borderBottom={1} borderColor="grey.300" {...rest}>
      <InputLabel shrink={true}>{label}</InputLabel>
      <Box minHeight="2.2em">{content}</Box>
    </Box>
  );
}
