import { Box, Button, ListContainer, Page } from 'components';
import { SavableContext } from 'contexts';
import { useRemountPageOutlet } from 'hooks';
import { PageRoute } from 'models';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { buildPath, buildTemplatePath } from 'services';
import { Developer } from 'types';
import { newDeveloperPageRoute } from '../NewDeveloperPage/NewDeveloperPage';
import { DeveloperTable } from './DeveloperTable';

const routeName = 'developers';
export const developerListPageRoute: PageRoute = {
  handle: 'Developers',
  templatePath: buildTemplatePath(routeName),
  getPath: () => buildPath(routeName),
};

export const DeveloperListPage = () => {
  const navigate = useNavigate();
  const { outletKey, remountOutlet } = useRemountPageOutlet();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fetchDate, setFetchDate] = useState<Date>();
  const [developers, setDevelopers] = useState<Developer[]>();

  useEffect(() => {
    // when component mounts
    fetchDevelopers();
  }, []);

  const refetch = () => {
    setFetchDate(new Date());
  };

  const fetchDevelopers = () => {
    const developers: Developer[] = [];
    for (let i: number = 1; i < 100; i++) {
      const developer: Developer = {
        id: i,
        uuid: `${i}${i}${i}-${i}${i}${i}${i}${i}-${i}${i}${i}`,
        firstName: `first-${i}`,
        lastName: `last-${i}`,
        genderId: 1,
        genderName: i % 2 === 0 ? 'Male' : 'Female',
        married: false,
        lastUpdatedDate: new Date(),
        lastUpdatedBy: 'Joe Falcomata',
      };

      developers.push(developer);
    }

    setDevelopers(developers);
    setFetchDate(new Date());
  };

  return (
    <Page
      header={
        <>
          <Box>{developerListPageRoute.handle}</Box>
          <Button
            variant="outlined"
            onClick={() => navigate(newDeveloperPageRoute.getPath())}
          >
            + New
          </Button>
        </>
      }
    >
      <ListContainer tableContainer={<DeveloperTable developers={developers} />} />

      <SavableContext.Provider
        value={{
          onSave: () => refetch(),
          remountOutlet: () => remountOutlet(),
        }}
      >
        <Outlet key={outletKey} />
      </SavableContext.Provider>
    </Page>
  );
};
