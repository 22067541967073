import { MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { Select } from 'components/mui';
import {
  CONDITIONAL_OPERATORS,
  ConditionalOperatorType,
  OperandValues,
  PackYearsOperand,
} from 'types';
import { OperandProps } from './constants/OperandProps';

export default function PackYears({
  operand,
  updateOperand,
}: OperandProps<PackYearsOperand>) {
  // check for "value" so that ts doesn't complain about accessing "value" on an operand without value
  const isPackYears = operand?.operand === OperandValues.packYears;
  if (!isPackYears) return <></>;

  const handleOperandChange = (e: SelectChangeEvent<any>) => {
    updateOperand({ ...operand, operator: e.target.value as ConditionalOperatorType });
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateOperand({ ...operand, value: Number(e.target.value) });
  };

  return (
    <>
      <Select onChange={handleOperandChange} value={operand.operator}>
        {CONDITIONAL_OPERATORS.map((o, i) => (
          <MenuItem value={o} key={i}>
            {o}
          </MenuItem>
        ))}
      </Select>
      <TextField onChange={handleValueChange} type="number" value={operand.value} />
    </>
  );
}
