"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RaceCode = void 0;
var RaceCode;
(function (RaceCode) {
    RaceCode["UNKNOWN"] = "";
    RaceCode["ABENAKI"] = "1006-6";
    RaceCode["ABSENTEE_SHAWNEE"] = "1579-2";
    RaceCode["ACOMA"] = "1490-2";
    RaceCode["AFGHANISTANI"] = "2126-1";
    RaceCode["AFRICAN"] = "2060-2";
    RaceCode["AFRICAN_AMERICAN"] = "2058-6";
    RaceCode["AGDAAGUX"] = "1994-3";
    RaceCode["AGUA_CALIENTE"] = "1212-0";
    RaceCode["AGUA_CALIENTE_CAHUILLA"] = "1045-4";
    RaceCode["AHTNA"] = "1740-0";
    RaceCode["AK_CHIN"] = "1654-3";
    RaceCode["AKHIOK"] = "1993-5";
    RaceCode["AKIACHAK"] = "1897-8";
    RaceCode["AKIAK"] = "1898-6";
    RaceCode["AKUTAN"] = "2007-3";
    RaceCode["ALABAMA_COUSHATTA"] = "1187-4";
    RaceCode["ALABAMA_CREEK"] = "1194-0";
    RaceCode["ALABAMA_QUASSARTE"] = "1195-7";
    RaceCode["ALAKANUK"] = "1899-4";
    RaceCode["ALAMO_NAVAJO"] = "1383-9";
    RaceCode["ALANVIK"] = "1744-2";
    RaceCode["ALASKA_INDIAN"] = "1737-6";
    RaceCode["ALASKA_NATIVE"] = "1735-0";
    RaceCode["ALASKAN_ATHABASCAN"] = "1739-2";
    RaceCode["ALATNA"] = "1741-8";
    RaceCode["ALEKNAGIK"] = "1900-0";
    RaceCode["ALEUT"] = "1966-1";
    RaceCode["ALEUT_CORPORATION"] = "2008-1";
    RaceCode["ALEUTIAN"] = "2009-9";
    RaceCode["ALEUTIAN_ISLANDER"] = "2010-7";
    RaceCode["ALEXANDER"] = "1742-6";
    RaceCode["ALGONQUIAN"] = "1008-2";
    RaceCode["ALLAKAKET"] = "1743-4";
    RaceCode["ALLEN_CANYON"] = "1671-7";
    RaceCode["ALPINE"] = "1688-1";
    RaceCode["ALSEA"] = "1392-0";
    RaceCode["ALUTIIQ_ALEUT"] = "1968-7";
    RaceCode["AMBLER"] = "1845-7";
    RaceCode["AMERICAN_INDIAN"] = "1004-1";
    RaceCode["AMERICAN_INDIAN_OR_ALASKA_NATIVE"] = "1002-5";
    RaceCode["ANAKTUVUK"] = "1846-5";
    RaceCode["ANAKTUVUK_PASS"] = "1847-3";
    RaceCode["ANDREAFSKY"] = "1901-8";
    RaceCode["ANGOON"] = "1814-3";
    RaceCode["ANIAK"] = "1902-6";
    RaceCode["ANVIK"] = "1745-9";
    RaceCode["APACHE"] = "1010-8";
    RaceCode["ARAB"] = "2129-5";
    RaceCode["ARAPAHO"] = "1021-5";
    RaceCode["ARCTIC"] = "1746-7";
    RaceCode["ARCTIC_SLOPE_CORPORATION"] = "1849-9";
    RaceCode["ARCTIC_SLOPE_INUPIAT"] = "1848-1";
    RaceCode["ARIKARA"] = "1026-4";
    RaceCode["ARIZONA_TEWA"] = "1491-0";
    RaceCode["ARMENIAN"] = "2109-7";
    RaceCode["AROOSTOOK"] = "1366-4";
    RaceCode["ASIAN"] = "2028-9";
    RaceCode["ASIAN_INDIAN"] = "2029-7";
    RaceCode["ASSINIBOINE"] = "1028-0";
    RaceCode["ASSINIBOINE_SIOUX"] = "1030-6";
    RaceCode["ASSYRIAN"] = "2119-6";
    RaceCode["ATKA"] = "2011-5";
    RaceCode["ATMAUTLUAK"] = "1903-4";
    RaceCode["ATQASUK"] = "1850-7";
    RaceCode["ATSINA"] = "1265-8";
    RaceCode["ATTACAPA"] = "1234-4";
    RaceCode["AUGUSTINE"] = "1046-2";
    RaceCode["BAD_RIVER"] = "1124-7";
    RaceCode["BAHAMIAN"] = "2067-7";
    RaceCode["BANGLADESHI"] = "2030-5";
    RaceCode["BANNOCK"] = "1033-0";
    RaceCode["BARBADIAN"] = "2068-5";
    RaceCode["BARRIO_LIBRE"] = "1712-9";
    RaceCode["BARROW"] = "1851-5";
    RaceCode["BATTLE_MOUNTAIN"] = "1587-5";
    RaceCode["BAY_MILLS_CHIPPEWA"] = "1125-4";
    RaceCode["BEAVER"] = "1747-5";
    RaceCode["BELKOFSKI"] = "2012-3";
    RaceCode["BERING_STRAITS_INUPIAT"] = "1852-3";
    RaceCode["BETHEL"] = "1904-2";
    RaceCode["BHUTANESE"] = "2031-3";
    RaceCode["BIG_CYPRESS"] = "1567-7";
    RaceCode["BILL_MOORES_SLOUGH"] = "1905-9";
    RaceCode["BILOXI"] = "1235-1";
    RaceCode["BIRCH_CREEK"] = "1748-3";
    RaceCode["BISHOP"] = "1417-5";
    RaceCode["BLACK"] = "2056-0";
    RaceCode["BLACK_OR_AFRICAN_AMERICAN"] = "2054-5";
    RaceCode["BLACKFEET"] = "1035-5";
    RaceCode["BLACKFOOT_SIOUX"] = "1610-5";
    RaceCode["BOIS_FORTE"] = "1126-2";
    RaceCode["BOTSWANAN"] = "2061-0";
    RaceCode["BREVIG_MISSION"] = "1853-1";
    RaceCode["BRIDGEPORT"] = "1418-3";
    RaceCode["BRIGHTON"] = "1568-5";
    RaceCode["BRISTOL_BAY_ALEUT"] = "1972-9";
    RaceCode["BRISTOL_BAY_YUPIK"] = "1906-7";
    RaceCode["BROTHERTON"] = "1037-1";
    RaceCode["BRULE_SIOUX"] = "1611-3";
    RaceCode["BUCKLAND"] = "1854-9";
    RaceCode["BURMESE"] = "2032-1";
    RaceCode["BURNS_PAIUTE"] = "1419-1";
    RaceCode["BURT_LAKE_BAND"] = "1039-7";
    RaceCode["BURT_LAKE_CHIPPEWA"] = "1127-0";
    RaceCode["BURT_LAKE_OTTAWA"] = "1412-6";
    RaceCode["CABAZON"] = "1047-0";
    RaceCode["CADDO"] = "1041-3";
    RaceCode["CAHTO"] = "1054-6";
    RaceCode["CAHUILLA"] = "1044-7";
    RaceCode["CALIFORNIA_TRIBES"] = "1053-8";
    RaceCode["CALISTA_YUPIK"] = "1907-5";
    RaceCode["CAMBODIAN"] = "2033-9";
    RaceCode["CAMPO"] = "1223-7";
    RaceCode["CANADIAN_AND_LATIN_AMERICAN_INDIAN"] = "1068-6";
    RaceCode["CANADIAN_INDIAN"] = "1069-4";
    RaceCode["CANONCITO_NAVAJO"] = "1384-7";
    RaceCode["CANTWELL"] = "1749-1";
    RaceCode["CAPITAN_GRANDE"] = "1224-5";
    RaceCode["CAROLINIAN"] = "2092-5";
    RaceCode["CARSON"] = "1689-9";
    RaceCode["CATAWBA"] = "1076-9";
    RaceCode["CAYUGA"] = "1286-4";
    RaceCode["CAYUSE"] = "1078-5";
    RaceCode["CEDARVILLE"] = "1420-9";
    RaceCode["CELILO"] = "1393-8";
    RaceCode["CENTRAL_AMERICAN_INDIAN"] = "1070-2";
    RaceCode["CENTRAL_COUNCIL_OF_TLINGIT_AND_HAIDA_TRIBES"] = "1815-0";
    RaceCode["CENTRAL_POMO"] = "1465-4";
    RaceCode["CHALKYITSIK"] = "1750-9";
    RaceCode["CHAMORRO"] = "2088-3";
    RaceCode["CHEFORNAK"] = "1908-3";
    RaceCode["CHEHALIS"] = "1080-1";
    RaceCode["CHEMAKUAN"] = "1082-7";
    RaceCode["CHEMEHUEVI"] = "1086-8";
    RaceCode["CHENEGA"] = "1985-1";
    RaceCode["CHEROKEE"] = "1088-4";
    RaceCode["CHEROKEE_ALABAMA"] = "1089-2";
    RaceCode["CHEROKEE_SHAWNEE"] = "1100-7";
    RaceCode["CHEROKEES_OF_NORTHEAST_ALABAMA"] = "1090-0";
    RaceCode["CHEROKEES_OF_SOUTHEAST_ALABAMA"] = "1091-8";
    RaceCode["CHEVAK"] = "1909-1";
    RaceCode["CHEYENNE"] = "1102-3";
    RaceCode["CHEYENNE_RIVER_SIOUX"] = "1612-1";
    RaceCode["CHEYENNE_ARAPAHO"] = "1106-4";
    RaceCode["CHICKAHOMINY"] = "1108-0";
    RaceCode["CHICKALOON"] = "1751-7";
    RaceCode["CHICKASAW"] = "1112-2";
    RaceCode["CHIGNIK"] = "1973-7";
    RaceCode["CHIGNIK_LAGOON"] = "2013-1";
    RaceCode["CHIGNIK_LAKE"] = "1974-5";
    RaceCode["CHILKAT"] = "1816-8";
    RaceCode["CHILKOOT"] = "1817-6";
    RaceCode["CHIMARIKO"] = "1055-3";
    RaceCode["CHINESE"] = "2034-7";
    RaceCode["CHINIK"] = "1855-6";
    RaceCode["CHINOOK"] = "1114-8";
    RaceCode["CHIPPEWA"] = "1123-9";
    RaceCode["CHIPPEWA_CREE"] = "1150-2";
    RaceCode["CHIRICAHUA"] = "1011-6";
    RaceCode["CHISTOCHINA"] = "1752-5";
    RaceCode["CHITIMACHA"] = "1153-6";
    RaceCode["CHITINA"] = "1753-3";
    RaceCode["CHOCTAW"] = "1155-1";
    RaceCode["CHUATHBALUK"] = "1910-9";
    RaceCode["CHUGACH_ALEUT"] = "1984-4";
    RaceCode["CHUGACH_CORPORATION"] = "1986-9";
    RaceCode["CHUKCHANSI"] = "1718-6";
    RaceCode["CHUMASH"] = "1162-7";
    RaceCode["CHUUKESE"] = "2097-4";
    RaceCode["CIRCLE"] = "1754-1";
    RaceCode["CITIZEN_BAND_POTAWATOMI"] = "1479-5";
    RaceCode["CLARKS_POINT"] = "1911-7";
    RaceCode["CLATSOP"] = "1115-5";
    RaceCode["CLEAR_LAKE"] = "1165-0";
    RaceCode["CLIFTON_CHOCTAW"] = "1156-9";
    RaceCode["COAST_MIWOK"] = "1056-1";
    RaceCode["COAST_YUROK"] = "1733-5";
    RaceCode["COCHITI"] = "1492-8";
    RaceCode["COCOPAH"] = "1725-1";
    RaceCode["COEUR_DALENE"] = "1167-6";
    RaceCode["COHARIE"] = "1169-2";
    RaceCode["COLORADO_RIVER"] = "1171-8";
    RaceCode["COLUMBIA"] = "1394-6";
    RaceCode["COLUMBIA_RIVER_CHINOOK"] = "1116-3";
    RaceCode["COLVILLE"] = "1173-4";
    RaceCode["COMANCHE"] = "1175-9";
    RaceCode["COOK_INLET"] = "1755-8";
    RaceCode["COOS"] = "1180-9";
    RaceCode["COOS_LOWER_UMPQUA_SIUSLAW"] = "1178-3";
    RaceCode["COPPER_CENTER"] = "1756-6";
    RaceCode["COPPER_RIVER"] = "1757-4";
    RaceCode["COQUILLES"] = "1182-5";
    RaceCode["COSTANOAN"] = "1184-1";
    RaceCode["COUNCIL"] = "1856-4";
    RaceCode["COUSHATTA"] = "1186-6";
    RaceCode["COW_CREEK_UMPQUA"] = "1668-3";
    RaceCode["COWLITZ"] = "1189-0";
    RaceCode["CRAIG"] = "1818-4";
    RaceCode["CREE"] = "1191-6";
    RaceCode["CREEK"] = "1193-2";
    RaceCode["CROATAN"] = "1207-0";
    RaceCode["CROOKED_CREEK"] = "1912-5";
    RaceCode["CROW"] = "1209-6";
    RaceCode["CROW_CREEK_SIOUX"] = "1613-9";
    RaceCode["CUPENO"] = "1211-2";
    RaceCode["CUYAPAIPE"] = "1225-2";
    RaceCode["DAKOTA_SIOUX"] = "1614-7";
    RaceCode["DECLINED_TO_SPECIFY"] = "ASKU";
    RaceCode["DEERING"] = "1857-2";
    RaceCode["DELAWARE"] = "1214-6";
    RaceCode["DIEGUENO"] = "1222-9";
    RaceCode["DIGGER"] = "1057-9";
    RaceCode["DILLINGHAM"] = "1913-3";
    RaceCode["DOMINICA_ISLANDER"] = "2070-1";
    RaceCode["DOMINICAN"] = "2069-3";
    RaceCode["DOT_LAKE"] = "1758-2";
    RaceCode["DOUGLAS"] = "1819-2";
    RaceCode["DOYON"] = "1759-0";
    RaceCode["DRESSLERVILLE"] = "1690-7";
    RaceCode["DRY_CREEK"] = "1466-2";
    RaceCode["DUCK_VALLEY"] = "1603-0";
    RaceCode["DUCKWATER"] = "1588-3";
    RaceCode["DUWAMISH"] = "1519-8";
    RaceCode["EAGLE"] = "1760-8";
    RaceCode["EASTERN_CHEROKEE"] = "1092-6";
    RaceCode["EASTERN_CHICKAHOMINY"] = "1109-8";
    RaceCode["EASTERN_CREEK"] = "1196-5";
    RaceCode["EASTERN_DELAWARE"] = "1215-3";
    RaceCode["EASTERN_MUSCOGEE"] = "1197-3";
    RaceCode["EASTERN_POMO"] = "1467-0";
    RaceCode["EASTERN_SHAWNEE"] = "1580-0";
    RaceCode["EASTERN_TRIBES"] = "1233-6";
    RaceCode["ECHOTA_CHEROKEE"] = "1093-4";
    RaceCode["EEK"] = "1914-1";
    RaceCode["EGEGIK"] = "1975-2";
    RaceCode["EGYPTIAN"] = "2120-4";
    RaceCode["EKLUTNA"] = "1761-6";
    RaceCode["EKUK"] = "1915-8";
    RaceCode["EKWOK"] = "1916-6";
    RaceCode["ELIM"] = "1858-0";
    RaceCode["ELKO"] = "1589-1";
    RaceCode["ELY"] = "1590-9";
    RaceCode["EMMONAK"] = "1917-4";
    RaceCode["ENGLISH"] = "2110-5";
    RaceCode["ENGLISH_BAY"] = "1987-7";
    RaceCode["ESKIMO"] = "1840-8";
    RaceCode["ESSELEN"] = "1250-0";
    RaceCode["ETHIOPIAN"] = "2062-8";
    RaceCode["ETOWAH_CHEROKEE"] = "1094-2";
    RaceCode["EUROPEAN"] = "2108-9";
    RaceCode["EVANSVILLE"] = "1762-4";
    RaceCode["EYAK"] = "1990-1";
    RaceCode["FALLON"] = "1604-8";
    RaceCode["FALSE_PASS"] = "2015-6";
    RaceCode["FIJIAN"] = "2101-4";
    RaceCode["FILIPINO"] = "2036-2";
    RaceCode["FLANDREAU_SANTEE"] = "1615-4";
    RaceCode["FLORIDA_SEMINOLE"] = "1569-3";
    RaceCode["FOND_DU_LAC"] = "1128-8";
    RaceCode["FOREST_COUNTY"] = "1480-3";
    RaceCode["FORT_BELKNAP"] = "1252-6";
    RaceCode["FORT_BERTHOLD"] = "1254-2";
    RaceCode["FORT_BIDWELL"] = "1421-7";
    RaceCode["FORT_HALL"] = "1258-3";
    RaceCode["FORT_INDEPENDENCE"] = "1422-5";
    RaceCode["FORT_MCDERMITT"] = "1605-5";
    RaceCode["FORT_MCDOWELL"] = "1256-7";
    RaceCode["FORT_PECK"] = "1616-2";
    RaceCode["FORT_PECK_ASSINIBOINE_SIOUX"] = "1031-4";
    RaceCode["FORT_SILL_APACHE"] = "1012-4";
    RaceCode["FORT_YUKON"] = "1763-2";
    RaceCode["FRENCH"] = "2111-3";
    RaceCode["FRENCH_AMERICAN_INDIAN"] = "1071-0";
    RaceCode["GABRIELENO"] = "1260-9";
    RaceCode["GAKONA"] = "1764-0";
    RaceCode["GALENA"] = "1765-7";
    RaceCode["GAMBELL"] = "1892-9";
    RaceCode["GAY_HEAD_WAMPANOAG"] = "1680-8";
    RaceCode["GEORGETOWN_EASTERN_TRIBES"] = "1236-9";
    RaceCode["GEORGETOWN_YUPIK_ESKIMO"] = "1962-0";
    RaceCode["GERMAN"] = "2112-1";
    RaceCode["GILA_BEND"] = "1655-0";
    RaceCode["GILA_RIVER_PIMA_MARICOPA"] = "1457-1";
    RaceCode["GOLOVIN"] = "1859-8";
    RaceCode["GOODNEWS_BAY"] = "1918-2";
    RaceCode["GOSHUTE"] = "1591-7";
    RaceCode["GRAND_PORTAGE"] = "1129-6";
    RaceCode["GRAND_RONDE"] = "1262-5";
    RaceCode["GRAND_TRAVERSE_BAND_OF_OTTAWA_CHIPPEWA"] = "1130-4";
    RaceCode["GRAYLING"] = "1766-5";
    RaceCode["GREENLAND_ESKIMO"] = "1842-4";
    RaceCode["GROS_VENTRES"] = "1264-1";
    RaceCode["GUAMANIAN"] = "2087-5";
    RaceCode["GUAMANIAN_OR_CHAMORRO"] = "2086-7";
    RaceCode["GULKANA"] = "1767-3";
    RaceCode["HAIDA"] = "1820-0";
    RaceCode["HAITIAN"] = "2071-9";
    RaceCode["HALIWA"] = "1267-4";
    RaceCode["HANNAHVILLE"] = "1481-1";
    RaceCode["HAVASUPAI"] = "1726-9";
    RaceCode["HEALY_LAKE"] = "1768-1";
    RaceCode["HIDATSA"] = "1269-0";
    RaceCode["HISPANIC"] = "2135-2";
    RaceCode["HMONG"] = "2037-0";
    RaceCode["HO_CHUNK"] = "1697-2";
    RaceCode["HOH"] = "1083-5";
    RaceCode["HOLLYWOOD_SEMINOLE"] = "1570-1";
    RaceCode["HOLY_CROSS"] = "1769-9";
    RaceCode["HOONAH"] = "1821-8";
    RaceCode["HOOPA"] = "1271-6";
    RaceCode["HOOPA_EXTENSION"] = "1275-7";
    RaceCode["HOOPER_BAY"] = "1919-0";
    RaceCode["HOPI"] = "1493-6";
    RaceCode["HOUMA"] = "1277-3";
    RaceCode["HUALAPAI"] = "1727-7";
    RaceCode["HUGHES"] = "1770-7";
    RaceCode["HURON_POTAWATOMI"] = "1482-9";
    RaceCode["HUSLIA"] = "1771-5";
    RaceCode["HYDABURG"] = "1822-6";
    RaceCode["IGIUGIG"] = "1976-0";
    RaceCode["ILIAMNA"] = "1772-3";
    RaceCode["ILLINOIS_MIAMI"] = "1359-9";
    RaceCode["INAJA_COSMIT"] = "1279-9";
    RaceCode["INALIK_DIOMEDE"] = "1860-6";
    RaceCode["INDIAN_TOWNSHIP"] = "1442-3";
    RaceCode["INDIANA_MIAMI"] = "1360-7";
    RaceCode["INDONESIAN"] = "2038-8";
    RaceCode["INUPIAQ"] = "1861-4";
    RaceCode["INUPIAT_ESKIMO"] = "1844-0";
    RaceCode["IOWA"] = "1281-5";
    RaceCode["IOWA_OF_KANSAS_NEBRASKA"] = "1282-3";
    RaceCode["IOWA_OF_OKLAHOMA"] = "1283-1";
    RaceCode["IOWA_SAC_AND_FOX"] = "1552-9";
    RaceCode["IQURMUIT_RUSSIAN_MISSION"] = "1920-8";
    RaceCode["IRANIAN"] = "2121-2";
    RaceCode["IRAQI"] = "2122-0";
    RaceCode["IRISH"] = "2113-9";
    RaceCode["IROQUOIS"] = "1285-6";
    RaceCode["ISLETA"] = "1494-4";
    RaceCode["ISRAEILI"] = "2127-9";
    RaceCode["ITALIAN"] = "2114-7";
    RaceCode["IVANOF_BAY"] = "1977-8";
    RaceCode["IWO_JIMAN"] = "2048-7";
    RaceCode["JAMAICAN"] = "2072-7";
    RaceCode["JAMESTOWN"] = "1313-6";
    RaceCode["JAPANESE"] = "2039-6";
    RaceCode["JEMEZ"] = "1495-1";
    RaceCode["JENA_CHOCTAW"] = "1157-7";
    RaceCode["JICARILLA_APACHE"] = "1013-2";
    RaceCode["JUANENO"] = "1297-1";
    RaceCode["KAIBAB"] = "1423-3";
    RaceCode["KAKE"] = "1823-4";
    RaceCode["KAKTOVIK"] = "1862-2";
    RaceCode["KALAPUYA"] = "1395-3";
    RaceCode["KALISPEL"] = "1299-7";
    RaceCode["KALSKAG"] = "1921-6";
    RaceCode["KALTAG"] = "1773-1";
    RaceCode["KARLUK"] = "1995-0";
    RaceCode["KARUK"] = "1301-1";
    RaceCode["KASAAN"] = "1824-2";
    RaceCode["KASHIA"] = "1468-8";
    RaceCode["KASIGLUK"] = "1922-4";
    RaceCode["KATHLAMET"] = "1117-1";
    RaceCode["KAW"] = "1303-7";
    RaceCode["KAWAIISU"] = "1058-7";
    RaceCode["KAWERAK"] = "1863-0";
    RaceCode["KENAITZE"] = "1825-9";
    RaceCode["KERES"] = "1496-9";
    RaceCode["KERN_RIVER"] = "1059-5";
    RaceCode["KETCHIKAN"] = "1826-7";
    RaceCode["KEWEENAW"] = "1131-2";
    RaceCode["KIALEGEE"] = "1198-1";
    RaceCode["KIANA"] = "1864-8";
    RaceCode["KICKAPOO"] = "1305-2";
    RaceCode["KIKIALLUS"] = "1520-6";
    RaceCode["KING_COVE"] = "2014-9";
    RaceCode["KING_SALMON"] = "1978-6";
    RaceCode["KIOWA"] = "1309-4";
    RaceCode["KIPNUK"] = "1923-2";
    RaceCode["KIRIBATI"] = "2096-6";
    RaceCode["KIVALINA"] = "1865-5";
    RaceCode["KLALLAM"] = "1312-8";
    RaceCode["KLAMATH"] = "1317-7";
    RaceCode["KLAWOCK"] = "1827-5";
    RaceCode["KLUTI_KAAH"] = "1774-9";
    RaceCode["KNIK"] = "1775-6";
    RaceCode["KOBUK"] = "1866-3";
    RaceCode["KODIAK"] = "1996-8";
    RaceCode["KOKHANOK"] = "1979-4";
    RaceCode["KOLIGANEK"] = "1924-0";
    RaceCode["KONGIGANAK"] = "1925-7";
    RaceCode["KONIAG_ALEUT"] = "1992-7";
    RaceCode["KONKOW"] = "1319-3";
    RaceCode["KOOTENAI"] = "1321-9";
    RaceCode["KOREAN"] = "2040-4";
    RaceCode["KOSRAEAN"] = "2093-3";
    RaceCode["KOTLIK"] = "1926-5";
    RaceCode["KOTZEBUE"] = "1867-1";
    RaceCode["KOYUK"] = "1868-9";
    RaceCode["KOYUKUK"] = "1776-4";
    RaceCode["KWETHLUK"] = "1927-3";
    RaceCode["KWIGILLINGOK"] = "1928-1";
    RaceCode["KWIGUK"] = "1869-7";
    RaceCode["LA_JOLLA"] = "1332-6";
    RaceCode["LA_POSTA"] = "1226-0";
    RaceCode["LAC_COURTE_OREILLES"] = "1132-0";
    RaceCode["LAC_DU_FLAMBEAU"] = "1133-8";
    RaceCode["LAC_VIEUX_DESERT_CHIPPEWA"] = "1134-6";
    RaceCode["LAGUNA"] = "1497-7";
    RaceCode["LAKE_MINCHUMINA"] = "1777-2";
    RaceCode["LAKE_SUPERIOR"] = "1135-3";
    RaceCode["LAKE_TRAVERSE_SIOUX"] = "1617-0";
    RaceCode["LAOTIAN"] = "2041-2";
    RaceCode["LARSEN_BAY"] = "1997-6";
    RaceCode["LAS_VEGAS"] = "1424-1";
    RaceCode["LASSIK"] = "1323-5";
    RaceCode["LEBANESE"] = "2123-8";
    RaceCode["LEECH_LAKE"] = "1136-1";
    RaceCode["LENNI_LENAPE"] = "1216-1";
    RaceCode["LEVELOCK"] = "1929-9";
    RaceCode["LIBERIAN"] = "2063-6";
    RaceCode["LIME"] = "1778-0";
    RaceCode["LIPAN_APACHE"] = "1014-0";
    RaceCode["LITTLE_SHELL_CHIPPEWA"] = "1137-9";
    RaceCode["LONE_PINE"] = "1425-8";
    RaceCode["LONG_ISLAND"] = "1325-0";
    RaceCode["LOS_COYOTES"] = "1048-8";
    RaceCode["LOVELOCK"] = "1426-6";
    RaceCode["LOWER_BRULE_SIOUX"] = "1618-8";
    RaceCode["LOWER_ELWHA"] = "1314-4";
    RaceCode["LOWER_KALSKAG"] = "1930-7";
    RaceCode["LOWER_MUSCOGEE"] = "1199-9";
    RaceCode["LOWER_SIOUX"] = "1619-6";
    RaceCode["LOWER_SKAGIT"] = "1521-4";
    RaceCode["LUISENO"] = "1331-8";
    RaceCode["LUMBEE"] = "1340-9";
    RaceCode["LUMMI"] = "1342-5";
    RaceCode["MACHIS_LOWER_CREEK_INDIAN"] = "1200-5";
    RaceCode["MADAGASCAR"] = "2052-9";
    RaceCode["MAIDU"] = "1344-1";
    RaceCode["MAKAH"] = "1348-2";
    RaceCode["MALAYSIAN"] = "2042-0";
    RaceCode["MALDIVIAN"] = "2049-5";
    RaceCode["MALHEUR_PAIUTE"] = "1427-4";
    RaceCode["MALISEET"] = "1350-8";
    RaceCode["MANDAN"] = "1352-4";
    RaceCode["MANLEY_HOT_SPRINGS"] = "1780-6";
    RaceCode["MANOKOTAK"] = "1931-5";
    RaceCode["MANZANITA"] = "1227-8";
    RaceCode["MARIANA_ISLANDER"] = "2089-1";
    RaceCode["MARICOPA"] = "1728-5";
    RaceCode["MARSHALL"] = "1932-3";
    RaceCode["MARSHALLESE"] = "2090-9";
    RaceCode["MARSHANTUCKET_PEQUOT"] = "1454-8";
    RaceCode["MARYS_IGLOO"] = "1889-5";
    RaceCode["MASHPEE_WAMPANOAG"] = "1681-6";
    RaceCode["MATINECOCK"] = "1326-8";
    RaceCode["MATTAPONI"] = "1354-0";
    RaceCode["MATTOLE"] = "1060-3";
    RaceCode["MAUNELUK_INUPIAT"] = "1870-5";
    RaceCode["MCGRATH"] = "1779-8";
    RaceCode["MDEWAKANTON_SIOUX"] = "1620-4";
    RaceCode["MEKORYUK"] = "1933-1";
    RaceCode["MELANESIAN"] = "2100-6";
    RaceCode["MENOMINEE"] = "1356-5";
    RaceCode["MENTASTA_LAKE"] = "1781-4";
    RaceCode["MESA_GRANDE"] = "1228-6";
    RaceCode["MESCALERO_APACHE"] = "1015-7";
    RaceCode["METLAKATLA"] = "1838-2";
    RaceCode["MEXICAN_AMERICAN_INDIAN"] = "1072-8";
    RaceCode["MIAMI"] = "1358-1";
    RaceCode["MICCOSUKEE"] = "1363-1";
    RaceCode["MICHIGAN_OTTAWA"] = "1413-4";
    RaceCode["MICMAC"] = "1365-6";
    RaceCode["MICRONESIAN"] = "2085-9";
    RaceCode["MIDDLE_EASTERN_OR_NORTH_AFRICAN"] = "2118-8";
    RaceCode["MILLE_LACS"] = "1138-7";
    RaceCode["MINICONJOU"] = "1621-2";
    RaceCode["MINNESOTA_CHIPPEWA"] = "1139-5";
    RaceCode["MINTO"] = "1782-2";
    RaceCode["MISSION_INDIANS"] = "1368-0";
    RaceCode["MISSISSIPPI_CHOCTAW"] = "1158-5";
    RaceCode["MISSOURI_SAC_AND_FOX"] = "1553-7";
    RaceCode["MIWOK"] = "1370-6";
    RaceCode["MOAPA"] = "1428-2";
    RaceCode["MODOC"] = "1372-2";
    RaceCode["MOHAVE"] = "1729-3";
    RaceCode["MOHAWK"] = "1287-2";
    RaceCode["MOHEGAN"] = "1374-8";
    RaceCode["MOLALA"] = "1396-1";
    RaceCode["MONO"] = "1376-3";
    RaceCode["MONTAUK"] = "1327-6";
    RaceCode["MOOR"] = "1237-7";
    RaceCode["MORONGO"] = "1049-6";
    RaceCode["MOUNTAIN_MAIDU"] = "1345-8";
    RaceCode["MOUNTAIN_VILLAGE"] = "1934-9";
    RaceCode["MOWA_BAND_OF_CHOCTAW"] = "1159-3";
    RaceCode["MUCKLESHOOT"] = "1522-2";
    RaceCode["MUNSEE"] = "1217-9";
    RaceCode["NAKNEK"] = "1935-6";
    RaceCode["NAMBE"] = "1498-5";
    RaceCode["NAMIBIAN"] = "2064-4";
    RaceCode["NANA_INUPIAT"] = "1871-3";
    RaceCode["NANSEMOND"] = "1238-5";
    RaceCode["NANTICOKE"] = "1378-9";
    RaceCode["NAPAKIAK"] = "1937-2";
    RaceCode["NAPASKIAK"] = "1938-0";
    RaceCode["NAPAUMUTE"] = "1936-4";
    RaceCode["NARRAGANSETT"] = "1380-5";
    RaceCode["NATCHEZ"] = "1239-3";
    RaceCode["NATIVE_HAWAIIAN"] = "2079-2";
    RaceCode["NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER"] = "2076-8";
    RaceCode["NAUSU_WAIWASH"] = "1240-1";
    RaceCode["NAVAJO"] = "1382-1";
    RaceCode["NEBRASKA_PONCA"] = "1475-3";
    RaceCode["NEBRASKA_WINNEBAGO"] = "1698-0";
    RaceCode["NELSON_LAGOON"] = "2016-4";
    RaceCode["NENANA"] = "1783-0";
    RaceCode["NEPALESE"] = "2050-3";
    RaceCode["NEW_HEBRIDES"] = "2104-8";
    RaceCode["NEW_STUYAHOK"] = "1940-6";
    RaceCode["NEWHALEN"] = "1939-8";
    RaceCode["NEWTOK"] = "1941-4";
    RaceCode["NEZ_PERCE"] = "1387-0";
    RaceCode["NIGERIAN"] = "2065-1";
    RaceCode["NIGHTMUTE"] = "1942-2";
    RaceCode["NIKOLAI"] = "1784-8";
    RaceCode["NIKOLSKI"] = "2017-2";
    RaceCode["NINILCHIK"] = "1785-5";
    RaceCode["NIPMUC"] = "1241-9";
    RaceCode["NISHINAM"] = "1346-6";
    RaceCode["NISQUALLY"] = "1523-0";
    RaceCode["NOATAK"] = "1872-1";
    RaceCode["NOMALAKI"] = "1389-6";
    RaceCode["NOME"] = "1873-9";
    RaceCode["NONDALTON"] = "1786-3";
    RaceCode["NOOKSACK"] = "1524-8";
    RaceCode["NOORVIK"] = "1874-7";
    RaceCode["NORTHERN_ARAPAHO"] = "1022-3";
    RaceCode["NORTHERN_CHEROKEE"] = "1095-9";
    RaceCode["NORTHERN_CHEYENNE"] = "1103-1";
    RaceCode["NORTHERN_PAIUTE"] = "1429-0";
    RaceCode["NORTHERN_POMO"] = "1469-6";
    RaceCode["NORTHWAY"] = "1787-1";
    RaceCode["NORTHWEST_TRIBES"] = "1391-2";
    RaceCode["NUIQSUT"] = "1875-4";
    RaceCode["NULATO"] = "1788-9";
    RaceCode["NUNAPITCHUKV"] = "1943-0";
    RaceCode["OGLALA_SIOUX"] = "1622-0";
    RaceCode["OKINAWAN"] = "2043-8";
    RaceCode["OKLAHOMA_APACHE"] = "1016-5";
    RaceCode["OKLAHOMA_CADO"] = "1042-1";
    RaceCode["OKLAHOMA_CHOCTAW"] = "1160-1";
    RaceCode["OKLAHOMA_COMANCHE"] = "1176-7";
    RaceCode["OKLAHOMA_DELAWARE"] = "1218-7";
    RaceCode["OKLAHOMA_KICKAPOO"] = "1306-0";
    RaceCode["OKLAHOMA_KIOWA"] = "1310-2";
    RaceCode["OKLAHOMA_MIAMI"] = "1361-5";
    RaceCode["OKLAHOMA_OTTAWA"] = "1414-2";
    RaceCode["OKLAHOMA_PAWNEE"] = "1446-4";
    RaceCode["OKLAHOMA_PEORIA"] = "1451-4";
    RaceCode["OKLAHOMA_PONCA"] = "1476-1";
    RaceCode["OKLAHOMA_SAC_AND_FOX"] = "1554-5";
    RaceCode["OKLAHOMA_SEMINOLE"] = "1571-9";
    RaceCode["OLD_HARBOR"] = "1998-4";
    RaceCode["OMAHA"] = "1403-5";
    RaceCode["ONEIDA"] = "1288-0";
    RaceCode["ONONDAGA"] = "1289-8";
    RaceCode["ONTONAGON"] = "1140-3";
    RaceCode["OREGON_ATHABASKAN"] = "1405-0";
    RaceCode["OSAGE"] = "1407-6";
    RaceCode["OSCARVILLE"] = "1944-8";
    RaceCode["OTHER_PACIFIC_ISLANDER"] = "2500-7";
    RaceCode["OTHER_RACE"] = "2131-1";
    RaceCode["OTOE_MISSOURIA"] = "1409-2";
    RaceCode["OTTAWA"] = "1411-8";
    RaceCode["OUZINKIE"] = "1999-2";
    RaceCode["OWENS_VALLEY"] = "1430-8";
    RaceCode["PAIUTE"] = "1416-7";
    RaceCode["PAKISTANI"] = "2044-6";
    RaceCode["PALA"] = "1333-4";
    RaceCode["PALAUAN"] = "2091-7";
    RaceCode["PALESTINIAN"] = "2124-6";
    RaceCode["PAMUNKEY"] = "1439-9";
    RaceCode["PANAMINT"] = "1592-5";
    RaceCode["PAPUA_NEW_GUINEAN"] = "2102-2";
    RaceCode["PASCUA_YAQUI"] = "1713-7";
    RaceCode["PASSAMAQUODDY"] = "1441-5";
    RaceCode["PAUGUSSETT"] = "1242-7";
    RaceCode["PAULOFF_HARBOR"] = "2018-0";
    RaceCode["PAUMA"] = "1334-2";
    RaceCode["PAWNEE"] = "1445-6";
    RaceCode["PAYSON_APACHE"] = "1017-3";
    RaceCode["PECHANGA"] = "1335-9";
    RaceCode["PEDRO_BAY"] = "1789-7";
    RaceCode["PELICAN"] = "1828-3";
    RaceCode["PENOBSCOT"] = "1448-0";
    RaceCode["PEORIA"] = "1450-6";
    RaceCode["PEQUOT"] = "1453-0";
    RaceCode["PERRYVILLE"] = "1980-2";
    RaceCode["PETERSBURG"] = "1829-1";
    RaceCode["PICURIS"] = "1499-3";
    RaceCode["PILOT_POINT"] = "1981-0";
    RaceCode["PILOT_STATION"] = "1945-5";
    RaceCode["PIMA"] = "1456-3";
    RaceCode["PINE_RIDGE_SIOUX"] = "1623-8";
    RaceCode["PIPESTONE_SIOUX"] = "1624-6";
    RaceCode["PIRO"] = "1500-8";
    RaceCode["PISCATAWAY"] = "1460-5";
    RaceCode["PIT_RIVER"] = "1462-1";
    RaceCode["PITKAS_POINT"] = "1946-3";
    RaceCode["PLATINUM"] = "1947-1";
    RaceCode["PLEASANT_POINT_PASSAMAQUODDY"] = "1443-1";
    RaceCode["POARCH_BAND"] = "1201-3";
    RaceCode["POCOMOKE_ACOHONOCK"] = "1243-5";
    RaceCode["POHNPEIAN"] = "2094-1";
    RaceCode["POINT_HOPE"] = "1876-2";
    RaceCode["POINT_LAY"] = "1877-0";
    RaceCode["POJOAQUE"] = "1501-6";
    RaceCode["POKAGON_POTAWATOMI"] = "1483-7";
    RaceCode["POLISH"] = "2115-4";
    RaceCode["POLYNESIAN"] = "2078-4";
    RaceCode["POMO"] = "1464-7";
    RaceCode["PONCA"] = "1474-6";
    RaceCode["POOSPATUCK"] = "1328-4";
    RaceCode["PORT_GAMBLE_KLALLAM"] = "1315-1";
    RaceCode["PORT_GRAHAM"] = "1988-5";
    RaceCode["PORT_HEIDEN"] = "1982-8";
    RaceCode["PORT_LIONS"] = "2000-8";
    RaceCode["PORT_MADISON"] = "1525-5";
    RaceCode["PORTAGE_CREEK"] = "1948-9";
    RaceCode["POTAWATOMI"] = "1478-7";
    RaceCode["POWHATAN"] = "1487-8";
    RaceCode["PRAIRIE_BAND"] = "1484-5";
    RaceCode["PRAIRIE_ISLAND_SIOUX"] = "1625-3";
    RaceCode["PRINCIPAL_CREEK_INDIAN_NATION"] = "1202-1";
    RaceCode["PRIOR_LAKE_SIOUX"] = "1626-1";
    RaceCode["PUEBLO"] = "1489-4";
    RaceCode["PUGET_SOUND_SALISH"] = "1518-0";
    RaceCode["PUYALLUP"] = "1526-3";
    RaceCode["PYRAMID_LAKE"] = "1431-6";
    RaceCode["QAGAN_TOYAGUNGIN"] = "2019-8";
    RaceCode["QAWALANGIN"] = "2020-6";
    RaceCode["QUAPAW"] = "1541-2";
    RaceCode["QUECHAN"] = "1730-1";
    RaceCode["QUILEUTE"] = "1084-3";
    RaceCode["QUINAULT"] = "1543-8";
    RaceCode["QUINHAGAK"] = "1949-7";
    RaceCode["RAMAH_NAVAJO"] = "1385-4";
    RaceCode["RAMPART"] = "1790-5";
    RaceCode["RAMPOUGH_MOUNTAIN"] = "1219-5";
    RaceCode["RAPPAHANNOCK"] = "1545-3";
    RaceCode["RED_CLIFF_CHIPPEWA"] = "1141-1";
    RaceCode["RED_DEVIL"] = "1950-5";
    RaceCode["RED_LAKE_CHIPPEWA"] = "1142-9";
    RaceCode["RED_WOOD"] = "1061-1";
    RaceCode["RENO_SPARKS"] = "1547-9";
    RaceCode["ROCKY_BOYS_CHIPPEWA_CREE"] = "1151-0";
    RaceCode["ROSEBUD_SIOUX"] = "1627-9";
    RaceCode["ROUND_VALLEY"] = "1549-5";
    RaceCode["RUBY"] = "1791-3";
    RaceCode["RUBY_VALLEY"] = "1593-3";
    RaceCode["SAC_AND_FOX"] = "1551-1";
    RaceCode["SAGINAW_CHIPPEWA"] = "1143-7";
    RaceCode["SAIPANESE"] = "2095-8";
    RaceCode["SALAMATOF"] = "1792-1";
    RaceCode["SALINAN"] = "1556-0";
    RaceCode["SALISH"] = "1558-6";
    RaceCode["SALISH_AND_KOOTENAI"] = "1560-2";
    RaceCode["SALT_RIVER_PIMA_MARICOPA"] = "1458-9";
    RaceCode["SAMISH"] = "1527-1";
    RaceCode["SAMOAN"] = "2080-0";
    RaceCode["SAN_CARLOS_APACHE"] = "1018-1";
    RaceCode["SAN_FELIPE"] = "1502-4";
    RaceCode["SAN_ILDEFONSO"] = "1503-2";
    RaceCode["SAN_JUAN"] = "1506-5";
    RaceCode["SAN_JUAN_DE"] = "1505-7";
    RaceCode["SAN_JUAN_PUEBLO"] = "1504-0";
    RaceCode["SAN_JUAN_SOUTHERN_PAIUTE"] = "1432-4";
    RaceCode["SAN_MANUAL"] = "1574-3";
    RaceCode["SAN_PASQUAL"] = "1229-4";
    RaceCode["SAN_XAVIER"] = "1656-8";
    RaceCode["SAND_HILL"] = "1220-3";
    RaceCode["SAND_POINT"] = "2023-0";
    RaceCode["SANDIA"] = "1507-3";
    RaceCode["SANS_ARC_SIOUX"] = "1628-7";
    RaceCode["SANTA_ANA"] = "1508-1";
    RaceCode["SANTA_CLARA"] = "1509-9";
    RaceCode["SANTA_ROSA"] = "1062-9";
    RaceCode["SANTA_ROSA_CAHUILLA"] = "1050-4";
    RaceCode["SANTA_YNEZ"] = "1163-5";
    RaceCode["SANTA_YSABEL"] = "1230-2";
    RaceCode["SANTEE_SIOUX"] = "1629-5";
    RaceCode["SANTO_DOMINGO"] = "1510-7";
    RaceCode["SAUK_SUIATTLE"] = "1528-9";
    RaceCode["SAULT_STE_MARIE_CHIPPEWA"] = "1145-2";
    RaceCode["SAVOONGA"] = "1893-7";
    RaceCode["SAXMAN"] = "1830-9";
    RaceCode["SCAMMON_BAY"] = "1952-1";
    RaceCode["SCHAGHTICOKE"] = "1562-8";
    RaceCode["SCOTT_VALLEY"] = "1564-4";
    RaceCode["SCOTTISH"] = "2116-2";
    RaceCode["SCOTTS_VALLEY"] = "1470-4";
    RaceCode["SELAWIK"] = "1878-8";
    RaceCode["SELDOVIA"] = "1793-9";
    RaceCode["SELLS"] = "1657-6";
    RaceCode["SEMINOLE"] = "1566-9";
    RaceCode["SENECA"] = "1290-6";
    RaceCode["SENECA_NATION"] = "1291-4";
    RaceCode["SENECA_CAYUGA"] = "1292-2";
    RaceCode["SERRANO"] = "1573-5";
    RaceCode["SETAUKET"] = "1329-2";
    RaceCode["SHAGELUK"] = "1795-4";
    RaceCode["SHAKTOOLIK"] = "1879-6";
    RaceCode["SHASTA"] = "1576-8";
    RaceCode["SHAWNEE"] = "1578-4";
    RaceCode["SHELDONS_POINT"] = "1953-9";
    RaceCode["SHINNECOCK"] = "1582-6";
    RaceCode["SHISHMAREF"] = "1880-4";
    RaceCode["SHOALWATER_BAY"] = "1584-2";
    RaceCode["SHOSHONE"] = "1586-7";
    RaceCode["SHOSHONE_PAIUTE"] = "1602-2";
    RaceCode["SHUNGNAK"] = "1881-2";
    RaceCode["SIBERIAN_ESKIMO"] = "1891-1";
    RaceCode["SIBERIAN_YUPIK"] = "1894-5";
    RaceCode["SILETZ"] = "1607-1";
    RaceCode["SINGAPOREAN"] = "2051-1";
    RaceCode["SIOUX"] = "1609-7";
    RaceCode["SISSETON_SIOUX"] = "1631-1";
    RaceCode["SISSETON_WAHPETON"] = "1630-3";
    RaceCode["SITKA"] = "1831-7";
    RaceCode["SIUSLAW"] = "1643-6";
    RaceCode["SKOKOMISH"] = "1529-7";
    RaceCode["SKULL_VALLEY"] = "1594-1";
    RaceCode["SKYKOMISH"] = "1530-5";
    RaceCode["SLANA"] = "1794-7";
    RaceCode["SLEETMUTE"] = "1954-7";
    RaceCode["SNOHOMISH"] = "1531-3";
    RaceCode["SNOQUALMIE"] = "1532-1";
    RaceCode["SOBOBA"] = "1336-7";
    RaceCode["SOKOAGON_CHIPPEWA"] = "1146-0";
    RaceCode["SOLOMON"] = "1882-0";
    RaceCode["SOLOMON_ISLANDER"] = "2103-0";
    RaceCode["SOUTH_AMERICAN_INDIAN"] = "1073-6";
    RaceCode["SOUTH_FORK_SHOSHONE"] = "1595-8";
    RaceCode["SOUTH_NAKNEK"] = "2024-8";
    RaceCode["SOUTHEAST_ALASKA"] = "1811-9";
    RaceCode["SOUTHEASTERN_INDIANS"] = "1244-3";
    RaceCode["SOUTHERN_ARAPAHO"] = "1023-1";
    RaceCode["SOUTHERN_CHEYENNE"] = "1104-9";
    RaceCode["SOUTHERN_PAIUTE"] = "1433-2";
    RaceCode["SPANISH_AMERICAN_INDIAN"] = "1074-4";
    RaceCode["SPIRIT_LAKE_SIOUX"] = "1632-9";
    RaceCode["SPOKANE"] = "1645-1";
    RaceCode["SQUAXIN_ISLAND"] = "1533-9";
    RaceCode["SRI_LANKAN"] = "2045-3";
    RaceCode["ST_CROIX_CHIPPEWA"] = "1144-5";
    RaceCode["ST_GEORGE"] = "2021-4";
    RaceCode["ST_MARYS"] = "1963-8";
    RaceCode["ST_MICHAEL"] = "1951-3";
    RaceCode["ST_PAUL"] = "2022-2";
    RaceCode["STANDING_ROCK_SIOUX"] = "1633-7";
    RaceCode["STAR_CLAN_OF_MUSCOGEE_CREEKS"] = "1203-9";
    RaceCode["STEBBINS"] = "1955-4";
    RaceCode["STEILACOOM"] = "1534-7";
    RaceCode["STEVENS"] = "1796-2";
    RaceCode["STEWART"] = "1647-7";
    RaceCode["STILLAGUAMISH"] = "1535-4";
    RaceCode["STOCKBRIDGE"] = "1649-3";
    RaceCode["STONY_RIVER"] = "1797-0";
    RaceCode["STONYFORD"] = "1471-2";
    RaceCode["SUGPIAQ"] = "2002-4";
    RaceCode["SULPHUR_BANK"] = "1472-0";
    RaceCode["SUMMIT_LAKE"] = "1434-0";
    RaceCode["SUQPIGAQ"] = "2004-0";
    RaceCode["SUQUAMISH"] = "1536-2";
    RaceCode["SUSANVILLE"] = "1651-9";
    RaceCode["SUSQUEHANOCK"] = "1245-0";
    RaceCode["SWINOMISH"] = "1537-0";
    RaceCode["SYCUAN"] = "1231-0";
    RaceCode["SYRIAN"] = "2125-3";
    RaceCode["TABLE_BLUFF"] = "1705-3";
    RaceCode["TACHI"] = "1719-4";
    RaceCode["TAHITIAN"] = "2081-8";
    RaceCode["TAIWANESE"] = "2035-4";
    RaceCode["TAKELMA"] = "1063-7";
    RaceCode["TAKOTNA"] = "1798-8";
    RaceCode["TALAKAMISH"] = "1397-9";
    RaceCode["TANACROSS"] = "1799-6";
    RaceCode["TANAINA"] = "1800-2";
    RaceCode["TANANA"] = "1801-0";
    RaceCode["TANANA_CHIEFS"] = "1802-8";
    RaceCode["TAOS"] = "1511-5";
    RaceCode["TATITLEK"] = "1969-5";
    RaceCode["TAZLINA"] = "1803-6";
    RaceCode["TE_MOAK_WESTERN_SHOSHONE"] = "1596-6";
    RaceCode["TELIDA"] = "1804-4";
    RaceCode["TELLER"] = "1883-8";
    RaceCode["TEMECULA"] = "1338-3";
    RaceCode["TENAKEE_SPRINGS"] = "1832-5";
    RaceCode["TENINO"] = "1398-7";
    RaceCode["TESUQUE"] = "1512-3";
    RaceCode["TETLIN"] = "1805-1";
    RaceCode["TETON_SIOUX"] = "1634-5";
    RaceCode["TEWA"] = "1513-1";
    RaceCode["TEXAS_KICKAPOO"] = "1307-8";
    RaceCode["THAI"] = "2046-1";
    RaceCode["THLOPTHLOCCO"] = "1204-7";
    RaceCode["TIGUA"] = "1514-9";
    RaceCode["TILLAMOOK"] = "1399-5";
    RaceCode["TIMBI_SHA_SHOSHONE"] = "1597-4";
    RaceCode["TLINGIT"] = "1833-3";
    RaceCode["TLINGIT_HAIDA"] = "1813-5";
    RaceCode["TOBAGOAN"] = "2073-5";
    RaceCode["TOGIAK"] = "1956-2";
    RaceCode["TOHONO_OODHAM"] = "1653-5";
    RaceCode["TOK"] = "1806-9";
    RaceCode["TOKELAUAN"] = "2083-4";
    RaceCode["TOKSOOK"] = "1957-0";
    RaceCode["TOLOWA"] = "1659-2";
    RaceCode["TONAWANDA_SENECA"] = "1293-0";
    RaceCode["TONGAN"] = "2082-6";
    RaceCode["TONKAWA"] = "1661-8";
    RaceCode["TORRES_MARTINEZ"] = "1051-2";
    RaceCode["TRINIDADIAN"] = "2074-3";
    RaceCode["TRINITY"] = "1272-4";
    RaceCode["TSIMSHIAN"] = "1837-4";
    RaceCode["TUCKABACHEE"] = "1205-4";
    RaceCode["TULALIP"] = "1538-8";
    RaceCode["TULE_RIVER"] = "1720-2";
    RaceCode["TULUKSKAK"] = "1958-8";
    RaceCode["TUNICA_BILOXI"] = "1246-8";
    RaceCode["TUNTUTULIAK"] = "1959-6";
    RaceCode["TUNUNAK"] = "1960-4";
    RaceCode["TURTLE_MOUNTAIN"] = "1147-8";
    RaceCode["TUSCARORA"] = "1294-8";
    RaceCode["TUSCOLA"] = "1096-7";
    RaceCode["TWENTY_NINE_PALMS"] = "1337-5";
    RaceCode["TWIN_HILLS"] = "1961-2";
    RaceCode["TWO_KETTLE_SIOUX"] = "1635-2";
    RaceCode["TYGH"] = "1663-4";
    RaceCode["TYONEK"] = "1807-7";
    RaceCode["UGASHIK"] = "1970-3";
    RaceCode["UINTAH_UTE"] = "1672-5";
    RaceCode["UMATILLA"] = "1665-9";
    RaceCode["UMKUMIATE"] = "1964-6";
    RaceCode["UMPQUA"] = "1667-5";
    RaceCode["UNALAKLEET"] = "1884-6";
    RaceCode["UNALASKA"] = "2025-5";
    RaceCode["UNANGAN_ALEUT"] = "2006-5";
    RaceCode["UNGA"] = "2026-3";
    RaceCode["UNITED_KEETOWAH_BAND_OF_CHEROKEE"] = "1097-5";
    RaceCode["UNREPORTED_REFUSED_TO_REPORT"] = "2131-4";
    RaceCode["UPPER_CHINOOK"] = "1118-9";
    RaceCode["UPPER_SIOUX"] = "1636-0";
    RaceCode["UPPER_SKAGIT"] = "1539-6";
    RaceCode["UTE"] = "1670-9";
    RaceCode["UTE_MOUNTAIN_UTE"] = "1673-3";
    RaceCode["UTU_UTU_GWAITU_PAIUTE"] = "1435-7";
    RaceCode["VENETIE"] = "1808-5";
    RaceCode["VIETNAMESE"] = "2047-9";
    RaceCode["WACCAMAW_SIOUSAN"] = "1247-6";
    RaceCode["WAHPEKUTE_SIOUX"] = "1637-8";
    RaceCode["WAHPETON_SIOUX"] = "1638-6";
    RaceCode["WAILAKI"] = "1675-8";
    RaceCode["WAINWRIGHT"] = "1885-3";
    RaceCode["WAKIAKUM_CHINOOK"] = "1119-7";
    RaceCode["WALES"] = "1886-1";
    RaceCode["WALKER_RIVER"] = "1436-5";
    RaceCode["WALLA_WALLA"] = "1677-4";
    RaceCode["WAMPANOAG"] = "1679-0";
    RaceCode["WAPPO"] = "1064-5";
    RaceCode["WARM_SPRINGS"] = "1683-2";
    RaceCode["WASCOPUM"] = "1685-7";
    RaceCode["WASHAKIE"] = "1598-2";
    RaceCode["WASHOE"] = "1687-3";
    RaceCode["WAZHAZA_SIOUX"] = "1639-4";
    RaceCode["WENATCHEE"] = "1400-1";
    RaceCode["WEST_INDIAN"] = "2075-0";
    RaceCode["WESTERN_CHEROKEE"] = "1098-3";
    RaceCode["WESTERN_CHICKAHOMINY"] = "1110-6";
    RaceCode["WHILKUT"] = "1273-2";
    RaceCode["WHITE"] = "2106-3";
    RaceCode["WHITE_EARTH"] = "1148-6";
    RaceCode["WHITE_MOUNTAIN"] = "1887-9";
    RaceCode["WHITE_MOUNTAIN_APACHE"] = "1019-9";
    RaceCode["WHITE_MOUNTAIN_INUPIAT"] = "1888-7";
    RaceCode["WICHITA"] = "1692-3";
    RaceCode["WICOMICO"] = "1248-4";
    RaceCode["WILLAPA_CHINOOK"] = "1120-5";
    RaceCode["WIND_RIVER"] = "1694-9";
    RaceCode["WIND_RIVER_ARAPAHO"] = "1024-9";
    RaceCode["WIND_RIVER_SHOSHONE"] = "1599-0";
    RaceCode["WINNEBAGO"] = "1696-4";
    RaceCode["WINNEMUCCA"] = "1700-4";
    RaceCode["WINTUN"] = "1702-0";
    RaceCode["WISCONSIN_POTAWATOMI"] = "1485-2";
    RaceCode["WISEMAN"] = "1809-3";
    RaceCode["WISHRAM"] = "1121-3";
    RaceCode["WIYOT"] = "1704-6";
    RaceCode["WRANGELL"] = "1834-1";
    RaceCode["WYANDOTTE"] = "1295-5";
    RaceCode["YAHOOSKIN"] = "1401-9";
    RaceCode["YAKAMA"] = "1707-9";
    RaceCode["YAKAMA_COWLITZ"] = "1709-5";
    RaceCode["YAKUTAT"] = "1835-8";
    RaceCode["YANA"] = "1065-2";
    RaceCode["YANKTON_SIOUX"] = "1640-2";
    RaceCode["YANKTONAI_SIOUX"] = "1641-0";
    RaceCode["YAPESE"] = "2098-2";
    RaceCode["YAQUI"] = "1711-1";
    RaceCode["YAVAPAI"] = "1731-9";
    RaceCode["YAVAPAI_APACHE"] = "1715-2";
    RaceCode["YERINGTON_PAIUTE"] = "1437-3";
    RaceCode["YOKUTS"] = "1717-8";
    RaceCode["YOMBA"] = "1600-6";
    RaceCode["YUCHI"] = "1722-8";
    RaceCode["YUKI"] = "1066-0";
    RaceCode["YUMAN"] = "1724-4";
    RaceCode["YUPIK_ESKIMO"] = "1896-0";
    RaceCode["YUROK"] = "1732-7";
    RaceCode["ZAIREAN"] = "2066-9";
    RaceCode["ZIA"] = "1515-6";
    RaceCode["ZUNI"] = "1516-4";
})(RaceCode = exports.RaceCode || (exports.RaceCode = {}));
