import { ensomataApi } from 'api';
import { Box, Button, ListContainer, Page } from 'components';
import { Outlet, useNavigate } from 'react-router-dom';
import { CDSSRuleListDto } from 'types';
import { newRulePageRoute } from '../RulePage/NewRule';
import { RuleListPageTable } from './RuleListPageTable';
import { PageRoute } from 'models';
import { buildPath, buildTemplatePath } from 'services';

const routeName = 'rules';
export const ruleListPageRoute: PageRoute = {
  handle: 'Rules',
  templatePath: buildTemplatePath(routeName),
  getPath: () => buildPath(routeName),
};

export const RuleListPage = () => {
  const navigate = useNavigate();

  const { data: rulesResponse } = ensomataApi.useGetRulesQuery({});
  const rules: CDSSRuleListDto[] | undefined = rulesResponse?.rules;

  return (
    <Page
      header={
        <>
          <Box>{ruleListPageRoute.handle}</Box>
          <Button
            variant="outlined"
            onClick={() => navigate({ pathname: newRulePageRoute.getPath() })}
          >
            + Add Rule
          </Button>
        </>
      }
    >
      <ListContainer tableContainer={<RuleListPageTable rules={rules} />} />

      <Outlet />
    </Page>
  );
};
