import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps, FormControlLabel } from "@mui/material";
import { SxProps, Theme, useTheme } from "@mui/material";
import { JSXElementConstructor, ReactElement } from "react";

type CheckboxProps = MuiCheckboxProps & {
  label?: string | number | ReactElement<any, string | JSXElementConstructor<any>>;
};

export function Checkbox({ className, sx, label, checked, ...rest }: CheckboxProps) {
  const theme = useTheme();
  const rootStyle: SxProps<Theme> = {
    fontWeight: theme.typography.fontWeightBold,
  };

  const muiCheckbox = <MuiCheckbox sx={rootStyle} checked={checked || false} {...rest} />;
  return label ? <FormControlLabel control={muiCheckbox} label={label} /> : muiCheckbox;
}
