import { MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { Select } from 'components/mui';
import { NoTestWithinOperand, OperandValues, RecommendationType } from 'types';
import { OperandProps } from './constants/OperandProps';
import NumberField from '../Components/NumberInput';

const periodTypes: (keyof NoTestWithinOperand['duration'])[] = [
  'days',
  'weeks',
  'months',
  'years',
];

const testTypes: RecommendationType[] = Object.values(RecommendationType);

export default function NoTestWithin({
  operand,
  updateOperand,
}: OperandProps<NoTestWithinOperand>) {
  // check for "value" so that ts doesn't complain about accessing "value" on an operand without value
  const isPrev = operand?.operand === OperandValues.noTestWithin;
  if (!isPrev) return <></>;

  const handleLabItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateOperand({ ...operand, orderableLabItemId: e.target.value });
  };

  const handlePeriodTypeChange = (e: SelectChangeEvent<any>) => {
    const val = e.target.value as (typeof periodTypes)[0];
    const duration = {
      [val]: 1,
    };
    updateOperand({ ...operand, duration });
  };

  const handleValueChange = (value: number) => {
    const key = Object.keys(operand.duration)?.[0];
    const duration = {
      [key]: value,
    };
    updateOperand({ ...operand, duration });
  };

  const handleTestTypeChange = (e: SelectChangeEvent<any>) => {
    updateOperand({ ...operand, testType: e.target.value as RecommendationType });
  };

  return (
    <>
      <div></div>
      <div></div>
      <div style={{ display: 'flex', gridColumn: 'span 3', gap: 4, marginLeft: '10%' }}>
        <div style={{ width: '25%' }}>
          <TextField
            label="Orderable Lab Item ID"
            onChange={handleLabItemChange}
            value={operand.orderableLabItemId}
          />
        </div>

        <div style={{ width: '25%' }}>
          <Select
            value={Object.keys(operand.duration)?.[0]}
            label="Period"
            onChange={handlePeriodTypeChange}
          >
            {periodTypes.map((p) => (
              <MenuItem key={p} value={p}>
                {p}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div style={{ width: '25%' }}>
          <NumberField
            value={Object.values(operand.duration)?.find((o) => !!o) || 0}
            label={Object.keys(operand.duration)[0]}
            changeHandler={handleValueChange}
            type="number"
          />
        </div>

        <div style={{ width: '25%', display: 'flex', justifyContent: 'center' }}>
          <Select
            label="Test Type"
            onChange={handleTestTypeChange}
            value={operand.testType}
          >
            {testTypes.map((o, i) => (
              <MenuItem value={o} key={i}>
                {o}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </>
  );
}
