import './App.css';
import { AppRoutes } from 'routes';
import { store } from 'redux/store';
import { theme } from 'theme';
import './auth'; // Configure Amplify
import { Authenticator } from '@aws-amplify/ui-react'; // Configure Amplify
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { Toaster } from 'react-hot-toast';
import { registerChartJS } from 'setups/registerChartJS';

registerChartJS();
export default function App() {
  return (
    <Authenticator.Provider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Toaster position="top-right" />
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </Authenticator.Provider>
  );
}
