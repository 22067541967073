import { createSlice } from '@reduxjs/toolkit';
import { Toast } from 'types';

interface AppState {
  toast?: Toast;
  loading: number;
  navDrawerOpen?: boolean;
  navPinned: boolean;
}

const initialState: AppState = {
  toast: undefined,
  loading: 0,
  navDrawerOpen: false,
  navPinned: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppLoading: (
      state,
      action: { type: string; payload: typeof initialState.loading }
    ) => {
      state.loading = action.payload;
    },
    setAppToast: (
      state,
      action: { type: string; payload: typeof initialState.toast }
    ) => {
      state.toast = action.payload;
    },
    setAppNavDrawerOpen: (
      state,
      action: { type: string; payload: typeof initialState.navDrawerOpen }
    ) => {
      state.navDrawerOpen = action.payload;
    },
    setAppNavPinned: (
      state,
      action: { type: string; payload: typeof initialState.navPinned }
    ) => {
      state.navPinned = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAppToast, setAppLoading, setAppNavDrawerOpen, setAppNavPinned } =
  appSlice.actions;

export default appSlice.reducer;
