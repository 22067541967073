import { Card, Stack } from 'components/mui';
import { CDSSRuleListDto, Operand } from 'types';
import OperandBuilder from './OperandBuilder';
import RuleFieldsContainer from './Components/RuleFieldsContainer';
import Recommendation from './Recommendation';
import Header from './Components/Header';

interface Props {
  rule: CDSSRuleListDto;
  updateRule: (l: CDSSRuleListDto) => void;
}

export default function RuleBuilder({ rule, updateRule }: Props) {
  const updateOperand = (operand: Operand) => {
    updateRule({ ...rule, rules: operand });
  };

  return (
    <Card>
      <form>
        <Stack spacing={'md'}>
          <Header {...{ rule }} />
          <RuleFieldsContainer {...{ rule, updateRule }} />
          <Recommendation {...{ rule, updateRule }} />
          <OperandBuilder {...{ updateOperand, rule }} />
        </Stack>
      </form>
    </Card>
  );
}
