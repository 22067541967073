import { Button, Form, Grid, TextField } from "components";
import produce from "immer";
import * as React from "react";
import { SelfServiceFilter } from "types";

type SelfServicePageFiltersProps = {
  filter: SelfServiceFilter;
  setFilter: (value: React.SetStateAction<SelfServiceFilter>) => void;
  setAppliedFilter: (value: React.SetStateAction<SelfServiceFilter>) => void;
};

export const SelfServicePageFilters = ({ filter, setFilter, setAppliedFilter }: SelfServicePageFiltersProps) => {
  return (
    <Form>
      <Grid container spacing="sm">
        <Grid item xs={3}>
          <TextField
            size="small"
            label="Patient ID"
            value={filter.patientId}
            onChange={(e) =>
              setFilter(
                produce((draft) => {
                  draft.patientId = e.target.value as unknown as number;
                })
              )
            }
            type="number"
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            size="small"
            label="Days"
            value={filter.days}
            onChange={(e) =>
              setFilter(
                produce((draft) => {
                  draft.days = e.target.value as unknown as number;
                })
              )
            }
            type="number"
          />
        </Grid>

        <Grid item xs={3}>
          <Button
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              setAppliedFilter(filter);
            }}
            type="submit"
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};
