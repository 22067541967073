"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecommendationType = void 0;
var RecommendationType;
(function (RecommendationType) {
    RecommendationType["IMAGING"] = "IMAGING";
    RecommendationType["IMMUNIZATION"] = "IMMUNIZATION";
    RecommendationType["INJECTION"] = "INJECTION";
    RecommendationType["LAB"] = "LAB";
    RecommendationType["PROCEDURE"] = "PROCEDURE";
})(RecommendationType = exports.RecommendationType || (exports.RecommendationType = {}));
