import { Autocomplete, MenuItem, Select, TextField } from 'components/mui';
import { OperandValues, RaceOperand } from 'types';
import { OperandProps } from './constants/OperandProps';
import { ensomataApi } from 'api';
import { SelectChangeEvent } from '@mui/material';

const operators: RaceOperand['operator'][] = ['=', '!'];

export default function Race({ operand, updateOperand }: OperandProps<RaceOperand>) {
  const { data, isLoading } = ensomataApi.useGetRaceCodesQuery({});
  // check for "value" so that ts doesn't complain about accessing "value" on an operand without value
  const isRace = operand?.operand === OperandValues.race;
  if (!isRace) return <></>;

  const handleOperandChange = (e: SelectChangeEvent<any>) => {
    updateOperand({ ...operand, operator: e.target.value as (typeof operators)[0] });
  };

  return (
    <>
      <Select onChange={handleOperandChange} value={operand.operator}>
        {operators.map((o, i) => (
          <MenuItem value={o} key={i}>
            {o}
          </MenuItem>
        ))}
      </Select>
      <Autocomplete
        onChange={(_, value) => {
          updateOperand({ ...operand, value: value?.value ?? '' });
        }}
        value={data?.find((d) => d.value === operand?.value)}
        disabled={isLoading}
        options={data || []}
        renderInput={(params) => (
          <TextField {...params} label="Primary Race" size="small" />
        )}
      />
    </>
  );
}
