import {
  Card,
  Checkbox,
  DataDisplay,
  Grid,
  MenuItem,
  PageMode,
  Select,
  TextField,
} from 'components';
import produce from 'immer';
import { formatBoolean } from 'services';
import { Developer } from 'types';

type DeveloperDemographicsProps = {
  pageMode: PageMode;
  developer: Developer;
  setDeveloper: (value: React.SetStateAction<Developer>) => void;
};

export const DeveloperDemographics = ({
  pageMode,
  developer,
  setDeveloper,
}: DeveloperDemographicsProps) => {
  return (
    <Card>
      <Grid container spacing="sm">
        <Grid item md={6} xs={12}>
          {pageMode === 'View' && (
            <DataDisplay label="First Name">{developer.firstName}</DataDisplay>
          )}
          {(pageMode === 'Add' || pageMode === 'Edit') && (
            <TextField
              label="First Name"
              required
              value={developer.firstName}
              onChange={(e) =>
                setDeveloper(
                  produce((draft) => {
                    draft.firstName = e.target.value;
                  })
                )
              }
            />
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          {pageMode === 'View' && (
            <DataDisplay label="Last Name">{developer.lastName}</DataDisplay>
          )}
          {(pageMode === 'Add' || pageMode === 'Edit') && (
            <TextField
              label="First Name"
              required
              value={developer.lastName}
              onChange={(e) =>
                setDeveloper(
                  produce((draft) => {
                    draft.lastName = e.target.value;
                  })
                )
              }
            />
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          {pageMode === 'View' && (
            <DataDisplay label="Gender">{developer.genderName}</DataDisplay>
          )}
          {(pageMode === 'Add' || pageMode === 'Edit') && (
            <Select
              label="Gender"
              required
              value={developer.genderId}
              onChange={(e) =>
                setDeveloper(
                  produce((draft) => {
                    draft.genderId = Number(e.target.value);
                  })
                )
              }
            >
              <MenuItem key={1} value={1}>
                Male
              </MenuItem>
              <MenuItem key={2} value={2}>
                Female
              </MenuItem>
            </Select>
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          {pageMode === 'View' && (
            <DataDisplay label="Occupation">{developer.occupationName}</DataDisplay>
          )}
          {(pageMode === 'Add' || pageMode === 'Edit') && (
            <Select
              label="Occupation"
              value={developer.occupationId}
              onChange={(e) =>
                setDeveloper(
                  produce((draft) => {
                    draft.occupationId = e.target.value
                      ? Number(e.target.value)
                      : undefined;
                  })
                )
              }
            >
              <MenuItem value={''}>None</MenuItem>
              <MenuItem key={1} value={1}>
                Engineer
              </MenuItem>
              <MenuItem key={2} value={2}>
                Pimp
              </MenuItem>
            </Select>
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          {pageMode === 'View' && (
            <DataDisplay label="Married">{formatBoolean(developer.married)}</DataDisplay>
          )}
          {(pageMode === 'Add' || pageMode === 'Edit') && (
            <Checkbox
              label="Married"
              checked={developer.married}
              onChange={(e) =>
                setDeveloper(
                  produce((draft) => {
                    draft.married = e.target.checked;
                  })
                )
              }
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
};
