import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export function Grid(theme: Theme) {
  return {
    MuiGrid: {
      styleOverrides: {
        item: {
          paddingRight: '2px', // this fixes the children's Card/Paper's right shadow being cut off when Card's height is 100%
          paddingBottom: '2px', // this fixes the children's Card/Paper's bottom shadow being cut off when Card's height is 100%
        },
      },
    },
  };
}
