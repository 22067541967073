import { Theme } from '@mui/material/styles';
import { Table, Grid, Paper, Card, Button } from '../overrideComponents';

export const overrideComponents = (theme: Theme) => {
  return Object.assign(
    Card(theme),
    Grid(theme),
    Paper(theme),
    Table(theme),
    Button(theme)
  );
};
