import AutorenewIcon from '@mui/icons-material/Autorenew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonGroup,
  CircularProgress,
  Modal,
  Typography,
} from '@mui/material';
import { Box, Button } from 'components';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { setAppToast } from 'redux/appSlice';
import { newToast } from 'services';
import { getSpacing } from 'theme';
import { SelfServiceFilter } from 'types';

type SelfServicePageRecommendationRequestsProps = {
  requestsFetching: boolean;
  recommendationRequests: any[] | undefined;
  appliedFilter: SelfServiceFilter;
  setSelectedPayload: (value: any) => void;
  selectedPayload: any;
  selectedPayloadResponse: any[] | undefined;
};

const jsonStyle = {
  maxHeight: '300px',
  overflowY: 'scroll',
};

export const SelfServicePageRecommendationRequests = ({
  requestsFetching,
  recommendationRequests,
  appliedFilter,
  setSelectedPayload,
  selectedPayload,
  selectedPayloadResponse,
}: SelfServicePageRecommendationRequestsProps) => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(e);
    dispatch(setAppToast(newToast('success', 'Copied to clipboard')));
  };

  const handleOpen = (x) => {
    setSelectedPayload(x.params);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPayload(null);
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Box py={getSpacing('md')} key="recommendationRequests">
        {requestsFetching && <CircularProgress />}
        {!requestsFetching && recommendationRequests?.length === 0 && (
          <Box>
            No request payloads matching {appliedFilter.patientId} in the last{' '}
            {appliedFilter.days} days.
          </Box>
        )}
        {!requestsFetching &&
          recommendationRequests?.map((x) => {
            return (
              <Accordion key={x.id}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={x.id + '-content'}
                  id={x.id}
                >
                  <Typography>
                    {x.date} - {x.id}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      size="small"
                      startIcon={<ContentCopyIcon />}
                      onClick={() => {
                        copyToClipboard(JSON.stringify(JSON.parse(x.params), null, 2));
                      }}
                    >
                      Copy
                    </Button>
                    <Button
                      size="small"
                      startIcon={<AutorenewIcon />}
                      onClick={() => {
                        handleOpen(x);
                      }}
                    >
                      Re-run
                    </Button>
                  </ButtonGroup>
                  <Typography variant="caption" display="block" sx={jsonStyle}>
                    <pre>{JSON.stringify(JSON.parse(x.params), null, 2)}</pre>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Re-running the payload {selectedPayload?.id}
          </Typography>
          {selectedPayloadResponse == null && (
            <Typography id="modal-modal-description" variant="caption" display="block">
              <CircularProgress />
            </Typography>
          )}
          {selectedPayloadResponse != null && (
            <Typography
              id="modal-modal-description"
              variant="caption"
              display="block"
              sx={jsonStyle}
            >
              <pre>{JSON.stringify(selectedPayloadResponse, null, 2)}</pre>
            </Typography>
          )}
        </Box>
      </Modal>
    </>
  );
};
