"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenderType = void 0;
var GenderType;
(function (GenderType) {
    GenderType["FEMALE"] = "FEMALE";
    GenderType["MALE"] = "MALE";
    GenderType["NON_BINARY"] = "NON_BINARY";
    GenderType["UNKNOWN"] = "UNKNOWN";
})(GenderType = exports.GenderType || (exports.GenderType = {}));
