import { CDSSRuleListDto, CreateCDSSRule } from 'types';
import { ensomataApi } from 'api';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ruleListPageRoute } from 'pages/rules/RuleListPage/RuleListPage';

export default function useUpsertRule() {
  const [update, updateStatus] = ensomataApi.useUpdateRuleMutation();
  const [create, createStatus] = ensomataApi.useCreateRuleMutation();
  const navigate = useNavigate();

  const handleUpdate = async (rule: CDSSRuleListDto) => {
    try {
      const r = { ...rule } as Partial<CDSSRuleListDto>;
      r.types = r.types || [];
      delete r.version;
      delete r.createdBy;
      delete r.datePosted;

      const res = await update(r);
      if ('error' in res) throw Error(res.error.message);
      navigate({ pathname: `/${ruleListPageRoute.getPath()}` });
      toast.success('Updated');
    } catch (e) {
      toast.error('Error updating rule');
      console.error(e);
    }
  };

  const handleCreate = async (rule: CDSSRuleListDto) => {
    const r = { ...rule } as Partial<CDSSRuleListDto>;
    r.types = r.types || [];
    delete r.id;
    delete r.version;
    delete r.datePosted;

    try {
      const res = await create(r as CreateCDSSRule);
      if ('error' in res) throw Error(res.error.message);
      navigate({ pathname: `/${ruleListPageRoute.getPath()}` });
      toast.success('Created');
    } catch (e) {
      toast.error('Error creating rule');
      console.error(e);
    }
  };

  const upsert = (rule: CDSSRuleListDto) => {
    const r = {
      ...rule,
      // rules needs to be a string for appsync
      rules: JSON.stringify(rule.rules),
    } as unknown as CDSSRuleListDto;
    if (r.id) {
      handleUpdate(r);
    } else {
      handleCreate(r);
    }
  };

  return {
    upsert,
    isLoading: updateStatus.isLoading || createStatus.isLoading,
  };
}
