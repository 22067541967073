import { Page, PageMode, Stack } from 'components';
import { useEffect, useState } from 'react';
import { Developer } from 'types';
import { DeveloperBasicInfo } from './DeveloperBasicInfo';
import { DeveloperDemographics } from './DeveloperDemographics';
import { DeveloperPageHeader } from './DeveloperPageHeader';
import { DeveloperTasks } from './DeveloperTasks';
import { PageRoute } from 'models';
import { buildPath, buildTemplatePath } from 'services';
import { useParams } from 'hooks';

const routeName = 'developer';
class Params {
  id: string = '';
  pageMode: PageMode = 'View';
}

export const developerPageRoute: PageRoute<Params> = {
  handle: 'Developer',
  templatePath: buildTemplatePath(routeName, Params),
  getPath: (params) => buildPath(routeName, params),
};

export const DeveloperPage = () => {
  const { params } = useParams(routeName, Params);
  const id = params.id;

  const [developer, setDeveloper] = useState<Developer>({} as Developer);
  const [pageMode, setPageMode] = useState<PageMode>(id ? 'View' : 'Add');

  useEffect(() => {
    const fetchDeveloper = () => {
      const developer: Developer = {
        id: 1,
        uuid: id,
        firstName: 'Bob',
        lastName: 'Johnson',
        genderId: 1,
        genderName: 'Male',
        occupationId: 1,
        occupationName: 'Engineer',
        married: false,
        tasks: [{ name: 'task1' }, { name: 'task2 ' }, { name: 'task3 ' }],
      };

      setDeveloper(developer);
    };
    fetchDeveloper();
  }, [id]);

  return (
    <Page
      header={<DeveloperPageHeader pageMode={pageMode} setPageMode={setPageMode} />}
      backButton
    >
      <Stack spacing="lg">
        <DeveloperBasicInfo id={id} />
        <DeveloperDemographics
          pageMode={pageMode}
          developer={developer}
          setDeveloper={setDeveloper}
        />
        <DeveloperTasks
          pageMode={pageMode}
          developer={developer}
          setDeveloper={setDeveloper}
        />
      </Stack>
    </Page>
  );
};
