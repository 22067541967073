"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DoSPropertyName = void 0;
// Maps to the DoS Property Name on the Patient.Marketing object
var DoSPropertyName;
(function (DoSPropertyName) {
    DoSPropertyName["aaaScreenDoS"] = "aaaScreenDoS";
    DoSPropertyName["abiDoS"] = "abiDoS";
    DoSPropertyName["aweDoS"] = "aweDoS";
    DoSPropertyName["carotidUltraSoundDoS"] = "carotidUltraSoundDoS";
    DoSPropertyName["chestXRayDoS"] = "chestXRayDoS";
    DoSPropertyName["cpeDoS"] = "cpeDoS";
    DoSPropertyName["dexaDoS"] = "dexaDoS";
    DoSPropertyName["echoDoS"] = "echoDoS";
    DoSPropertyName["ekgDoS"] = "ekgDoS";
    DoSPropertyName["eyeTelDoS"] = "eyeTelDoS";
    DoSPropertyName["fluFaccDoS"] = "fluFaccDoS";
    DoSPropertyName["glaucomaDoS"] = "glaucomaDoS";
    DoSPropertyName["hemoccultDoS"] = "hemoccultDoS";
    DoSPropertyName["hepPanelDoS"] = "hepPanelDoS";
    DoSPropertyName["liverUsDoS"] = "liverUsDoS";
    DoSPropertyName["lungScrnDoS"] = "lungScrnDoS";
    DoSPropertyName["mammogramDoS"] = "mammogramDoS";
    DoSPropertyName["microalbuminDOS"] = "microalbuminDOS";
    DoSPropertyName["pneumoniaVacDoS"] = "pneumoniaVacDoS";
    DoSPropertyName["sleepStudyDoS"] = "sleepStudyDoS";
    DoSPropertyName["spiroDoS"] = "spiroDoS";
    DoSPropertyName["stressTestDoS"] = "stressTestDoS";
    DoSPropertyName["testoPelDoS"] = "testoPelDoS";
    DoSPropertyName["thyroidUsDoS"] = "thyroidUsDoS";
    DoSPropertyName["wellChildDoS"] = "wellChildDoS";
})(DoSPropertyName = exports.DoSPropertyName || (exports.DoSPropertyName = {}));
