import { Box, Button, Card, Page, Skeleton, Stack } from 'components';
import { PageRoute } from 'models';
import { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { buildPath, buildTemplatePath } from 'services';
import BiReportApiService from 'bi-report/BiReportApiService';
import { MetricsReportPuck } from 'bi-report/BiReportApi';
import { getSpacing } from 'theme';
import { practiceMetricsDetailPageRoute } from '../PracticeMetricsDetailPage/PracticeMetricsDetailPage';
import { useParams } from 'hooks';

const routeName = 'practiceMetrics';

class Params {
  reportId!: number;
  facilityId!: number;
  badgeNumber!: string;
  reportDate!: string;
}

export const practiceMetricsPageRoute: PageRoute<Params> = {
  handle: 'Practice Metrics',
  templatePath: buildTemplatePath(routeName, Params),
  getPath: (params) => buildPath(routeName, params),
};

export const PracticeMetricsPage = () => {
  const { params } = useParams(routeName, Params);

  const reportId = params.reportId;
  const facilityId = params.facilityId;
  const badgeNumber = params.badgeNumber;
  const reportDate = params.reportDate;

  const navigate = useNavigate();
  const [reportPucks, setReportPucks] = useState<MetricsReportPuck[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = useCallback(() => {
    setLoading(true);
    BiReportApiService.getApi()
      .adminApp.adminAppControllerGetReportPucks(
        reportId,
        facilityId,
        badgeNumber,
        reportDate
      )
      .then((response) => {
        setLoading(false);
        setReportPucks(response.data);
      });
  }, [reportId, facilityId, badgeNumber, reportDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const navigateToDetail = (puckId: number) => {
    navigate({
      pathname: practiceMetricsDetailPageRoute.getPath({
        reportId: puckId,
        facilityId: facilityId,
        badgeNumber: badgeNumber,
        reportDate: reportDate,
      }),
    });
  };

  // temporary
  const puckWidth = '350px';

  return (
    <>
      <Page>
        <Card>
          <Box display="flex" flexWrap="wrap" gap={2}>
            {loading
              ? Array.from({ length: 9 }).map((_) => (
                  <Skeleton variant="rectangular" width={puckWidth} height={370} />
                ))
              : reportPucks?.map((puck, index) => {
                  return (
                    <Stack
                      key={index}
                      border={(theme) => `1px solid ${theme.palette.divider}`}
                      spacing="sm"
                      p={getSpacing('md')}
                      width={puckWidth}
                      alignItems="center"
                      justifyContent={'center'}
                    >
                      <Stack spacing="md">
                        <Box dangerouslySetInnerHTML={{ __html: puck.puckHtml }} />

                        <Button
                          variant="contained"
                          onClick={() => navigateToDetail(puck.id)}
                        >
                          Details
                        </Button>
                      </Stack>
                    </Stack>
                  );
                })}
          </Box>
        </Card>
      </Page>

      <Outlet />
    </>
  );
};
