import { TableCell as MuiTableCell, TableCellProps as MuiTableCellProps } from "@mui/material";
import { Stack } from "./Stack";

type TableCellProps = MuiTableCellProps & {
  type?: "select" | "action" | "id" | undefined;
};

export const TableCell = ({ children, type, ...rest }: TableCellProps) => {
  let typeAttributes: any;

  if (type === "select") typeAttributes = { align: "center", width: "100px", onClick: (e: any) => e.stopPropagation() };
  if (type === "action") typeAttributes = { align: "left", width: "1%", onClick: (e: any) => e.stopPropagation() };
  if (type === "id") typeAttributes = { align: "center", width: "100px" };

  return (
    <MuiTableCell {...typeAttributes} {...rest}>
      {type === "action" ? (
        <Stack direction="row" alignItems="center" justifyContent={"flex-end"}>
          {children}
        </Stack>
      ) : (
        children
      )}
    </MuiTableCell>
  );
};
