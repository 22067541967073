import { createTheme } from '@mui/material/styles';
import { overrideComponents } from './overrideComponents/components';

// A custom theme for this app
export const theme = createTheme({
  //spacing: 6, // 8 is default
  palette: {
    primary: {
      light: '#F26628',
      main: '#ED4924',
      dark: '#B8371A',
    },
    secondary: {
      light: '#44c8b7',
      main: '#39a194',
      dark: '#27766c',
    },
    // error: {
    //   main: red.A400,
    // },
    background: {
      default: '#EEEEEE',
      //default: "white",
      //default: "rgb(243, 244, 249)",
      //neutral: "#F4F6F8",
    },
  },
  zIndex: {},
});

theme.components = overrideComponents(theme);
