import { Typography } from '@mui/material';
import { Button } from 'components';
import { Box, Page } from 'components';
import * as React from 'react';
import MultiselectMenu from './MultiselectMenu';
import useLookupSync from 'components/RuleBuilder/hooks/useLookupSync';
import { LookupType } from '@ensomata/common/enums';
import { PageRoute } from 'models';
import { buildPath, buildTemplatePath } from 'services';

const routeName = 'lookupSync';
export const lookupSyncPageRoute: PageRoute = {
  handle: 'Lookup Sync',
  templatePath: buildTemplatePath(routeName),
  getPath: () => buildPath(routeName),
};

export const LookupSyncPage = () => {
  const [lookupTablesSelected, setLookupTablesSelected] = React.useState<LookupType[]>(
    []
  );
  const { upsert } = useLookupSync();

  const handleUpsert = () => {
    upsert(lookupTablesSelected);
  };

  return (
    <Page
      header={
        <Box>
          <Box>{lookupSyncPageRoute.handle}</Box>
          <Typography fontStyle="italic" fontSize="12px">
            Select Lookup Tables to Sync
          </Typography>
        </Box>
      }
    >
      <MultiselectMenu
        lookupTablesSelected={lookupTablesSelected}
        setLookupTablesSelected={setLookupTablesSelected}
      />

      <Button
        disabled={!lookupTablesSelected.length}
        variant="contained"
        onClick={handleUpsert}
        type="submit"
      >
        Sync Selected Tables
      </Button>
    </Page>
  );
};
